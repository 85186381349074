import React, { useState } from 'react'
import './grammarly.css'
import grammarly from '../../../assets/img/grammarly.svg'
const Grammarly = () => {
  const [button, setButton] = useState('Dyslexia')

  return (
    <div className='container'>
      <div className='content_wrapper'>
        <h1 className='mr_head'>Grammarly</h1>
        <div className='text_size'>
          <h2>Grammarly for DSA</h2>
          <p className='mr_content'>
            Grammarly provides students with ways to rapidly improve spelling, writing, and grammar
            as well as enhance organisational skills, and prevent plagiarism. It is a compensatory
            tool that has remedial benefits.
          </p>
          <div className='contact_us_btn_wr'>
            <a className='contact_us_button btn_bg' href='mailto: orders@as-dsa.com'>
              Contact us
            </a>
          </div>
          <img src={grammarly} alt='grammarly' className='grammarly_img' />
          <h2>Available anywhere</h2>
          <p className='mr_content'>
            Grammarly integrates with most browsers, Office products, desktop and mobile apps
            offering real time writing suggestions to help students.
          </p>
          <h2>Beyond Grammar</h2>
          <p className='mr_content'>
            Grammarly provides real-time writing suggestions to students to improve clarity, work
            choice, and style, making their writing more impactful.
          </p>
          <h2>Built-In Plagarism Checker</h2>
          <p className='mr_content'>
            Grammarly inclides a plagiarism checker to help students manage their research and
            ensure they are referencing correctly.
          </p>
        </div>
        <div className='video'>
          <iframe
            src='https://www.youtube.com/embed/zADj0k0waFY'
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            className='player'></iframe>
        </div>

        <div className='grammarly_btn_content text_size'>
          <h2>Disabilities</h2>
          <div className='grammarly_buttons'>
            <div
              className={
                button === 'Dyslexia' ? 'grammarly_button_active' : 'grammarly_button btn_bg'
              }
              onClick={() => setButton('Dyslexia')}>
              Dyslexia
            </div>
            <div
              className={
                button === 'Hearing_impaired'
                  ? 'grammarly_button_active'
                  : 'grammarly_button btn_bg'
              }
              onClick={() => setButton('Hearing_impaired')}>
              Hearing impaired
            </div>
            <div
              className={button === 'ASCs' ? 'grammarly_button_active' : 'grammarly_button btn_bg'}
              onClick={() => setButton('ASCs')}>
              ASCs
            </div>
            <div
              className={
                button === 'Mental' ? 'grammarly_button_active' : 'grammarly_button btn_bg'
              }
              onClick={() => setButton('Mental')}>
              Mental Health
            </div>
            <div
              className={button === 'ADHD' ? 'grammarly_button_active' : 'grammarly_button btn_bg'}
              onClick={() => setButton('ADHD')}>
              ADHD
            </div>
          </div>
          <p className='mr_content'>
            {button === 'Hearing_impaired'
              ? "Grammarly will reduce concerns around spelling, grammatical errors, tone and syntax and help improve Student's writing skills, thus improving student confidence and allowing them to work more effectively. Grammarly will allow Student to structure the process of improving their academic writing skills by clearly identifying areas of improvement they can explore with their Study Skills Tutor or Mentors while building their confidence in their ability to learn effectively. This will make it possible for Student to concentrate more on content and achieve better academic outcomes."
              : button === 'ASCs'
              ? "Grammarly's plugin for Word will improve the speed and efficiency of Student's working process, making it easier for Student to manage their academic writing tasks. Grammarly will reduce anxiety around spelling, grammatical errors, tone and syntax and help improve Student's writing skills, thus improving student confidence and allowing them to work more proficiently. Grammarly's organisational tools will help remove the processing burden of managing resources and reduce concerns around plagiarism. The ability to access research information easily through the plagiarism checker will help address problems that impact concentration and ease of learning. This will make it possible for Student to concentrate more on content and achieve better academic outcomes."
              : button === 'Mental'
              ? "Grammarly's organisational tools will help remove the processing burden of proofreading and concerns around plagiarism and improve Student's writing skills, thus improving student confidence and allowing them to work more efficiently. Grammarly's tools to improve spelling, grammar tone, and syntax of written tasks will help reduce work-related stress and be crucial to students' progress by allowing them to manage mood states more effectively. The ability to access research information easily through the plagiarism checker will help address problems with (fatigue, pain, and anxiety) that impact concentration and ease of learning. This will make it possible for Student to concentrate more on content and achieve better academic outcomes."
              : button === 'ADHD'
              ? "Grammarly's plugin for Word will improve the speed and efficiency of Student's working process, making it easier for Student to manage their academic writing tasks. Grammarly will reduce anxiety around spelling, grammatical errors, tone and syntax and help improve Student's writing skills, thus improving student confidence and allowing them to work more proficiently. Grammarly's organisational tools will help remove the processing burden of managing resources and reduce concerns around plagiarism. The ability to access research information easily through the plagiarism checker will help address problems that impact concentration and ease of learning. This will make it possible for Student to concentrate more on content and achieve better academic outcomes."
              : "Grammarly will allow Student to structure the process of improving their academic writing skills by clearly identifying areas of improvement they can explore with their Study Skills Tutor or Mentors while building their confidence in their ability to learn effectively. Grammarly will also reduce anxiety around spelling, grammatical errors, tone and syntax and help improve Student's writing skills, thus improving student confidence and allowing Student to work more efficiently. This will make it possible for Student to concentrate more on content and achieve better academic outcomes."}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Grammarly

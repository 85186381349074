import React from 'react'
import { pic_11 } from '../img'

const Memrise = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={pic_11} alt='pic' className='article_img' />
        <h2>Memrise</h2>
        <p className='text_size mr_content'>Tags: SPLD / DYSLEXIA</p>
        <div className='text_size'>
          <p className='mr_content'>
            Imagine if you could revise or learn during your wasted time. Bus rides, waiting for
            someone in a coffee shop, or just sitting on the sofa could be ideal time for learning
            new information. Memrise is software that allows you to make flashcards and use
            ready-made material to memories information. For example there are libraries for
            learning a multitude of different languages. Using Memrise's phone app or website, you
            can regularly add things that you want to remember, and their customisable flash card
            system could be ideal for setting up your own revision lists.
          </p>
          <p className='mr_content'>
            What is fascinating is that you don't have to spend a long time looking at the things
            you want to remember. But the frequency of how often you look at the information you
            want to learn is important. 5 or 10 minutes looking at cues (be it Memrise or
            traditional revision cards), a few times a day, is extremely effective. Studies have
            shown that people remember things far better if they revisit their memories/ cues
            regularly, even if they only spend a very short time looking the cues; compared to large
            amounts of time spent memorising or revising information which is not always as
            effective.
            <br />
          </p>
          <p className='mr_content'>
            <a
              href='https://www.memrise.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              www.memrise.com
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Memrise

import React from 'react'
import { turning5 } from '../img'

const MyLifeAsADisabledStudent = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={turning5} alt='pic' className='article_img' />
        <h2>My Life as a Disabled Student</h2>

        <div className='text_size mr_content'>
          Michaela Hollywood
          <br /> The Guardian
          <br /> 3 October 2013
          <br />
          Tags: EDUCATION
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            Starting university is a time filled with excitement, buzz and freebies but there are
            always problems to be dealt with too. Many students will have to adjust to life away
            from home, combat freshers flu, struggle with changing timetables and find their books
            at a price that doesn't break the bank. Disabled students will have these same worries.
            Yet as well as battling the basic hurdles, establishing a life at university with a
            disability brings a whole different set of challenges.
          </p>
          <p className='mr_content'>
            I am a disabled student at the University of Ulster and have been in higher education
            for four years. I live with muscular dystrophy, a condition which means I rely on an
            "enabler" to help me. I need it even to scratch an itch, and I have to use a powered
            wheelchair to get around.
          </p>
          <p className='mr_content'>
            Starting university scared me just as much as it scares any fresher. I had a new
            personal assistant, a whole new building to navigate and classes to pass just like
            everybody else. It was a bumpy start, but hints, tips and advice from other young
            disabled people were really helpful.
          </p>
          <p className='mr_content'>
            This year, the Muscular Dystrophy Campaign Trailblazers have been gathering the
            experiences, good and bad, of young disabled students. We've then been auditing the
            services that universities provide to support them. It was frustrating that nearly a
            third of the people we asked said they felt limited in where they could choose to study.
            This was because of headaches over their care packages.
          </p>
          <p className='mr_content'>
            My disability and the difficulty in securing care is a big part of the reason I live
            with my parents. I have a great support network at university. However, as an off-campus
            student it can be tricky to get in on the action, and I have felt isolated at times.
          </p>
          <p className='mr_content'>
            The first few weeks of university are a critical time to make friends and become part of
            campus life. Yet, of the universities Trailblazers audited, only a quarter consider
            disabled students' needs when preparing freshers information.
          </p>
          <p className='mr_content'>
            This can make a crucial difference, and a lack of information and support really
            affected me in my first 18 months.
          </p>
          <p className='mr_content'>
            When I started as an eager public relations student I didn't know anybody in my class.
            My first hurdle was to break down the barriers that my wheelchair often puts between me
            and my peers. It took me just over a year before those social barriers truly came down.
          </p>
          <p className='mr_content'>
            As I found my stride, I formed a friendship with a fellow disabled student, and I ran
            for equality champion on the campus council. Communication channels have opened and we
            hope to have a society set up for disabled students and allies in the near future.
          </p>
          <p className='mr_content'>
            As more disabled students have got to know each other, we have shared information. Even
            exchanging shortcuts on campus and the best routes from block to block for those of us
            with mobility difficulties has been really helpful.
          </p>
          <p className='mr_content'>
            We would like to see all universities support students to set up networks and societies.
            We'd like to ensure disabled students get support and advice from people who have been
            in a similar position.
          </p>
          <p className='mr_content'>
            There are always teething problems at the beginning of each term. Yet when universities
            get the basics right it can make an enormous difference. Ulster has a great disability
            services team who have been beside me every step of the way. It has made me feel much
            more part of the campus community.
          </p>
          <p className='mr_content'>
            My advice to all disabled freshers out there is to enjoy every moment of university. The
            challenges it poses ready us for the real world. Embrace every aspect, from Harvard
            referencing to mid-class giggles and your PA forgetting where the lift is. Enjoy the
            ride and take pride in the qualification you earn at the end.
          </p>
          <p className='mr_content article-text-bold'>Student disability statistics</p>
          <p className='mr_content'>According to the Muscular Dystrophy Campaign's new report:</p>
          <p className='mr_content'>
            • 60% of respondents said there is not enough information for disabled students on
            university websites regarding accommodation
          </p>
          <p className='mr_content'>
            • 30% said their student unions were not in an accessible and inclusive environment
          </p>
          <p className='mr_content'>• 70% could not access a disabled students society on campus</p>
          <p className='mr_content'>
            • 30% said their graduation ceremony was in an inaccessible or non-inclusive setting
          </p>
          <p className='mr_content'>
            • Just over half have full access to all university teaching rooms, study rooms and
            libraries
          </p>
        </div>
      </div>
    </div>
  )
}

export default MyLifeAsADisabledStudent

import React from 'react'
import { turning3 } from '../img'

const DisabledStudentsStillNeedHelp = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={turning3} alt='pic' className='article_img' />
        <h2>Disabled students ‘still need more help’, says report</h2>

        <div className='text_size mr_content'>
          Jack Grove
          <br /> Times Higher Education
          <br /> 4 October 2013
          <br />
          Tags: EDUCATION
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            Many disabled students are unable to use lecture theatres and libraries, according to a
            report by the Muscular Dystrophy Campaign’s Trailblazers group.
          </p>
          <p className='mr_content'>
            Some institutions are also failing to signpost vital information required by disabled
            students, such as details about accessible university accommodation or how wheel-chair
            users could navigate campuses.
          </p>
          <p className='mr_content'>
            The study, titled University Challenge, published on 3 October, used information from
            disabled students at 100 UK universities.
          </p>
          <p className='mr_content'>
            It found that only half of universities had made all their teaching rooms, study rooms
            and libraries fully accessible for students with mobility difficulties.
          </p>
          <p className='mr_content'>
            Only a quarter of the universities audited had considered disabled students when
            planning freshers’ week information, it added.
          </p>
          <p className='mr_content'>
            Sixty per cent of students polled also felt there is not enough information for disabled
            students on university websites regarding accessible accommodation.
          </p>
          <p className='mr_content'>
            While the number of disabled student attending university had increased in recent years,
            more needed to be done to help them play a full part in campus life, said Tanvi Vyas,
            project manager at Trailblazers.
          </p>
          <p className='mr_content'>
            “We continue to hear about how many universities are still missing the mark when it
            comes to helping people planning on entering higher education and helping them to
            complete their time there,” she said.
          </p>
          <p className='mr_content'>
            Disabled students also raised concerns over local authorities and the care packages they
            provided, with many young people telling how worries over continuity of social care when
            moving away from home had heavily influenced where they eventually chose to study.
          </p>
          <p className='mr_content'>
            “My local council…were quite insistent that I should stay and study at my local
            university (ranked 119th as opposed to Oxford, ranked first) and do a course that I had
            absolutely no interest in,” said Zoe Hallam, who studied at the University of Oxford.
          </p>
          <p className='mr_content'>
            “My decision to move away was treated with complete bewilderment; there was no
            understanding of how my care package would be accommodated, and the idea that agency
            care was more expensive in the new local authority caused real problems when negotiating
            my direct payments,” she added.
          </p>
          <p className='mr_content'>
            The Trailblazers report found universities had improved their disability access since it
            conducted a similar study four years ago, but more support was needed for students with
            disabilities.
          </p>
        </div>
      </div>
    </div>
  )
}

export default DisabledStudentsStillNeedHelp

import React from 'react'
import {
  pic_18,
  pic_41,
  pic_42,
  pic_43,
  pic_44,
  pic_45,
  pic_46,
  pic_47,
  pic_48,
  pic_49,
  pic_50,
  pic_51
} from '../img'
import './article.css'
const WhyProcrastinatorsProcrastinate = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={pic_18} alt='pic' className='article_img' />
        <h2>Why Procrastinators Procrastinate</h2>

        <p className='text_size mr_content'>
          Tags: PROCRASTINATION
          <p className='text_size '>
            Click{' '}
            <a
              href='http://waitbutwhy.com/2013/10/why-procrastinators-procrastinate.html'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </p>
        <div className='text_size'>
          <p className='mr_content'>
            <span className='article-text-bold'>pro-cras-ti-na-tion |prəˌkrastəˈnāSHən, prō-|</span>
            <br />
            noun <br />
            the action of delaying or postponing something: your first tip is to avoid
            procrastination.
          </p>
          <p className='mr_content'>
            Who would have thought that after decades of struggle with procrastination, the
            dictionary, of all places, would hold the solution.
          </p>
          <p className='mr_content'>Avoid procrastination. So elegant in its simplicity.</p>
          <p className='mr_content'>
            While we’re here, let’s make sure obese people avoid overeating, depressed people avoid
            apathy, and someone please tell beached whales that they should avoid being out of the
            ocean.
          </p>
          <p className='mr_content'>
            No, “avoid procrastination” is only good advice for fake procrastinators—those people
            that are like, “I totally go on Facebook a few times every day at work—I’m such a
            procrastinator!” The same people that will say to a real procrastinator something like,
            “Just don’t procrastinate and you’ll be fine.”
          </p>
          <p className='mr_content'>
            The thing that neither the dictionary nor fake procrastinators understand is that for a
            real procrastinator, procrastination isn’t optional—it’s something they don’t know how
            to not do.
          </p>
          <p className='mr_content'>
            In college, the sudden unbridled personal freedom was a disaster for me—I did nothing,
            ever, for any reason. The one exception was that I had to hand in papers from time to
            time. I would do those the night before, until I realized I could just do them through
            the night, and I did that until I realized I could actually start them in the early
            morning on the day they were due. This behavior reached caricature levels when I was
            unable to start writing my 90-page senior thesis until 72 hours before it was due, an
            experience that ended with me in the campus doctor’s office learning that lack of blood
            sugar was the reason my hands had gone numb and curled up against my will. (I did get
            the thesis in—no, it was not good.)
          </p>
          <p className='mr_content'>
            Even this post took much longer than it should have, because I spent a bunch of hours
            doing things like seeing this picture sitting on my desktop from a previous post,
            opening it, looking at it for a long time thinking about how easily he could beat me in
            a fight, then wondering if he could beat a tiger in a fight, then wondering who would
            win between a lion and a tiger, and then googling that and reading about it for a while
            (the tiger would win). I have problems.
          </p>
          <p className='mr_content'>
            To understand why procrastinators procrastinate so much, let’s start by understanding a
            non-procrastinator’s brain:
          </p>
          <img src={pic_41} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            Pretty normal, right? Now, let’s look at a procrastinator’s brain:
          </p>
          <img src={pic_42} alt='pic' className='article_img_in' />
          <p className='mr_content'>Notice anything different?</p>
          <p className='mr_content'>
            It seems the Rational Decision-Maker in the procrastinator’s brain is coexisting with a
            pet—the Instant Gratification Monkey.
          </p>
          <p className='mr_content'>
            This would be fine—cute, even—if the Rational Decision-Maker knew the first thing about
            how to own a monkey. But unfortunately, it wasn’t a part of his training and he’s left
            completely helpless as the monkey makes it impossible for him to do his job.
          </p>
          <img src={pic_43} alt='pic' className='article_img_in' />
          <img src={pic_44} alt='pic' className='article_img_in' />
          <img src={pic_45} alt='pic' className='article_img_in' />
          <img src={pic_46} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            The fact is, the Instant Gratification Monkey is the last creature who should be in
            charge of decisions—he thinks only about the present, ignoring lessons from the past and
            disregarding the future altogether, and he concerns himself entirely with maximizing the
            ease and pleasure of the current moment. He doesn’t understand the Rational
            Decision-Maker any better than the Rational Decision-Maker understands him—why would we
            continue doing this jog, he thinks, when we could stop, which would feel better. Why
            would we practice that instrument when it’s not fun? Why would we ever use a computer
            for work when the internet is sitting right there waiting to be played with? He thinks
            humans are insane.
          </p>
          <p className='mr_content'>
            In the monkey world, he’s got it all figured out—if you eat when you’re hungry, sleep
            when you’re tired, and don’t do anything difficult, you’re a pretty successful monkey.
            The problem for the procrastinator is that he happens to live in the human world, making
            the Instant Gratification Monkey a highly unqualified navigator. Meanwhile, the Rational
            Decision-Maker, who was trained to make rational decisions, not to deal with competition
            over the controls, doesn’t know how to put up an effective fight—he just feels worse and
            worse about himself the more he fails and the more the suffering procrastinator whose
            head he’s in berates him.
          </p>
          <p className='mr_content'>
            It’s a mess. And with the monkey in charge, the procrastinator finds himself spending a
            lot of time in a place called the Dark Playground.*
          </p>
          <p className='mr_content'>
            The Dark Playground is a place every procrastinator knows well. It’s a place where
            leisure activities happen at times when leisure activities are not supposed to be
            happening. The fun you have in the Dark Playground isn’t actually fun because it’s
            completely unearned and the air is filled with guilt, anxiety, self-hatred, and dread.
            Sometimes the Rational Decision-Maker puts his foot down and refuses to let you waste
            time doing normal leisure things, and since the Instant Gratification Monkey sure as
            hell isn’t gonna let you work, you find yourself in a bizarre purgatory of weird
            activities where everyone loses.**
          </p>
          <img src={pic_47} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            And the poor Rational Decision-Maker just mopes, trying to figure out how he let the
            human he’s supposed to be in charge of end up here again.
          </p>
          <p className='mr_content'>
            Given this predicament, how does the procrastinator ever manage to accomplish anything?
          </p>
          <p className='mr_content'>
            As it turns out, there’s one thing that scares the shit out of the Instant Gratification
            Monkey:
          </p>
          <img src={pic_48} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            The Panic Monster is dormant most of the time, but he suddenly wakes up when a deadline
            gets too close or when there’s danger of public embarrassment, a career disaster, or
            some other scary consequence.
          </p>
          <img src={pic_49} alt='pic' className='article_img_in' />
          <img src={pic_50} alt='pic' className='article_img_in' />
          <img src={pic_51} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            The Instant Gratification Monkey, normally unshakable, is terrified of the Panic
            Monster. How else could you explain the same person who can’t write a paper’s
            introductory sentence over a two-week span suddenly having the ability to stay up all
            night, fighting exhaustion, and write eight pages? Why else would an extraordinarily
            lazy person begin a rigorous workout routine other than a Panic Monster freakout about
            becoming less attractive?
          </p>
          <p className='mr_content'>
            And these are the lucky procrastinators—there are some who don’t even respond to the
            Panic Monster, and in the most desperate moments they end up running up the tree with
            the monkey, entering a state of self-annihilating shutdown.
          </p>
          <p className='mr_content'>Quite a crowd we are.</p>
          <p className='mr_content'>
            Of course, this is no way to live. Even for the procrastinator who does manage to
            eventually get things done and remain a competent member of society, something has to
            change. Here are the main reasons why:
          </p>
          <p className='mr_content'>
            <span className='article-text-bold'> 1) It’s unpleasant. </span>Far too much of the
            procrastinator’s precious time is spent toiling in the Dark Playground, time that could
            have been spent enjoying satisfying, well-earned leisure if things had been done on a
            more logical schedule. And panic isn’t fun for anyone.
          </p>
          <p className='mr_content'>
            <span className='article-text-bold'>
              2) The procrastinator ultimately sells himself short.
            </span>
            He ends up underachieving and fails to reach his potential, which eats away at him over
            time and fills him with regret and self-loathing.
          </p>
          <p className='mr_content'>
            <span className='article-text-bold'>
              3) The Have-To-Dos may happen, but not the Want-To-Dos.
            </span>
            Even if the procrastinator is in the type of career where the Panic Monster is regularly
            present and he’s able to be fulfilled at work, the other things in life that are
            important to him—getting in shape, cooking elaborate meals, learning to play the guitar,
            writing a book, reading, or even making a bold career switch—never happen because the
            Panic Monster doesn’t usually get involved with those things. Undertakings like those
            expand our experiences, make our lives richer, and bring us a lot of happiness—and for
            most procrastinators, they get left in the dust.
            <br />
            So how can a procrastinator improve and become happier? See Part 2,{' '}
            <a
              href='https://waitbutwhy.com/2013/11/how-to-beat-procrastination.html'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              How To Beat Procrastination.
            </a>
          </p>
          <p className='mr_content'>
            * A lot of you are probably reading this article while in the Dark Playground.
            <br />
            ** I spent two hours in the Dark Playground right before I drew the Dark Playground
            drawing, because I was dreading having to draw the signpost, which I knew would be hard
            and take forever (which it did).
            <br />
            <a
              href='http://waitbutwhy.com/2013/10/why-procrastinators-procrastinate.html'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              http://waitbutwhy.com/2013/10/why-procrastinators-procrastinate.html
            </a>
          </p>
          <p className='mr_content'>
            <span className='article-text-bold'>
              If you liked this article, here are some more posts from Wait But Why:
            </span>
            <a
              href='https://waitbutwhy.com/2013/09/why-generation-y-yuppies-are-unhappy.html'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              {' '}
              Why Generation Y Yuppies Are Unhappy
            </a>
            <a
              href='https://waitbutwhy.com/2013/09/why-generation-y-yuppies-are-unhappy.html'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              7 Ways to be Insufferable on Facebookl
            </a>
            <a
              href='https://waitbutwhy.com/2013/11/life-is-picture-but-you-live-in-pixel.html'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Life is a Picture, But You Live in a Pixel
            </a>
          </p>
          <p className='mr_content article-text-bold'>
            For more articles from Wait But Why, check out{' '}
            <a
              href='https://waitbutwhy.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              www.waitbutwhy.com
            </a>
          </p>
          <p className='mr_content article-text-bold'>
            Follow Wait But Why on Facebook:{' '}
            <a
              href='https://waitbutwhy.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              www.facebook.com/waitbutwhy
            </a>{' '}
          </p>
          <p className='mr_content article-text-bold'>
            Subscribe to Wait But Why via{' '}
            <a
              href='https://waitbutwhy.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Email
            </a>
          </p>
          <p className='mr_content article-text-bold'>
            Follow Wait But Why on Twitter:{' '}
            <a
              href='https://twitter.com/waitbutwhy'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              www.twitter.com/waitbutwhy
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default WhyProcrastinatorsProcrastinate

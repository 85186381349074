import React from 'react'
import about_us from '../../assets/img/about_us.svg'
import './about.css'
const AboutUs = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <h1 className='mr_head'>About us</h1>
        <div className='text_size'>
          <p className='mr_content'>
            We are a social enterprise focused on helping people work and study more effectively. As
            a disability-confident employer, we take pride in our diverse team. We often have to
            work within the confines of government-funded support, but we always pledge to do our
            best to assist. We have decades of experience helping people accelerate their work
            efficiency and improve study grades. Embracing the bespoke nature of 1-1 support, we are
            able to tailor support to each individual, something we value greatly. Feel free to
            discuss your needs with our team and they will do their best to assist you, within the
            prescribed service level agreements and frameworks. We need to be highly cost-effective
            and efficient, as we are often funded by taxpayers' money, and we appreciate you working
            with us along the way.
            <br />
            <br />
            The flags represent our current team's eclectic background.
          </p>
          <img src={about_us} alt='About Us' className='about_us_img' />
        </div>
      </div>
    </div>
  )
}

export default AboutUs

import React from 'react'
import './coming-soon.css'
import coming_soon from '../../assets/img/coming_soon.svg'
const ComingSoon = () => {
  return (
    <div className='coming_wr '>
        <div className='container'>
            <div className='content_wrapper cm_wrapp_img'>
            <img src={coming_soon} alt='coming_soon' className='coming_soon_img' />
            </div>
        </div>
    </div>
  )
}

export default ComingSoon
import reading from '../../../assets/img/reading.svg'
import reading_speed from '../../../assets/img/reading_speed.svg'
import reading_comprehension from '../../../assets/img/reading_comprehension.svg'
import reasearch_organisation from '../../../assets/img/reasearch_organisation.svg'
import Procrastination from '../../../assets/img/Procrastination.svg'
import short_term from '../../../assets/img/short_term.svg'
import revision_strategies from '../../../assets/img/revision_strategies.svg'
import easy_integration from '../../../assets/img/easy_integration.svg'

export const sholarcyItems = [
  {
    id: 1,
    img: reading,
    title: 'Skim reading',
    content:
      'Many students find reading long articles about an unfamiliar topic challenging. Scholarcy can help by highlighting and presenting key information in an accessible format.'
  },
  {
    id: 2,
    img: reading_speed,
    title: 'Reading speed',
    content: `Some students find reading difficult and have slower than average reading speed. Scholarcy alleviates 
      stress and improves comprehension by breaking long texts down into clear, manageable sections.`
  },
  {
    id: 3,
    img: reading_comprehension,
    title: 'Reading comprehension',
    content: `Key concepts are defined within the Summary Flashcard to help students get to grips with a new 
    subject. Reading becomes more manageable and less overwhelming.
    `
  },
  {
    id: 4,
    img: reasearch_organisation,
    title: 'Research organisation',
    content: `Summary flashcards can be stored in one place, ready for an upcoming essay, or revision. Flashcards also contain the full text of a paper and provide a link to the original article. References for every article  can be quickly exported into Zotero or other referencing tools.
    `
  },
  {
    id: 5,
    img: reading,
    title: 'Skim reading',
    content:
      'Many students find reading long articles about an unfamiliar topic challenging. Scholarcy can help by highlighting and presenting key information in an accessible format.'
  },
  {
    id: 6,
    img: Procrastination,
    title: 'Procrastination',
    content: `Finding a way into dense academic articles can be intimidating. Scholarcy provides a simple, clear, and consistent format, taking away some of the barriers to getting started and staying focused.
      `
  },
  {
    id: 7,
    img: short_term,
    title: 'Short-term memory challenges',
    content: `For those that forget articles soon after they’ve read them, Scholarcy’s Summary Flashcard acts as a 
    quick refresher of the text, helping readers  recall and digest important information.
    `
  },
  {
    id: 8,
    img: easy_integration,
    title: 'Easy integration',
    content: `Scholarcy slots seamlessly into a student's workflow as a powerful skim-reading tool. Scholarcy works with  Zotero and other reference managers, for easy bibliography creation and annotated references. Students can export flashcards to Word, Excel, and PowerPoint.
     `
  }
]

import React, { useEffect, useRef, useState } from 'react'
import './order-form.css'
import upload_pic from '../../../assets/img/upload.svg'
import { Link } from 'react-router-dom'
import Loader from '../../../components/loader/Loader'
import { generateEmail } from './generateEmail'
import emailjs from '@emailjs/browser'
import SliderFundingBody from '../dsa-order-process/SliderFundingBody'
const OrderForm = () => {
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState('')
  const [isSend, setIsSend] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [info, setInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    notes: ''
  })
  const form = useRef()

  const [infoDirty, setInfoDirty] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    notes: false
  })
  const [infoError, setInfoError] = useState({
    firstName: 'First Name cannot be empty',
    lastName: 'Last Name cannot be empty',
    email: 'Email cannot be empty',
    phone: 'Invalid Phone Number',
    notes: 'Note cannot be empty'
  })
  const blurHandler = e => {
    switch (e.target.name) {
      case 'firstName':
        setInfoDirty(prev => ({
          ...prev,
          firstName: true
        }))
        break
      case 'lastName':
        setInfoDirty(prev => ({
          ...prev,
          lastName: true
        }))
        break
      case 'notes':
        setInfoDirty(prev => ({
          ...prev,
          notes: true
        }))
        break
      case 'phone':
        setInfoDirty(prev => ({
          ...prev,
          phone: true
        }))
        break
      case 'email':
        setInfoDirty(prev => ({
          ...prev,
          email: true
        }))
        break
    }
  }

  const infoEmailHandler = e => {
    setInfo(prev => ({
      ...prev,
      email: e.target.value
    }))
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

    if (!re.test(String(e.target.value).toLowerCase())) {
      setInfoError(prev => ({
        ...prev,
        email: 'Invalid Email'
      }))
    } else {
      setInfoError(prev => ({
        ...prev,
        email: ''
      }))
    }
  }
  const infoPhoneHandler = e => {
    const clearedValue = e.target.value.replace(/[^\._\-\d\+\/(\)]/, '')

    setInfo(prev => ({
      ...prev,
      phone: clearedValue
    }))
    const phTest = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)[0-9]\d{3}(\s)?\d{6}/g
    if (!phTest.test(String(e.target.value).toLocaleLowerCase())) {
      setInfoError(prev => ({
        ...prev,
        phone: 'Invalid number'
      }))
    } else {
      setInfoError(prev => ({
        ...prev,
        phone: ''
      }))
    }
  }
  const infoInputHandler = e => {
    setInfo(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }
  useEffect(() => {
    if (info.firstName.length < 2) {
      setInfoError(prev => ({
        ...prev,
        firstName: 'Invalid First Name'
      }))
    } else {
      setInfoError(prev => ({
        ...prev,
        firstName: ''
      }))
    }
    if (info.lastName.length < 2) {
      setInfoError(prev => ({
        ...prev,
        lastName: 'Invalid Last Name'
      }))
    } else {
      setInfoError(prev => ({
        ...prev,
        lastName: ''
      }))
    }

    if (info.notes.length < 2) {
      setInfoError(prev => ({
        ...prev,
        notes: 'Invalid Note'
      }))
    } else {
      setInfoError(prev => ({
        ...prev,
        notes: ''
      }))
    }
  }, [info])
  function fileHandler(e) {
    e.preventDefault()
    var file = e.target.files[0]
    setFileName(file.name)
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setFile(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }
  const sendSubmit = e => {
    e.preventDefault()
    setIsLoading(true)

    emailjs
      .sendForm(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID_ORDER_FORM,
        form.current,
        process.env.REACT_APP_YOUR_PUBLIC_KEY
      )
      .then(
        res => {
          setIsLoading(false)
          if (res.text !== 'OK') {
            setIsLoading(false)
            setError(true)
          }
          setIsSend(true)
          console.log(res.text)
        },
        error => {
          setIsLoading(false)
          setError(true)
        }
      )
  }

  if (error) {
    return (
      <div className='container'>
        <div className='content_wrapper error_tech'>
          <h1 className='mr_head'>Order Form</h1>
          <div className='mr_content order_success'>
            <h2 className='h2_order_form'>Error!</h2>
            <p className='text_size mr_content'>
              Error! Unfortunately there seems to have been an issue with your form submission.
              Please try again or email{' '}
              <a className='link__order_form' href='mailto: orders@as-dsa.com'>
                orders@as-dsa.com
              </a>
              <br />
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='container'>
        <div className='content_wrapper'>
          <h1 className='mr_head'>Order Form</h1>
          <div className='text_size'>
            <p>
              Please use this form to place your Equipment or Specialist Tuition order. We will
              respond within 1 working day. {isSend && <br />}
              Alternatively, you can arrange your Equipment or Tuition over the phone 0203 519 8000
              or by email
              <a className='link__order_form' href='mailto: orders@as-dsa.com'>
                orders@as-dsa.com
              </a>
              {isSend && <br />}
              You will need the Entitlement letter from Student Finance England / NHS or another
              funding body to hand. Please check out the
              <Link className='link__order_form' to='/dsa-order-process'>
                DSA Order Process
              </Link>
              for more information.
            </p>
          </div>

          <div>
            {isSend ? (
              <div className='mr_content order_success'>
                <h2 className='h2_order_form'>Thank you!</h2>
                <p className='text_size mr_content'>
                  Your form has been successfully submitted. We will respond within 1 working day of
                  receiving your email. Please note response times may be slightly longer after Bank
                  holidays. <br />
                </p>
              </div>
            ) : (
              <div className='form_wrapp'>
                {isLoading ? (
                  <div className='loader'>
                    <Loader />
                  </div>
                ) : (
                  ''
                )}
                <form ref={form} onSubmit={sendSubmit} className='order_form'>
                  <div>
                    <div className='label_block '>
                      <label className='inp_label' htmlFor='firtName'>
                        First Name
                      </label>
                      {infoDirty.firstName && infoError.firstName && (
                        <div style={{ color: 'red' }}>{infoError.firstName}</div>
                      )}
                    </div>
                    <input
                      onBlur={blurHandler}
                      value={info.firstName}
                      onChange={infoInputHandler}
                      type='text'
                      className='input_small'
                      name='firstName'
                      placeholder='First Name'
                    />
                  </div>
                  <div>
                    <div className='label_block '>
                      <label className='inp_label' htmlFor='lastName'>
                        Last Name
                      </label>
                      {infoDirty.lastName && infoError.lastName && (
                        <div style={{ color: 'red' }}>{infoError.lastName}</div>
                      )}
                    </div>
                    <input
                      onBlur={blurHandler}
                      value={info.lastName}
                      onChange={infoInputHandler}
                      type='text'
                      className='input_small'
                      name='lastName'
                      placeholder='Last Name'
                    />
                  </div>
                  <div>
                    <div className='label_block '>
                      <label className='inp_label' htmlFor='email'>
                        Email
                      </label>
                      {infoDirty.email && infoError.email && (
                        <div style={{ color: 'red' }}>{infoError.email}</div>
                      )}
                    </div>
                    <input
                      onBlur={blurHandler}
                      value={info.email}
                      onChange={infoEmailHandler}
                      type='text'
                      className='input_small'
                      name='email'
                      placeholder='Email'
                    />
                  </div>
                  <div>
                    <div className='label_block '>
                      <label className='inp_label' htmlFor='phone'>
                        Telephone Number
                      </label>
                      {infoDirty.phone && infoError.phone && (
                        <div style={{ color: 'red' }}>{infoError.phone}</div>
                      )}
                    </div>
                    <input
                      onBlur={blurHandler}
                      type='text'
                      name='phone'
                      required
                      value={info.phone}
                      maxLength={info.phone[0] === '0' ? 11 : 13}
                      onChange={infoPhoneHandler}
                      className='input_small'
                      placeholder='07981234567'
                    />
                  </div>
                  <div className='big mr_content'>
                    <div className='label_block '>
                      <label className='inp_label' htmlFor='notes'>
                        Delivery address & any notes
                      </label>
                      {infoDirty.notes && infoError.notes && (
                        <div style={{ color: 'red' }}>{infoError.notes}</div>
                      )}
                    </div>
                    <textarea
                      onBlur={blurHandler}
                      value={info.notes}
                      onChange={infoInputHandler}
                      name='notes'
                      className='input_big'
                      placeholder='Please include the full address with postcode. You can change your delivery address later.'
                    />
                  </div>
                  <div className='upload_container mr_content'>
                    <img src={upload_pic} alt='upload' />
                    <div className='upload'>
                      <button type='button' className='btn-warning'>
                        Click to upload your Entitlement Letter
                        <input
                          onChange={fileHandler}
                          type='file'
                          accept='application/pdf'
                          className='input_file'
                          name='my_file'
                        />
                      </button>
                      <p> {file ? `${fileName} Uploaded successfully.` : 'PDF (max. 500 kB)'}</p>
                    </div>
                  </div>
                  <div
                    className={
                      fileName.length > 1 &&
                      info.firstName.length > 1 &&
                      info.lastName.length > 1 &&
                      info.notes.length > 1 &&
                      info.email.length > 1 &&
                      info.phone.length > 1
                        ? 'submit_btn mr_content btn_bg'
                        : 'submit-disabled mr_content'
                    }>
                    {fileName.length > 1 &&
                    info.firstName.length > 1 &&
                    info.lastName.length > 1 &&
                    info.notes.length > 1 &&
                    info.email.length > 1 &&
                    info.phone.length > 1 ? (
                      <button type='submit' className='submit'>
                        Submit
                      </button>
                    ) : (
                      <button type='submit' className='submit' disabled>
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
      {!isSend ? (
        <div className='container_order'>
          <div className='content_wrapper_order'>
            <h2 className='mr_content'>Examples of Funding Body Letters</h2>
            <p className='mr_content text_size'>
              Please click on images below for examples of Funding Body Letters you need to send to
              us.
            </p>
            <SliderFundingBody />
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default OrderForm

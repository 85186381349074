import React from 'react'
import { pic_2 } from '../img'

const WhenWillWenUnderstandAutism = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={pic_2} alt='pic' className='article_img' />
        <h2>When will we understand Autism Spectrum Disorders?</h2>

        <div className='text_size mr_content'>
          Professor Francesca Happé
          <br /> The Royal Society, London 26
          <br /> October 2011
          <br />
          Tags: ASD
          <p className='text_size '>
            Click{' '}
            <a
              href='https://royalsociety.org/science-events-and-lectures/2011/autism-spectrum-disorder/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            This talk presents a cognitive neuroscience perspective on what might be some of the
            obstacles standing in the way of major scientific breakthroughs in the science of
            autism. What we do know, and what we need to know, about individuals with autism
            spectrum disorders will be discussed.
          </p>
        </div>
      </div>
    </div>
  )
}

export default WhenWillWenUnderstandAutism

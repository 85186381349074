import React from 'react'
import { turning2 } from '../img'

const SalmanKhan = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={turning2} alt='pic' className='article_img' />
        <h2>When will we understand Autism Spectrum Disorders?</h2>

        <div className='text_size mr_content'>
          Tags: EDUCATION
          <p className='text_size '>
            Click{' '}
            <a
              href='https://www.youtube.com/watch?v=gM95HHI4gLk'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
      </div>
    </div>
  )
}

export default SalmanKhan

import React from 'react'
import {  pic_5 } from '../img'

const ShowdonAwardScheme = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
      <img src={pic_5} alt='pic' className='article_img' />
        <h2 >Snowdon Award Scheme</h2>
        <div className='text_size mr_content'>
          Tags: Deaf aware
          <p className='text_size '>
            Click{' '}
            <a
              href='https://eric.ed.gov/?id=ED356398'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            Snowdon bursaries are made for one or two years and do not normally exceed £2,500. The
            usual closing date for applications is 31 May so it may be too late for the 2012/13
            academic year, but the panel also meets in October to consider late applications - funds
            permitting.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ShowdonAwardScheme

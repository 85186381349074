import React from 'react'
import logo from '../../assets/img/logo_light.svg'
import { Link } from 'react-router-dom'
import './footer.css'
const Footer = () => {
  return (
    <div className='footer_wrapper'>
      <div className='footer '>
        <div className='footer_mr'>
          <div className='footer_border_wrapper'>
            <div className='footer_border'></div>
          </div>
          <div className='footer_content'>
            <img src={logo} alt='pic' className='footer_logo' />
            <div className='foot_head_content'>
              <ul className='footer_head_address'>
                <li className='foot_text'>1A Dunn Street</li>
                <li className='foot_text'>London</li>
                <li className='foot_text'>E8 2DG</li>
              </ul>
              <ul>
                <li>
                  <a className='foot_link' href='mailto:  info@as-dsa.com'>
                    info@as-dsa.com
                  </a>
                </li>
                <li className='foot_text'>0203 519 8000</li>
              </ul>
              <ul>
                <li>
                  <Link className='foot_link ' to='/contact'>
                    Contact Page
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='footer_border_wrapper_bottom'>
            <div className='footer_border'></div>
          </div>
          <div className='footer_content'>
            <div className='footer_foot'>
              <div className='foot_text'>© Assistive Solutions Ltd.</div>
              <ul className='footer_foot_content foot_text'>
                <li>
                  <a
                    className='foot_link'
                    href='pdf/Assistive Solutions - Terms and Conditions DSA.pdf'
                    target='_blank'>
                    Terms
                  </a>
                </li>
                |
                <li>
                  <a
                    className='foot_link'
                    href='pdf/Assistive Solutions Privacy Policy 2018.pdf'
                    target='_blank'>
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer

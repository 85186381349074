import React from 'react'
import { pic_23 } from '../img';

const MartynSibleyBlog = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={pic_23} alt='pic' className='article_img' />
        <h2>Martyn Sibley Blog</h2>

        <div className='text_size mr_content'>
          Tags: EQUALITY_ACT
          <p className='text_size '>
            Click{' '}
            <a
              href='http://www.martynsibley.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            My blog aims to inspire, inform and change the world around disability issues. By
            starting with my personal experiences and sharing my individual progress; it begins the
            conversation. I discuss my family, friends, love of music, struggles as a Tottenham
            Hotspurs Football Club fan, my world travels and crazy challenges. I also work as an
            online facilitator for disabled people, covering social care and benefits to travel and
            relationships. This is to understand other people’s experiences, share, learn, support
            and progress together. My personal achievements show what is possible, but every
            individual should achieve in their own way. Sign up now for regular updates.
          </p>
        </div>
      </div>
    </div>
  )
}

export default MartynSibleyBlog

import React from 'react'
import './ergonomic-assessments.css'
import ergonomic_svg from '../../../assets/img/ergonomic_svg.svg'
import ergonomic from '../../../assets/img/ergonomic.svg'

const ErgonomicAssessments = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <h1 className='mr_head'>Ergonomic Assessments</h1>
        <div className=' text_size '>
          <p className='mr_content'>
            We offer an efficient Ergonomic Assessment service with quick report turnaround.
          </p>
          <p className='mr_content'>
            We have provided thousands of assessments for those needing ergonomic support. From the
            Isle of Wight to the Scottish Highlands, we cover the U.K. to carry out the highest
            quality ergonomic assessments.
          </p>
          <img src={ergonomic} alt='ergonomic' className='ergonimic_img' />
          <h2>What is Ergonomics</h2>
          <p className='mr_content'>
            Ergonomics is the process of organising and setting up a workstation that is most
            suitable for the users’ needs. This means adapting a users current working environment
            and adopting new equipment for maximum benefit; whether sitting or standing at a
            workstation.
          </p>
          <h2>Our service</h2>
          <p className='mr_content'>
            We provide full face-to-face ergonomic assessments to ensure individuals needs are
            adequately met. A report is then sent back to needs assessors within 48 hours of the
            assessment, and the order is placed as soon as approval is met by the funding body.
          </p>
          <h2>Book your assessment</h2>
          <p className='mr_content'>
            We focus on high quality, tried and tested ergonomic products, which will reliably last
            for many years. Our assessors are trained to recommend the most beneficial items, to
            ensure individual's can work to the best of their abilities.
          </p>
          <p className='mr_content'>
            To arrange an ergonomic assessment or for any ergonomics-related queries, please email
            alex.francis@as-dsa.com or call us directly on 020 3519 8000.
          </p>
          <img src={ergonomic_svg} alt='Ergonomic Assessment' className='ergonimic_svg'/>
        </div>
      </div>
    </div>
  )
}

export default ErgonomicAssessments

import React from 'react'
import { pic_9 } from '../img'
const InPraiseOfMistfits = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
      <img src={pic_9} alt='pic' className='article_img' />
        <h2>In praise of misfits</h2>

        <div className='text_size mr_content'>
          June 2nd 2012 <br />
          Tags: SPLD / DYSLEXIA
          <p className='text_size '>
            Click{' '}
            <a
              href='https://www.economist.com/business/2012/06/02/in-praise-of-misfits'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            In 1956 William Whyte argued in his bestseller, “The Organisation Man”, that companies
            were so in love with “well-rounded” executives that they fought a “fight against
            genius”. Today many suffer from the opposite prejudice. Software firms gobble up
            anti-social geeks. Hedge funds hoover up equally oddball quants. Hollywood bends over
            backwards to accommodate the whims of creatives. And policymakers look to rule-breaking
            entrepreneurs to create jobs. Unlike the school playground, the marketplace is kind to
            misfits.
          </p>
          <p className='mr_content'>
            Recruiters have noticed that the mental qualities that make a good computer programmer
            resemble those that might get you diagnosed with Asperger's syndrome: an obsessive
            interest in narrow subjects; a passion for numbers, patterns and machines; an addiction
            to repetitive tasks; and a lack of sensitivity to social cues. Some joke that the
            internet was invented by and for people who are “on the spectrum”, as they put it in the
            Valley. Online, you can communicate without the ordeal of meeting people.
          </p>
          <p className='mr_content'>
            Wired magazine once called it “the Geek Syndrome”. Speaking of internet firms founded in
            the past decade, Peter Thiel, an early Facebook investor, told the New Yorker that: “The
            people who run them are sort of autistic.” Yishan Wong, an ex-Facebooker, wrote that
            Mark Zuckerberg, the founder, has “a touch of Asperger's”, in that “he does not provide
            much active feedback or confirmation that he is listening to you.” Craig Newmark, the
            founder of Craigslist, says he finds the symptoms of Asperger's “uncomfortably familiar”
            when he hears them listed.
          </p>
          <p className='mr_content'>
            Similar traits are common in the upper reaches of finance. The quants have taken over
            from the preppies. The hero of Michael Lewis's book “The Big Short”, Michael Burry, a
            hedge-fund manager, is a loner who wrote a stockmarket blog as a hobby while he was
            studying to be a doctor. He attracted so much attention from money managers that he quit
            medicine to start his own hedge fund, Scion Capital. After noticing that there was
            something awry with the mortgage market, he made a killing betting that it would crash.
            “The one guy that I could trust in the middle of this crisis,” Mr Lewis told National
            Public Radio, “was this fellow with Asperger's and a glass eye.”
          </p>
          <p className='mr_content'>
            Entrepreneurs also display a striking number of mental oddities. Julie Login of Cass
            Business School surveyed a group of entrepreneurs and found that 35% of them said that
            they suffered from dyslexia, compared with 10% of the population as a whole and 1% of
            professional managers. Prominent dyslexics include the founders of Ford, General
            Electric, IBM and IKEA, not to mention more recent successes such as Charles Schwab (the
            founder of a stockbroker), Richard Branson (the Virgin Group), John Chambers (Cisco) and
            Steve Jobs (Apple). There are many possible explanations for this. Dyslexics learn how
            to delegate tasks early (getting other people to do their homework, for example). They
            gravitate to activities that require few formal qualifications and demand little reading
            or writing.
          </p>
          <p className='mr_content'>
            Attention-deficit disorder (ADD) is another entrepreneur-friendly affliction: people who
            cannot focus on one thing for long can be disastrous employees but founts of new ideas.
            Some studies suggest that people with ADD are six times more likely than average to end
            up running their own businesses. David Neeleman, the founder of JetBlue, a budget
            airline, says: “My ADD brain naturally searches for better ways of doing things. With
            the disorganisation, procrastination, inability to focus and all the other bad things
            that come with ADD, there also come creativity and the ability to take risks.” Paul
            Orfalea, the founder of Kinko's and a hotch-potch of businesses since, has both ADD and
            dyslexia. “I get bored easily; that is a great motivator,” he once said. “I think
            everybody should have dyslexia and ADD.”
          </p>
          <p className='mr_content'>
            Where does that leave the old-fashioned organisation man? He will do just fine. The more
            companies hire brilliant mavericks, the more they need sensible managers to keep the
            company grounded. Someone has to ensure that dull but necessary tasks are done. Someone
            has to charm customers (and perhaps lawmakers). This task is best done by those who
            don't give the impression that they think normal people are stupid. (Sheryl Sandberg, Mr
            Zuckerberg's deputy, does this rather well for Facebook.) Many start-ups are saved from
            disaster only by replacing the founders with professional managers. Those managers, of
            course, must learn to work with geeks.
          </p>
          <p className='mr_content'>
            Geekery in the genes <br />
            The clustering of people with unusual minds is causing new problems. People who work for
            brainy companies tend to marry other brainy people. Simon Baron-Cohen of Cambridge
            University argues that when two hyper-systematisers meet and mate, they are more likely
            to have children who suffer from Asperger's or its more severe cousin, autism. He has
            shown that children in Eindhoven, a technology hub in the Netherlands, are two to four
            times more likely to be diagnosed with autism than children in two other Dutch towns of
            similar size. He has also shown that Cambridge students who study mathematics, physics
            and engineering are more likely to have autistic relatives than students studying
            English literature. Most employers are leery of hiring severely autistic people, but not
            all. Specialist People, a Danish firm, matches autistic workers with jobs that require a
            good memory or a high tolerance for repetition.
          </p>
          <p className='mr_content'>
            More broadly, the replacement of organisation man with disorganisation man is changing
            the balance of power. Those square pegs may not have an easy time in school. They may be
            mocked by jocks and ignored at parties. But these days no serious organisation can
            prosper without them. As Kiran Malhotra, a Silicon Valley networker, puts it: “It's
            actually cool to be a geek.”
          </p>
        </div>
      </div>
    </div>
  )
}

export default InPraiseOfMistfits

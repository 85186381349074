import React from 'react'
import { empl2 } from '../img'

const WillDisability = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={empl2} alt='pic' className='article_img' />
        <h2>Will my disability affect my graduate job application?</h2>

        <div className='text_size mr_content'>
          Louise Tickle
          <br /> The Guardian
          <br /> 30 September 2013
          <br />
          Tags: EMPLOYMENT
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            "A lot about mental illness makes you sound like you're a lazy person," says Christina
            Nelson, who is about to embark on her third year studying law at School of African and
            Oriental Studies.
          </p>
          <p className='mr_content'>
            Christina has been diagnosed with bipolar disorder and generalised anxiety. She says
            that, when it comes to applying for internships or graduate jobs, "disclosure to
            employers is complicated and a challenge, because you don't always know exactly what
            you'll be doing in that job, and whether your condition will be relevant."
          </p>
          <p className='mr_content'>
            Many disabilities, of course, aren't obvious and students may find it tempting not to
            let a potential employer know in advance. As Christina says: "I have a mental disorder
            that really does affect my life, but it doesn't affect me at interview."
          </p>
          <p className='mr_content'>
            Disclosure is one of the trickiest dilemmas facing any disabled job applicant in a
            market where graduate-level employment is highly competitive, says Helen Cooke, founder
            of My Plus Consulting. She is a wheelchair user herself, and works with organisations
            interested in graduate recruitment to help them to understand and address issues around
            disability.
          </p>
          <p className='mr_content'>
            Despite equal opportunities legislation which makes it illegal to discriminate against
            applicants on the grounds of disability, students still worry that an employer's view of
            them will be tainted if they tell. There is also the embarrassment or even shame factor,
            Cooke points out.
          </p>
          <p className='mr_content'>
            "This can be quite personal information," she says: it even may be a recent diagnosis
            that someone has not yet come to terms with themselves. All a student with a disability
            wants is to be treated like anyone else, and they worry about whether a potential
            employer will be put off by having to make "reasonable adjustments" in the interview
            process, and when a job is secured.
          </p>
          <p className='mr_content'>
            This could create an additional burden of stress at an already stressful time. But
            Michael Smith, 22, who lost his sight in his first year as a student and has just
            graduated from King's College London, says that he uses his disability to his advantage
            and is upfront in every application he makes.
          </p>
          <p className='mr_content'>
            "I always pick up the phone to the firm I'm applying to, and ask if their application
            forms are compatible with my software. It puts a face to my name and they get to know a
            bit about my personality," he explains. Making personal contact gives the human
            resources manager the reassurance of knowing he's able pick up the phone and talk about
            his condition with confidence, he adds.
          </p>
          <p className='mr_content'>
            Taking a positive stance, however, doesn't always lead to the desired result. "My
            experience of work placements have been very mixed," Smith says. "Some firms are very
            disability-confident, very switched on to catering to a visually impaired person, know
            the etiquette, and are always one step ahead of you.
          </p>
          <p className='mr_content'>
            "Other times, there are a lot of things you have to chase, and it becomes obvious that
            they're not that interested. And yes, there have been times I've decided not to bother
            applying after that experience."
          </p>
          <p className='mr_content'>
            Nelson agrees. "I worked for a law firm and asked them about their diversity and
            disability policy and they gave me a blank stare. It's very offputting."
          </p>
          <p className='mr_content'>
            A new website launched by Cooke, Greatwithdisability.com, aims to help both employers
            and students navigate their way through some of these pitfalls, and develop best
            practice in the recruitment process for undergraduate placements and graduate jobs.
          </p>
          <p className='mr_content'>
            "Investment banking have got it, law firms have got it, and some professional services –
            consulting and accountancy – have got it. In all, 9.3% of students register a
            disability, and that's an enormous talent pool that employers really cannot afford to
            overlook," says Cooke.
          </p>
          <p className='mr_content'>So what are the barriers that remain?</p>
          <p className='mr_content'>
            "I actually think that some of the blocks are with individuals themselves," she says. "I
            also think that perhaps they're not getting the right messages from their careers
            services."
          </p>
          <p className='mr_content'>
            One university she picks out for praise is Brunel. There, all students who register a
            disability are invited to a three-way interview together with the disability and
            dyslexia service and their placement adviser from the careers service.
          </p>
          <p className='mr_content'>
            "It's about bringing all the expertise together from the beginning, if they're on a
            sandwich course, or want to do a work placement, before they even start looking,"
            explains operations manager Gill Beech. "We all come together discuss the search
            process, legal issues, the implications of medication that might, for instance, make
            them drowsy in the morning so they can't use public transport to get to work, do they
            need interview and assessment centre adjustments... we really dig into the detail."
          </p>
          <p className='mr_content'>
            The second meeting takes place once the placement or job has been secured, to look at
            what adjustments might be needed in the workplace and communicating that in good time to
            the employer.
          </p>
          <p className='mr_content'>
            It seems to be effective: employment outcomes for Brunel's first degree leavers who
            registered a disability or learning difficulty last year are less than one percent lower
            than for their non-disabled peers.
          </p>
          <p className='mr_content'>
            Things may be moving in the right direction, but Nelson warns that disabled students may
            not aim as high as they could do for fear of taking a fall – and says this applies to
            herself as well.
          </p>
          <p className='mr_content'>
            "The more I learn about employers, the more I lean towards the public sector," she
            explains. "There seems to be more flexibility, better hours, more reasonable
            expectations, so not 80 hours a week at a law firm with all-nighters that a person with
            a condition might struggle with.
          </p>
          <p className='mr_content'>
            "But this may be selling ourselves short, because it might seem easier to apply where
            you're not putting yourself at risk, rather than going for a job where potentially you
            might fail."
          </p>
          <p className='mr_content'>
            There's no doubt too, she adds, that disabled students still have to work far harder to
            ensure their applications are considered equitably. "You have to make a lot of the
            running yourself," she says.
          </p>
          <p className='mr_content'>
            "There's still a huge amount to do, and 20 times a day I bang my head against a brick
            wall," acknowledges Smith, whose proactive hard work has secured him a traineeship at a
            top London law firm.
          </p>
          <p className='mr_content'>
            "But it depends on how positive people are, if they're able to get on in the workplace."
          </p>
        </div>
      </div>
    </div>
  )
}

export default WillDisability

import React from 'react'
import { turning4 } from '../img'

const FiveSecrets = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={turning4} alt='pic' className='article_img' />
        <h2>Five secrets to revising that can boost your grades</h2>

        <div className='text_size mr_content'>
          Tom Stafford
          <br /> The Guardian
          <br /> 8 January 2014
          <br />
          Tags: EDUCATION
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            How do you get the most out of your revision time, and end up with the best grades you
            can? Or, if you're a different sort of student, how can you get the same grades you're
            getting now, but spend less time revising?
          </p>
          <p className='mr_content'>
            Either way, you need to know how to learn better. And fortunately, decades of research
            carried out by psychologists about learning and memory has produced some clear advice on
            doing just that.
          </p>
          <p className='mr_content'>
            As an experimental psychologist, I am especially interested in learning. Most research
            on learning is done in a lab, with volunteers who come in once or twice to learn simple
            skills or lists of words.
          </p>
          <p className='mr_content'>
            Wouldn't it be better, I thought, if we could study learning by looking at a skill
            people are practising anyway? And could we draw links between how people practise and
            how good they eventually get?
          </p>
          <p className='mr_content'>
            Computer games provide a great way to study learning: they are something people spend
            many hours practising, and they automatically record every action people take as they
            practise. Players even finish the game with a score that tells them how good they are.
          </p>
          <p className='mr_content'>
            Using data from a simple online game, my colleague Mike Dewar and I could analyse how
            more than 850,000 people learned to play. The resulting scientific paper, which has just
            been published, shows in unprecedented detail the shape of the learning curve, allowing
            us to test existing theories of learning, as well as suggesting some new ideas on the
            best ways to learn.
          </p>
          <p className='mr_content'>So here are my five evidence-based tips on how to learn:</p>
          <p className='mr_content article-text-bold'>1. Space your practice</p>
          <p className='mr_content'>
            Our analysis showed that people who leave longer gaps between practice attempts go on to
            score higher. In fact, the longer the gaps, the higher the scores.
          </p>
          <p className='mr_content'>
            The difference is huge: people who leave more than 24 hours between their first five
            attempts at the game and their second five attempts score as highly, on average, as
            people who have practiced 50% more than them.
          </p>
          <p className='mr_content'>
            Our finding confirms lots of other research: if you want to study effectively, you
            should spread out your revision rather than cramming. This is easier said than done, but
            if you are organised enough, you can spend less time revising and remember more.
          </p>
          <p className='mr_content article-text-bold'>2. Make sure you fail occasionally</p>
          <p className='mr_content'>
            A new result from our analysis shows that people who are most inconsistent when they
            first start have better scores later on.
          </p>
          <p className='mr_content'>
            Our theory is that these people are exploring how the game works, rather than trying to
            get the very highest score they can every time.
          </p>
          <p className='mr_content'>
            The moral is clear: invest some time in trying things out, which may mean failing
            occasionally, if you want to maximise learning in the long run.
          </p>
          <p className='mr_content article-text-bold'>3. Practise the thing you'll be tested on</p>
          <p className='mr_content'>
            The big mistake many students make is not practising the thing they will be tested on.
            If your exam involves writing an essay, you need to practise essay-writing. Merely
            memorising the material is not enough.
          </p>
          <p className='mr_content'>
            Writing exam answers is a skill, just like playing an online game is a skill. You
            wouldn't try and improve at a game by trying to memorise moves, you'd practise making
            them.
          </p>
          <p className='mr_content'>
            Other research confirms that practising retrieving information is one of the best ways
            to ensure you remember it.
          </p>
          <p className='mr_content article-text-bold'>
            4. Structure information, don't try to remember it
          </p>
          <p className='mr_content'>
            Trying to remember something has been shown to have almost no effect on whether you do
            remember it. The implication for revision is clear: just looking at your notes won't
            help you learn them.
          </p>
          <p className='mr_content'>
            Instead, you need to reorganise the information in some way – whether by making notes of
            your notes, thinking about how what you're reading relates to other material, or
            practising writing answers. This approach, called "depth of processing", is the way to
            ensure material gets lodged in your memory.
          </p>
          <p className='mr_content article-text-bold'>5. Rest and sleep</p>
          <p className='mr_content'>
            New research shows that a brief rest after learning something can help you remember it a
            week later. Other experiments have shown that a full night's sleep helps you learn new
            skills or retain information.
          </p>
          <p className='mr_content'>
            Even napping can help consolidate your memories, and maybe even make you more creative.
            This is great news for those of us who like to nap during the day, and is a signal to
            all of us that staying up all night to revise probably isn't a good idea.
          </p>
        </div>
      </div>
    </div>
  )
}

export default FiveSecrets

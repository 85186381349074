import React from 'react'
import { turning } from '../img'

const TurningLearningRightSide = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={turning} alt='pic' className='article_img' />
        <h2>Turning Learning Right Side Up Book Talk</h2>

        <div className='text_size mr_content'>
          Tags: EDUCATION
          <p className='text_size '>
            Click{' '}
            <a
              href='https://www.youtube.com/watch?v=P_6We74dYxs'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            Studies have shown that we only remember 20 to 30% of what we see and hear. Yet we
            remember 95% of what we teach others! As lecture theatres and class rooms empty across
            the world, it is the teachers and lecturers who have processed and remember the most
            information. Being 'taught' in a classical sense is not a very effective way to learn,
            but ironically, teaching others, is. Russell Ackoff and Daniel Greenberg talk about
            their book Turning Learning Right Side Up which aims to address this inverted teaching
            relationship.
          </p>
        </div>
      </div>
    </div>
  )
}

export default TurningLearningRightSide

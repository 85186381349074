import React from 'react'
import './dsa-incurance.css'
const DsaInsurance = () => {
  return (
    <div className='container'>
      <div className='content_wrapper '>
        <h1 className='mr_head'>DSA Insurance</h1>
        <div className='text_size'>
          <h2>Overview</h2>
          <p className='mr_content'>
            Our DSA insurance is provided by Specialty Risks Limited. You can find out more about
            their insurance service by following this{' '}
            <a
              className='incurance_link'
              href='https://www.specialty-risks.com/about-our-insurance-services/'
              target='_blank'
              rel='noreferrer'>
              {' '}
              link
            </a>
            . Download{' '}
            <a target='_blank' className='incurance_link' href='pdf/DSA_IPID_01082021.pdf'>
              Insurance Summary Document
            </a>
            . Download{' '}
            <a
              className='incurance_link'
              href='pdf/Assistive_Solutions_DSA_EOC_01082021.pdf'
              target='_blank'>
              Full Policy Details
            </a>
            . Your DSA Equipment is insured from the day it’s delivered. You will receive policy
            details by email in due course.
          </p>
          <h2>Policy cover and features</h2>
          <ul className='ul-insurance mr_content '>
            <li> &bull; Accidental damage</li>
            <li> &bull; Fire damage</li>
            <li> &bull; Liquid damage</li>
            <li> &bull; Malicious damage (by a third party)</li>
            <li> &bull; Theft</li>
            <li> &bull; Worldwide cover as standard</li>
            <li> &bull; Zero excess</li>
            <li> &bull; Unlimited number of claims</li>
            <li> &bull; 1 to 5 years cover to meet the duration of course</li>
            <li> &bull; Full reinstatement of software and hardware</li>
          </ul>
          <h2>Make a claim</h2>
          <p className='mr_content'>
            If you would like to make an insurance claim, please follow this{' '}
            <a
              className='incurance_link'
              target='_blank'
              href='https://www.specialty-risks.com/dsa-claims/'
              rel='noreferrer'>
              link
            </a>
            .
          </p>
          <h2>Insurance of Own Computer Equipment</h2>
          <p className='mr_content'>
            If you would like to insure your own computer equipment, please follow this{' '}
            <a
              href='https://www.specialty-risks.com/dsa-equipment/'
              className='incurance_link'
              target='_blank'
              rel='noreferrer'>
              link
            </a>
            . Eligibility criteria applies.
          </p>
        </div>
      </div>
    </div>
  )
}

export default DsaInsurance

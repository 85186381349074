import React from 'react'
import { ed } from '../img'

const MentalHealthAndHigherEducation = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={ed} alt='pic' className='article_img' />
        <h2>
          Mental health and higher education: 'I won't let depression hold back my academic career'
        </h2>
        <p className='text_size mr_content'>Tags: Mental Health, Education</p>
        <div className='text_size'>
          <p className='mr_content'>Guardian</p>
          <p className='mr_content'>
            I've suffered from periodic bouts of depression for all of my working life – some 30
            plus years. I wanted to write this article to demonstrate that a successful career,
            mental illness and enjoyment of life need not be incompatible. In fact, at an objective
            level, I am highly successful and know just how fortunate I am: I have a staunch (and
            long-suffering) wife, two wonderful children, and am acknowledged for my teaching
            excellence, as a National Teaching Fellow.
          </p>
          <p className='mr_content'>
            But, on a regular basis, the trap door opens and I fall through it in to a nightmare
            world. It seems to be stress and a feeling that everything is spiralling out of control
            that triggers my depression. In fact it was only about eight years ago that I finally
            had a label pinned to what I suffer – generalised anxiety disorder. Which is a pretty
            fair description in the sense that, when the affliction is upon me I am racked by worry,
            insecurity, dread; all of which manifest themselves as a racing heart, sweating, and an
            overriding sense of impending doom; that I'm about to explode or disintegrate. It's very
            frightening. And the teaching fellowship, and 30 plus years of a highly successful
            career, just evaporate.
          </p>
          <p className='mr_content'>
            As a counsellor observed, some years back, I'm like a plane that mostly cruises at
            30,000 feet; but then I hit bad weather and plummet to about 10,000 feet. I've never had
            a day off work as a result of this. It's like apples and pears; the apples (objective
            evidence of my success in all areas of life) just doesn't cut it when set against the
            pears (feelings of insecurity and depression).
          </p>
          <p className='mr_content'>
            Of course I know that I am far from alone. In 2006 the London School of Economics and
            Political Science published The depression report: a new deal for depression and anxiety
            disorders.
          </p>
          <p className='mr_content'>
            It's worth quoting from at length: "Crippling depression and chronic anxiety are the
            biggest causes of misery in Britain today. They are the great submerged problem, which
            shame keeps out of sight. But if you mention them, you soon discover how many families
            are affected. According to the respected Psychiatric Morbidity Survey, one in six of us
            would be diagnosed as having depression or chronic anxiety disorder, which means that
            one family in three is affected. That is the bad news. The good news is that we now have
            evidence-based psychological therapies that can lift at least a half of those affected
            out of their depression or their chronic fear."
          </p>
          <p className='mr_content'>
            As proof of this, I don't feel able to 'come out' in this article. I am not ashamed but
            I do wonder how the relationship with colleagues, and pursuit of promotion might be
            affected if it was generally known that I suffer from depression. In fact over the past
            few years I have been increasingly open in explaining to others how I become. It really
            is a case of Jekyll and Hyde. When life is in focus and I am well, I am sociable,
            extrovert, outward looking and love life. When stress triggers my anxiety and depression
            I become consumed by worry, with panic just boiling below the surface. You know the
            sequence at the end of the Lord of the Rings when the eye of Sauron looks within (too
            late to find Frodo the ring bearer) that is how it is for me, an instant removal from
            looking outwards, and a sudden focus within. I also become compulsive and exhausted. My
            sleep pattern goes to hell. I have vivid dreams and revolve around a mantra of worries
            as I drag myself through days.
          </p>
          <p className='mr_content'>
            It feels like I am speaking through treacle, everything is such an effort. Can you
            imagine the distress and effort in confronting students and classes day by day? When I
            feel depressed I really have to steel myself for lectures and student contact; when all
            I want to do is run from the room. I use student-centred approaches, interactive
            sessions, handouts, PowerPoint presentations to take the pressure off - and get them
            concentrating on something other than me.
          </p>
          <p className='mr_content'>
            Another way I deal with this is to plan ahead. When well I prepare session plans for way
            ahead. In so doing I have the security that even if unwell, I have the materials to draw
            on. Over time I have learned that these bouts last for about three weeks, and during
            that time, I try to take my pedal off the gas, in the sense of doing what's essential
            rather than extras I might like to do.
          </p>
          <p className='mr_content'>
            I also try to focus on what's happening around me, rather than to me; and now recognise
            that apart from my closest colleagues and family, I really don't think others notice –
            they are too busy with their own lives and concerns. And I don't say this in a
            pejorative way. Family is different. My wife and children see into my heart of darkness.
            Particularly for my wife I recognise how tough it is to be with someone for over 25 plus
            years of marriage, and to experience my depressions every four months or so. It really
            is time I got her that eternity ring. Another means of coping is to reduce commitments
            and resist doing things that add stress (conference presentations and travel) or that
            are unnecessary.
          </p>
          <p className='mr_content'>
            Finally, I love the words of the Roman poet Catullus who was obviously suffering, when
            he said: "One day all this will seem funny."
          </p>
        </div>
      </div>
    </div>
  )
}

export default MentalHealthAndHigherEducation

import React from 'react'
import { pic_14 } from '../img'

const NorahResearch = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={pic_14} alt='pic' className='article_img' />
        <h2>The Norah Fry Research Centre</h2>

        <div className='text_size mr_content'>Tags: MENTAL_HEALTH</div>
        <div className='text_size'>
          <p className='mr_content'>
            Part of the University of Bristol's Department of Mental Health. The Norah Fry Research
            Centre researches issues affecting the lives of disabled children and adults with
            learning difficulties and their families and, through their research findings, aims to
            influence policy, improve services and support, empower and inform service users,
            families and professionals.
          </p>
          <p className='mr_content'>
            Visit the{' '}
            <a
              href='http://www.bristol.ac.uk/sps/research/centres/norahfryresearch/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              The Norah Fry Research Centre website
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default NorahResearch

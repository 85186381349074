import React from 'react'
import './home.css'
import { Link } from 'react-router-dom'
import yoga_1 from '../../assets/img/yoga_1.svg'
import collegs from '../../assets/img/collegs.png'
import improve from '../../assets/img/improve.png'
import home_title from '../../assets/img/home_titile.svg'
const Home = () => {
  return (
    <div className='home_container '>
      <div className='home_head '>
        <div className='home_container_head'>
          <div className='home_head_content '>
            <h1>Welcome</h1>
            <p className='text_size mr_content_home'>
              Order your Computer or Ergonomic Equipment, or <br /> Book Assistive Technology
              Training, Study Skills Tuition <br /> and Specialist Mentoring.
            </p>
            <div className='home_start_btn btn_bg'>
              <Link to='/order' className='start_link_home '>
                Start here
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='home_main_content_wrapper'>
        <div className='container'>
          <div className='home_main_title'>
            <img
              src={home_title}
              alt='Everything we do is about helping you improve the way you work.'
            />
          </div>
        </div>
        <div className='container '>
          <div className='home_content_wrapper mr_content'>
            <h2 className='home_main_title_h2'>Work smarter</h2>
            <p className='home_main_p_smart mr_content'>
              Can you save time and improve the quality of your work output? Employing software
              tools, new working strategies and 1-1 coaching, we help people accelerate professional
              work and studies.
            </p>

            <div className='focus_yog'>
              <h2 className='home_main_title_h2_content'>Focus on your wellbeing</h2>
              <div className='focus_content'>
                <p className='home_main_p mr_content'>
                  The burdens we face in academic and professional lives put tangible pressure on
                  all of us. Ensuring you are focused and fit is fundamental to completing
                  commitments and being able to enjoy your work. Without a foundation of well-being,
                  your work may well suffer. Our experienced Coaches are ready to help.
                </p>
                <img className='focus_img' src={yoga_1} alt='pic' />
              </div>
            </div>
            <div className='develop'>
              <img src={collegs} className='develop_img' alt='pic' />
              <div className='develop_content'>
                <p className='develop_title_h2_content'>
                  Develop with support from experienced tutors
                </p>
                <p className='home_main_p mr_content'>
                  We recognise that working 1-1 with you is a great privilege. We get to tailor
                  sessions just to you, which is a rarity within most institutions. Formal protocols
                  tend to favour a one-size-fits-all approach, but we get to focus on you: how you
                  work and learn best. Mentors, Coaches and Tutors use their experience to help you
                  individualise the way you work and study.
                </p>
              </div>
            </div>
            <div className='improve'>
              <div>
                <h2 className='home_main_title_h2_content'>Improve work & learning outcomes</h2>
                <div className='home_main_p mr_content'>
                  Working in organisations, our clients improve their work efficiency and job
                  satisfaction as they take control of their own work practices, and make their job
                  easier. Students who take the DSA award go on to attain higher marks and complete
                  their studies at{' '}
                  <a
                    href='https://www.hesa.ac.uk/data-and-analysis/performance-indicators/widening-participation-summary#disability'
                    target='_blank'
                    rel='noreferrer' className='home-link'>
                    unparalleled rates
                  </a>
                  . The DSA can help to adapt learning to you, and we encourage you to utilise
                  Tutors knowledge to explore fresh ways of working. It is down to you what you make
                  of working adjustments, but our friendly team are here to support you, and we will
                  facilitate your advancement wherever we can.
                </div>
              </div>
              <img src={improve} alt='pic' className='improve_img' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home

import React from 'react'
import { dwarf } from '../img'

const MentalElf = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={dwarf} alt='pic' className='article_img' />
        <h2>The Mental Elf: More mental health support needed for students in higher education</h2>

        <div className='text_size mr_content'>
          Tags: Mental Health
          <p className='text_size '>
            Click{' '}
            <a
              href='
          http://www.thementalelf.net/treatment/psychological-therapies/more-mental-health-support-needed-for-students-in-higher-education/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            The Royal College of Psychiatrists has today published a new report into the mental
            health of students in higher education. The report calls for more investment in mental
            health support for university students, amid concerns that some students with mental
            health problems may be missing out on the help they need.
          </p>
          <p className='mr_content'>
            The College published its first report on the mental health of students in higher
            education back in 2003. This new and fully-updated report provides a complete
            re-examination of the issues.
          </p>
          <p className='mr_content'>
            According to the authors of the report, the student population has changed enormously
            over the last decade. Overall student numbers have grown, more students are entering
            higher education from socially and culturally-diverse backgrounds, and there are growing
            numbers of international students. Students are also struggling with rising debt and
            fewer employment opportunities – all of which may be impacting on their mental health
            and well-being.
          </p>
          <p className='mr_content'>
            Dr John Callender, consultant psychiatrist and chair of the working group that produced
            the report, said:
          </p>
          <p className='mr_content'>
            The demand for counselling and mental health advisor services continues to rise.
            Although there are some excellent support services for students across the country, in
            many universities the provision of services has not kept pace with expanding student
            numbers – leaving existing services overstretched.
          </p>
          <p className='mr_content'>
            A recent survey indicated that about 4% of university students are seen by counsellors
            each year for a wide range of emotional and psychological difficulties. And many
            counsellors and mental health advisors in universities are reporting increased demand
            from students for their services.
          </p>
          <p className='mr_content'>Dr Callender continued:</p>
          <p className='mr_content'>
            We know that universities are facing large funding cuts. But it's vital that they
            continue to support student counselling and mental health advisor roles. Academic
            success, physical health and psychological well-being are closely linked, and
            institutions need to be aware of the importance of looking after their students' health.
          </p>
          <p className='mr_content'>
            Dr Leonard Fagin, consultant psychiatrist and a co-author of the report said:
          </p>
          <p className='mr_content'>
            There are concerns that universities are programming cuts that will affect provision of
            counselling and psychiatric services to students, preventing effective early
            intervention. Likewise, cuts to mental health services could also affect the provision
            of coordinated services to young people known to them who are attending higher
            educational institutions.
          </p>
          <p className='mr_content'>
            The report states that the student population is in some ways more vulnerable than other
            young people. First year students have to adapt to new environments and ways of
            learning, and the transition from home to university can be a difficult period. Mature
            students in particular may find themselves isolated, and in the university environment
            there can be significant peer pressure to misuse alcohol and drugs. for serious mental
            illnesses like schizophrenia and bipolar disorder, the peak age of onset is during the
            university years, between age 18-25.
          </p>
          <p className='mr_content'>The report makes a series of key recommendations, including:</p>
          <p className='mr_content'>
            All higher education institutions should have a formal mental health policy, and ensure
            that training in the recognition of mental disorder and suicide risk is offered to
            academic and other institutional staff who work with students.
          </p>
          <p className='mr_content'>
            Particular attention should be paid to the needs of vulnerable groups of students, such
            as international students and those with a history of mental disorder.
          </p>
          <p className='mr_content'>
            The 'Healthy Universities' network, which provides a whole university approach to
            promoting health, is adopted as widely as possible.
          </p>
          <p className='mr_content'>
            New, high-quality research should be commissioned to determine the nature and prevalence
            of mental disorders (including drug and alcohol use) in the UK student population.
            Higher education institutions and the NHS should work together more closely to formulate
            local and national policies in relation to the mental well-being of students.
          </p>
          <p className='mr_content'>
            The departments of health in the four UK countries establish special funding provision
            for dedicated student health services provided by GPs. At present, the ways in which
            general practice is funded in the UK leads to substantially lower remuneration for GPs
            who work with student populations, which could threaten the viability of these services
            in future. Eileen Smith, co-author of the report and Head of Counselling at the
            University of Hertfordshire, said:
          </p>
          <p className='mr_content'>
            Feedback from students suggests that a timely counselling intervention can enable
            improved academic performance and reduce drop-out rates, thereby saving the waste of
            individual potential and universities' resources. Mental health is not a minority issue:
            we all have minds and emotions which affect our sense of ourselves, our ability to make
            relationships, to work and to contribute to society. It is only by promoting the mental
            health of all students and staff that we can create a healthy university that is able to
            support its more vulnerable members.
          </p>
          <p className='mr_content'>
            <a
              href='
              https://www.rcpsych.ac.uk/files/pdfversion/CR166.pdf'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Mental health of students in higher education.
            </a>{' '}
            Royal College of Psychiatrists, College Report CR166, 30 Sep 2011.
          </p>
        </div>
      </div>
    </div>
  )
}

export default MentalElf

import { useState, useEffect } from 'react'
import './App.css'
import MobileMenu from './components/Navbar/MobileMenu'
import Navbar from './components/Navbar/Navbar'
import MainRoutes from './MainRoutes'
import Footer from './components/footer/Footer'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

function App() {
    const [show, setShow] = useState(false)
    const theme = useSelector((state) => state.theme)
    const location = useLocation()
    const dispatch = useDispatch()

  
    return (
        <div
            className={
                theme === 'yellow-theme'
                    ? 'main_container_yellow'
                    : theme === 'blue-theme'
                    ? 'main_container_blue'
                    : theme === 'purple-theme'
                    ? 'main_container_purple'
                    : theme === 'green-theme'
                    ? 'main_container_green'
                    : theme === 'dark-light-theme'
                    ? 'main_container_dark_light'
                    : theme === 'dark-yellow-theme'
                    ? 'main_container_dark_yellow'
                    : 'main_container'
            }
        >
            <header>
                {show ? <MobileMenu setShow={setShow} show={show} /> : <Navbar setShow={setShow} show={show} />}
            </header>
            <div
                className={
                    show
                        ? 'page_none'
                        : location.pathname === '/'
                        ? 'page_home'
                        : location.pathname === '/coming-soon'
                        ? null
                        : location.key === 'default'
                        ? null
                        : 'page'
                }
            >
                <div className='main_content'>
                    <div className={location.pathname === '/' ? null : 'back_pic'}>
                        <MainRoutes />
                    </div>
                </div>
                {location.pathname !== '/admin' && location.pathname !== '/new' && (
                    <footer>
                        <Footer />
                    </footer>
                )}
            </div>
        </div>
    )
}

export default App

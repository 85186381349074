import React from 'react'
import './Tag.css'
import { studentsTags } from '../resources-items'

const TagStudents = ({ handler, select }) => {
  return (
    <div className='tags_wrapper'>
      {studentsTags.map(tag => (
        <div
          className={select === tag.title ? 'tag_active' : 'tag'}
          key={tag.title}
          onClick={() => handler(tag.title, tag.value)}>
          {tag.title}
        </div>
      ))}
    </div>
  )
}

export default TagStudents

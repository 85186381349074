import React from 'react'
import { pic_4 } from '../img'

const AspergerIsOurNormal = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
      <img src={pic_4} alt='pic' className='article_img' />
        <h2>Asperger's: 'This is our normal'</h2>
        <div className='text_size mr_content'>
          Giulia Rhodes
          <br />
          Tags: ASD
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            Jennifer Cook O'Toole and her husband, John, with their children Maura, Sean and Gavin:
            'My way of learning these unspoken social rules is to write them down.'
          </p>
          <p className='mr_content'>
            Maura, nine, is an expert on ancient history. Her brother Sean, six, specialises in the
            classification of animals. Gavin, the youngest, has an encyclopaedic knowledge of
            Spiderman – a little less high-brow, but then he is only three.
          </p>
          <p className='mr_content'>
            All three like to hold forth on their chosen subjects – regardless of whether anyone
            else is listening or, for that matter, speaking. Family meal-times, admits their mother,
            Jennifer Cook O'Toole, can be quite a headache-inducing affair.
          </p>
          <p className='mr_content'>
            Fortunately, Jennifer and her husband, John, are not averse to delivering impassioned
            monologues themselves – about, respectively, the history of monarchy and astronomy – if
            the mood takes them.
          </p>
          <p className='mr_content'>
            There is, of course, no such thing as a "normal" family, and the Cook O'Tooles are more
            aware of that truism than most. They are a self-styled "Asper-family" – over the past
            three years all five of them have been diagnosed with Asperger's syndrome, a
            high-functioning form of autism.
          </p>
          <p className='mr_content'>
            "This is our normal. We've always been like this, even before we knew why," says
            Jennifer, 36. "I don't speak for all Aspies, like I don't speak for all women, or all
            red-heads – but I definitely can't tell you what it's like to not be any of those
            things."
          </p>
          <p className='mr_content'>
            The family is visiting Britain from their home in Charlotte, North Carolina, for the
            publication of Jennifer's book about social skills for children with Asperger's.
          </p>
          <p className='mr_content'>
            Maura's diagnosis, three years ago, was the first. She had been a difficult baby –
            crying constantly and vomiting frequently. Her parents soon suspected a physical cause
            for her distress and, after a long, stressful investigation, she was diagnosed with
            tethered cord syndrome (a neurological abnormality of the spinal cord) at the age of
            four, and underwent surgery.
          </p>
          <p className='mr_content'>
            Her parents were looking forward to life after years of doctors and hospitals and Maura
            was now old enough for school. She sat entrance tests for a private school that John and
            Jennifer felt would best suit her gradually recovering health. The results were
            surprising – there was a huge gulf between her scores in verbal and non-verbal
            reasoning. That led to the diagnosis of Asperger's.
          </p>
          <p className='mr_content'>
            John, meanwhile, was seeing a psychologist, at Jennifer's insistence. "The trauma of
            having a chronically sick child was huge and John had no close friends to talk to. I
            felt he needed someone other than me to help," she says.
          </p>
          <p className='mr_content'>
            The psychologist realised that John was also on the autistic spectrum. The diagnosis
            surprised the couple, though Jennifer now can't think why. "It was so obvious – I always
            had to tell him to look at me – I was so busy picking over the children that I wasn't
            thinking about us. Then I went along to a talk, so I could understand John better, and
            had this moment of realisation about myself."
          </p>
          <p className='mr_content'>
            As a child, Jennifer had been taken to numerous psychologists by her mother, who was
            baffled by her daughter's inability to socialise, only to be told each time that the
            child was simply too bright for her own good. "That was ridiculous – lots of children
            are very clever but they didn't have the same problems. Actually, I had Asperger's. All
            of a sudden, things made sense. For me, it was an utter relief."
          </p>
          <p className='mr_content'>
            Gavin's diagnosis, aged two – the final piece in the family jigsaw – was "a no-brainer".
            Jennifer had compiled a file of evidence from the start – "the lining up of toys and so
            on".
          </p>
          <p className='mr_content'>
            Realising that they, as parents, shared the same difficulties as their children was
            briefly frightening for John and Jennifer. "Then we took a deep breath and realised we
            were doing it anyway. The years when Maura was sick kept things in perspective; we just
            felt even more protective, and knew we could cope."
          </p>
          <p className='mr_content'>
            The idea that their own special needs could be an advantage to their children occurred
            soon afterwards. "A friend whose son also has autism kept asking me questions that
            seemed obvious to me. I realised I was on to something. I have 36 years of experience –
            but I am like them and usually I have made the mistake myself. I can tell them that. We
            know what it is to feel deficient, other, less. I hope that stops our advice sounding
            condescending, and that it can save them from some of those feelings."
          </p>
          <p className='mr_content'>
            The book Jennifer has published is a notebook she began for herself. "My way of learning
            these unspoken social rules everyone else just instinctively knew is to write them down.
            It is a tool for the future. It's like trying to learn a bit of German if you go to live
            in Germany."
          </p>
          <p className='mr_content'>
            A section of bullet point reminders offers a glimpse of life with her Asper-family.
            "When a door opens, wait for other people to exit before you walk in", "Even excuse me
            becomes an interruption if you keep saying it", "Don't change the TV channel if other
            people are watching the show."
          </p>
          <p className='mr_content'>
            Following what she calls "the rules" is also Jennifer's technique for strengthening her
            relationship in the face of her own and John's interpersonal weaknesses.
          </p>
          <p className='mr_content'>
            The couple see a psychologist together once a month, a requirement Jennifer likens to
            regular dental check-ups. "We know we aren't good at communication so we put work in –
            and we must set a good example to our children. We make more effort than many couples
            simply because we have to.
          </p>
          <p className='mr_content'>
            "When Maura was ill and John and I were physically, emotionally and financially
            exhausted, it was actually the Aspie mindset that kept us moving forward – we're
            married, we have to stick at it together. Now sharing that diagnosis is like wearing the
            same team shirt, a reminder we are on the same side."
          </p>
          <p className='mr_content'>
            Though they are clearly very happy, Jennifer says that their early relationship was
            maddening. Flummoxed by the minefields of dating and fashion, she had "systematically
            studied" Just Seventeen magazine and the 1995 dating bestseller, The Rules.
          </p>
          <p className='mr_content'>
            On their first date, she was deeply perplexed to learn that John had made absolutely no
            plans. "I had learned how it was meant to work. He was supposed to compliment me and
            then take me to a show or something. After seven months of seeing each other I
            eventually asked if he thought I was pretty. He said, 'Of course'."
          </p>
          <p className='mr_content'>
            Much of what Jennifer saw in John she recognised from her father. He died of lung cancer
            five years ago, never having heard of Asperger's."It is obvious to me where my
            Asperger's comes from. My father never understood how he could be a brilliant litigator
            in court rooms around the world and yet want to die at parties. He would be so on edge
            he would bump into people, rock back and forth. He self-medicated continuously with
            Scotch and cigarettes."
          </p>
          <p className='mr_content'>
            Jennifer recalls a heartbreaking conversation in which, with tears in his eyes, he told
            her, "I'm so glad you don't have the trouble with people that I do." (Of course she did,
            she says, "but he was easily fooled".)
          </p>
          <p className='mr_content'>
            When he seemed cold in the face of her upsets – one year she was desperate enough to ask
            Father Christmas for a friend – she realises he was simply unable to cope.
          </p>
          <p className='mr_content'>
            His loneliness is proof enough to Jennifer that labels should not be feared. "Parents
            often say they don't want their children to be labelled with Asperger's, or that people
            managed fine before the diagnosis existed.
          </p>
          <p className='mr_content'>
            "I can't help but be offended. I feel we are as we should be. The word might be new, but
            the people aren't. There was just more depression, divorce and suicide. If diagnosis
            brings the right support, understanding and self-esteem then that is a very good thing."
          </p>
          <p className='mr_content'>
            Asperger's is only one part of who they are as individuals and as a family though. "I
            don't want Asperger's itself to become our special interest. The children go to school
            and get to be normal kids too. I am also just another mum who sometimes wants to pull
            her hair out.
          </p>
          <p className='mr_content'>
            "I certainly wouldn't change things – apart from maybe Spiderman. Two years of hearing
            about him all the time really is hardgoing."
          </p>
        </div>
      </div>
    </div>
  )
}

export default AspergerIsOurNormal

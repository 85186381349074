import React from 'react'
import { pic_7 } from '../img'

const WhyTheDeafHaveEnhancendVision = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
      <img src={pic_7} alt='pic' className='article_img' />
        <h2>Why the Deaf Have Enhanced Vision</h2>
        <div className='text_size mr_content'>
          Ker Than
          <br />
          National Geographic News
          <br />
          October 11, 2010
          <br/>
          Tags: Deaf aware
          <p className='text_size '>
            Click{' '}
            <a
              href='https://www.nationalgeographic.co.uk/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            Deaf people with enhanced vision can thank otherwise idle brain cells for their
            heightened sense, a new study in cats suggests.
          </p>
          <p className='mr_content'>
            That's because the brain recruits cells normally devoted to hearing to help them see
            better, the research revealed
          </p>
          <p className='mr_content'>
            "The brain is very efficient and it's not going to let this huge territory that is the
            auditory cortex and all the processing that it has go to waste," said study leader
            Stephen Lomber of Canada's University of Western Ontario. The auditory cortex is the
            part of the brain that controls hearing.
          </p>
          <p className='mr_content'>
            "So it makes sense that other senses will come in and colonize."
          </p>
          <p className='mr_content'>Deaf-Cat Experiments Reveal Brain's Wiring</p>
          <p className='mr_content'>
            In behavioral tests, Lomber and his team determined that domestic cats born deaf have
            better peripheral vision and motion-detection abilities than cats born with normal
            hearing—a finding that parallels visual test results in deaf people.
          </p>
          <p className='mr_content'>
            Next, the researchers used a surgical method called reversible deactivation to
            temporarily cool and render inoperative parts of the brain. This enabled the scientists
            to pinpoint which parts of the brain were responsible for the enhanced visual abilities.
          </p>
          <p className='mr_content'>
            (Related: "Brain's 'Core' Revealed by First Hi-Res Wiring Map.")
          </p>
          <p className='mr_content'>
            "Reversible deactivation is very powerful because you can test an animal before you
            deactivate an area of the brain, again while the area is deactivated, and a final time
            when the brain is rewarmed afterward," said study team member Alex Meredith, a
            neuroscientist at Virginia Commonwealth University.
          </p>
          <p className='mr_content'>"It's like having a stroke without losing brain tissue."</p>
          <p className='mr_content'>
            The scientists found that when they cooled the part of the deaf cats' auditory cortex
            involved in peripheral hearing, the animals lost their peripheral vision advantage.
          </p>
          <p className='mr_content'>
            Likewise, when the scientists deactivated the part of the brain normally involved in
            discerning which direction a sound was coming from, the deaf animals fared no better
            than normal cats in visually detecting motion. (See brain pictures.)
          </p>
          <p className='mr_content'>
            "These visual functions [that are enhanced] don't just randomly redistribute" in the
            auditory cortex, Lomber said.
          </p>
          <p className='mr_content'>
            "They actually seem to take up residence in an auditory area that would perform a
            similar function."
          </p>
          <p className='mr_content'>Improved Treatment for the Deaf?</p>
          <p className='mr_content'>
            More studies will reveal if same is true in humans, the authors said.
          </p>
          <p className='mr_content'>
            But the cat experiments do explain "why, in deaf humans, some visual skills get better
            and others do not change at all," said Daphne Bavelier, a neuroscientist at the
            University of Rochester in New York who was not involved in the study.
          </p>
          <p className='mr_content'>
            The research, published October 11 in the journal Nature Neuroscience, also seems to
            explain why deaf individuals who receive a cochlear implant later in life don't regain
            as much of their hearing compared with people who receive the implants as young
            children.
          </p>
          <p className='mr_content'>
            A cochlear implant is a small electronic device surgically inserted under the skin that
            can give a sense of sound to a deaf person.
          </p>
          <p className='mr_content'>
            "If you delay the implantation, then the brain reorganization that occurred [in early
            life] is more or less locked in," study leader Lomber said. "The brain's lost the
            ability to reorganize a second time and push the visual functions out."
          </p>
          <p className='mr_content'>
            But Lomber and colleagues say the research could lead to improved cochlear implants that
            target specific regions of the auditory cortex, such as the part involved in
            understanding speech, for example.
          </p>
          <p className='mr_content'>(Read "Beyond the Brain" in National Geographic magazine.)</p>
          <p className='mr_content'>
            "If you can understand the changes that the brain is going to undergo and the areas that
            you really want to target with your signals, then you can create a next-generation type
            of cochlear implant that better serves the needs of the brain," Lomber said.
          </p>
          <p className='mr_content'>
            Bavelier, who wrote a commentary about the research in Nature Neuroscience, agreed.
          </p>
          <p className='mr_content'>
            Understanding how the auditory cortex functions in young deaf people is "critical to our
            understanding of how to maximize the chances of successful implant," she said.
          </p>
        </div>
      </div>
    </div>
  )
}

export default WhyTheDeafHaveEnhancendVision

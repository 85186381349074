import { SET_THEME } from "../types/types";

export const themeReducer = (state = null, action) => {
    const {type, payload} = action 
    switch (type) {
        case SET_THEME:
            return payload
        
        default:
            return state
    }
}
import { SEND_EMAIL } from '../types/types'

export const emailReducer = (state = false, action) => {
    const { type, payload } = action
    switch (type) {
        case SEND_EMAIL:
            return payload
        default:
            return state
    }
}

import {
  SET_THEME,
  SEND_EMAIL,
} from '../types/types'
import axios from 'axios'
export const setTheme = value => async dispatch => {
  dispatch({ type: SET_THEME, payload: value })
}

export const sendMail = value => async dispatch => {
  dispatch({ type: SEND_EMAIL, payload: 'pending' })
  const config = {
    headers: { 'content-type': 'multipart/form-data' }
  }
  await axios
    .post('/send', value, config)
    .then(res => (res.status === 200 ? dispatch({ type: SEND_EMAIL, payload: false }) : ''))
}

import React from 'react'
import { bsl_tutors } from '../img'

const AssistiveSolutionsBslTutors = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={bsl_tutors} alt='pic' className='article_img' />
        <h2>Assistive Solutions BSL Tutors</h2>

        <div className='text_size'>
          <p className='mr_content'>
            Specialist BSL Tutors are fully equipped to teach common assistive software and working
            strategies. BSL Tutors are available in Brighton, Medway, Kent and London. Neutral
            training rooms are available for people to take their training in Central London. All
            BSL Tutors have a minimum BSL Level 4. Please contact us for a quote.
          </p>
        </div>
      </div>
    </div>
  )
}

export default AssistiveSolutionsBslTutors

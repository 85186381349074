import React from 'react'
import { pic_22 } from '../img'

const EqualityAct = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={pic_22} alt='pic' className='article_img' />
        <h2>Equality Act 2010</h2>
        <div className='text_size'>
          <p className='mr_content'>
            The{' '}
            <a
              href='https://www.legislation.gov.uk/ukpga/2001/10/contents'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Equality Act 2010
            </a>{' '}
            brought together a range of different legislations relating to discrimination and
            harassment.
          </p>
          <p className='mr_content'>
            The section relating directly to disability, starts from the premise that people must
            not be treated unfairly because of something that arises due to that person's
            disability. Within education or the workplace, discrimination against an individual
            because of their disability is unlawful. For more information on what constitutes a
            disability, the Equalities Act, and how to act if you have been discriminated against,
            follow the links below.
          </p>

          <p className='mr_content'>
            <a
              href='http://www.dls.org.uk/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              http://www.dls.org.uk/
            </a>
          </p>
          <p className='mr_content'>
          <a
              href='http://www.disabilityrightsuk.org/accesstoelectedofficefund.htm'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              http://www.disabilityrightsuk.org/accesstoelectedofficefund.htm
            </a>
            
          </p>
        </div>
      </div>
    </div>
  )
}

export default EqualityAct

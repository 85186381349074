import React, { useEffect, useState } from 'react'
import './tech-support.css'
import { generateEmail } from '../order-form/generateEmail'
import Loader from '../../../components/loader/Loader'
import emailjs from '@emailjs/browser'
import { useRef } from 'react'
const TechnicalSupport = () => {
  const [isSend, setIsSend] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [info, setInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    notes: ''
  })
  const [infoDirty, setInfoDirty] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    notes: false
  })

  const [infoError, setInfoError] = useState({
    firstName: 'First Name cannot be empty',
    lastName: 'Last Name cannot be empty',
    email: 'Email cannot be empty',
    phone: 'Invalid Phone Number',
    notes: 'Note cannot be empty'
  })
  const form = useRef()
  const blurHandler = e => {
    switch (e.target.name) {
      case 'firstName':
        setInfoDirty(prev => ({
          ...prev,
          firstName: true
        }))
        break
      case 'lastName':
        setInfoDirty(prev => ({
          ...prev,
          lastName: true
        }))
        break
      case 'notes':
        setInfoDirty(prev => ({
          ...prev,
          notes: true
        }))
        break
      case 'phone':
        setInfoDirty(prev => ({
          ...prev,
          phone: true
        }))
        break
      case 'email':
        setInfoDirty(prev => ({
          ...prev,
          email: true
        }))
        break
    }
  }

  const infoEmailHandler = e => {
    setInfo(prev => ({
      ...prev,
      email: e.target.value
    }))
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

    if (!re.test(String(e.target.value).toLowerCase())) {
      setInfoError(prev => ({
        ...prev,
        email: 'Invalid Email'
      }))
    } else {
      setInfoError(prev => ({
        ...prev,
        email: ''
      }))
    }
  }

  const infoPhoneHandler = e => {
    const clearedValue = e.target.value.replace(/[^\._\-\d\+\/(\)]/, '')

    setInfo(prev => ({
      ...prev,
      phone: clearedValue
    }))
    const phTest = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)[0-9]\d{3}(\s)?\d{6}/g
    if (!phTest.test(String(e.target.value).toLocaleLowerCase())) {
      setInfoError(prev => ({
        ...prev,
        phone: 'Invalid number'
      }))
    } else {
      setInfoError(prev => ({
        ...prev,
        phone: ''
      }))
    }
  }
  const infoInputHandler = e => {
    setInfo(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  useEffect(() => {
    if (info.firstName.length < 2) {
      setInfoError(prev => ({
        ...prev,
        firstName: 'Invalid First Name'
      }))
    } else {
      setInfoError(prev => ({
        ...prev,
        firstName: ''
      }))
    }
    if (info.lastName.length < 2) {
      setInfoError(prev => ({
        ...prev,
        lastName: 'Invalid Last Name'
      }))
    } else {
      setInfoError(prev => ({
        ...prev,
        lastName: ''
      }))
    }

    if (info.notes.length < 2) {
      setInfoError(prev => ({
        ...prev,
        notes: 'Invalid question'
      }))
    } else {
      setInfoError(prev => ({
        ...prev,
        notes: ''
      }))
    }
  }, [info])
  const sendSubmit = e => {
    e.preventDefault()
    setIsLoading(true)

    emailjs
      .sendForm(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID_SUPPORT_FORM,
        form.current,
        process.env.REACT_APP_YOUR_PUBLIC_KEY
      )
      .then(
        res => {
          try {
            setIsLoading(false)
            if (res.text !== 'OK') {
              setIsLoading(false)
              setError(true)
            }
            setIsSend(true)
          } catch (error) {
            setIsLoading(false)
            setError(true)
          }
        },
        error => {
          setIsLoading(false)
          setError(true)
        }
      )
  }

  if (error) {
    return (
      <div className='container'>
        <div className='content_wrapper error_tech'>
          <h1 className='mr_head'>Technical Support</h1>
          <div className='mr_content order_success'>
            <h2 className='h2_order_form'>Error!</h2>
            <p className='text_size mr_content'>
              Error! Unfortunately there seems to have been an issue with your form submission.
              Please try again or email{' '}
              <a className='link__order_form' href='mailto: support@as-dsa.com'>
                support@as-dsa.com
              </a>{' '}
              <br />
            </p>
          </div>
        </div>
      </div>
    )
  }

  if (isSend) {
    return (
      <div className='container'>
        <div className='content_wrapper'>
          <h1 className='mr_head'>Welcome to Technical Support</h1>
          <div className='mr_content order_success'>
            <h2 className='h2_order_form'>Thank you!</h2>
            <p className='text_size mr_content'>
              Your form has been successfully submitted. We will respond within 1 working day of
              receiving your email. Please note response times may be slightly longer after Bank
              holidays.
              <br />
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='container'>
      <div className='content_wrapper '>
        <div className='tech_support'>
          <h1 className='mr_head'>Welcome to Technical Support</h1>
          <h2> What can we help you with?</h2>
        </div>
        <div className='form_wrapp'>
          {!isLoading ? (
            <form ref={form} className='order_form' onSubmit={sendSubmit}>
              <div>
                <div className='label_block '>
                  <label className='inp_label' htmlFor='firtName'>
                    First Name
                  </label>
                  {infoDirty.firstName && infoError.firstName && (
                    <div style={{ color: 'red' }}>{infoError.firstName}</div>
                  )}
                </div>
                <input
                  onBlur={blurHandler}
                  value={info.firstName}
                  onChange={infoInputHandler}
                  type='text'
                  className='input_small'
                  name='firstName'
                  placeholder='First Name'
                />
              </div>
              <div>
                <div className='label_block '>
                  <label className='inp_label' htmlFor='lastName'>
                    Last Name
                  </label>
                  {infoDirty.lastName && infoError.lastName && (
                    <div style={{ color: 'red' }}>{infoError.lastName}</div>
                  )}
                </div>
                <input
                  onBlur={blurHandler}
                  value={info.lastName}
                  onChange={infoInputHandler}
                  type='text'
                  className='input_small'
                  name='lastName'
                  placeholder='Last Name'
                />
              </div>
              <div>
                <div className='label_block '>
                  <label className='inp_label' htmlFor='email'>
                    Email
                  </label>
                  {infoDirty.email && infoError.email && (
                    <div style={{ color: 'red' }}>{infoError.email}</div>
                  )}
                </div>
                <input
                  onBlur={blurHandler}
                  value={info.email}
                  onChange={infoEmailHandler}
                  type='text'
                  className='input_small'
                  name='email'
                  placeholder='Email'
                />
              </div>
              <div>
                <div className='label_block '>
                  <label className='inp_label' htmlFor='phone'>
                    Telephone Number
                  </label>
                  {infoDirty.phone && infoError.phone && (
                    <div style={{ color: 'red' }}>{infoError.phone}</div>
                  )}
                </div>
                <input
                  type='text'
                  name='phone'
                  required
                  value={info.phone}
                  maxLength={info.phone[0] === '0' ? 11 : 13}
                  onChange={infoPhoneHandler}
                  onBlur={blurHandler}
                  className='input_small'
                  placeholder='07981234567'
                />
              </div>
              <div className='big mr_content'>
                <div className='label_block '>
                  <label className='inp_label' htmlFor='notes'>
                    Ask your question
                  </label>
                  {infoDirty.notes && infoError.notes && (
                    <div style={{ color: 'red' }}>{infoError.notes}</div>
                  )}
                </div>
                <textarea
                  onBlur={blurHandler}
                  value={info.notes}
                  onChange={infoInputHandler}
                  name='notes'
                  className='input_big'
                  placeholder='Enter your question'
                />
              </div>
              <div
                className={
                  info.firstName.length > 1 &&
                  info.lastName.length > 1 &&
                  info.notes.length > 1 &&
                  info.email.length > 1 &&
                  info.phone.length > 1
                    ? 'submit_btn mr_content btn_bg'
                    : 'submit-disabled mr_content'
                }>
                {info.firstName.length > 1 &&
                info.lastName.length > 1 &&
                info.notes.length > 1 &&
                info.email.length > 1 &&
                info.phone.length > 1 ? (
                  <button type='submit' className='submit'>
                    Submit
                  </button>
                ) : (
                  <button type='submit' className='submit' disabled>
                    Submit
                  </button>
                )}
              </div>
            </form>
          ) : (
            <Loader />
          )}
        </div>
        <div className='tech_contact text_size mr_content'>
          <h1>Technical support:</h1>
          <p className='mr_content'>support@as-dsa.com</p>
          <h1>Telephone:</h1>
          <p className='mr_content'>0203 519 8000</p>
          <p>Select Option 2</p>
        </div>
      </div>
    </div>
  )
}

export default TechnicalSupport

import React from 'react'
import { turning6 } from '../img'

const StudentsWaysToBeatStress = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={turning6} alt='pic' className='article_img' />
        <h2>Students: 10 ways to beat stress</h2>

        <div className='text_size mr_content'>
          Nane Steinhoff
          <br /> The Guardian
          <br /> 6 November 2013
          <br />
          Tags: EDUCATION
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            Young people should have everything to be happy about, but as the generation with the
            least responsibility we actually experience the most stress. A 2013 survey by the
            Nightline Association found that 65% ofstudents feel stressed.
          </p>
          <p className='mr_content'>
            Students juggle part time jobs with university, worry about assignments and stress about
            the future and how to make the next step. Trying to manage all these things at once can
            leave you feeling overwhelmed.
          </p>
          <p className='mr_content'>
            As a student, every spare minute seems to be filled with worrying – you feel like you
            have to achieve something and make plans for your future. Instead of relaxing in the
            holidays, you're planning an internship to add to your CV, or working to earn some
            well-needed extra cash.
          </p>
          <p className='mr_content'>
            If you're not careful, working too hard and worrying too much can lead to "burnout" –
            when everything seems bleak and you have nothing left to give.
          </p>
          <p className='mr_content'>
            It might not seem like it when you're feeling down, but living a more stress free life
            is possible. There are some really easy ways to beat stress effectively. Here are some
            that I have encountered as a student:
          </p>
          <p className='mr_content article-text-bold'>1. A varied and healthy diet</p>
          <p className='mr_content'>
            Eating fresh ingredients and lots of fruit is really important. Juices filled with
            vitamin C, such as orange or grapefruit juice, are said to be good for your immune
            system so can help with stress.
          </p>
          <p className='mr_content'>
            When you're busy and tired it can be tempting just to grab another pizza or ready meal,
            but cooking from scratch can be therapeutic as well as being healthier.
          </p>
          <p className='mr_content article-text-bold'>2. Exercise</p>
          <p className='mr_content'>
            Doing sport at least once a week is the best way to reduce stress. It helps your body
            produce endorphins, which make you feel good. Even daily walks of 30 minutes can help
            reduce stress levels but it's even better to work out intensively. Even if you don't
            feel like it at the time you will feel the benefits afterwards.
          </p>
          <p className='mr_content'>
            Joining a sports club could also help with stress as the regular contact with other
            people should help improve your mood.
          </p>
          <p className='mr_content'>
            And why not try yoga? It's a great way to ease your mind and relax your muscles.
          </p>
          <p className='mr_content article-text-bold'>3. Meditation</p>
          <p className='mr_content'>
            It might sound simple, but sitting quietly for 10 minutes a day can really help with
            stress levels. If you've never tried meditation before, it's worth a go.
          </p>
          <p className='mr_content'>
            Good breathing techniques can put you in a more relaxed state as they send oxygen
            surging through your bloodstream, helping to calm you down and beat the stress.
          </p>
          <p className='mr_content article-text-bold'>4. Take breaks regularly</p>
          <p className='mr_content'>
            Short breaks between working can help you switch off. But longer breaks are important
            too.
          </p>
          <p className='mr_content'>
            How about taking the weekend off to relax? Make time for fun and for yourself even if
            this means that you have to schedule time away from your work. You'll hopefully come
            back to your work feeling fresh.
          </p>
          <p className='mr_content article-text-bold'>5. Get a pet</p>
          <p className='mr_content'>
            It is said that spending time with animals is good for your health. If youpat a dog for
            a couple of minutes, your body releases hormones that make you feel happy and can
            decrease the amount of stress in your system.
          </p>
          <p className='mr_content'>
            Most uni halls won't let you keep an animal though, so spending some time with friends
            or family who have pets is a good option: you get the love without the commitment.
          </p>
          <p className='mr_content article-text-bold'>6. Sleep (and sign off Facebook)</p>
          <p className='mr_content'>
            Sleep is always the best medicine and some people find that small 20-minute naps can
            help increase productivity.
          </p>
          <p className='mr_content'>
            As students we tend to spend too much time on social media sites and answering emails,
            texts and phone calls. Sociability is fun – but too much of it, and too much computer
            time, can lead to more stress.
          </p>
          <p className='mr_content'>
            Failing to switch off from work because of your electronic gadgets will only make you
            even more stressed.
          </p>
          <p className='mr_content article-text-bold'>7. Quit smoking</p>
          <p className='mr_content'>
            Some people say they smoke to relax, but researchers on the European Board for Research
            on Nicotine and Tobacco suggest that nicotine suppresses the hormone serotonin, which
            fights stress. Another good reason to quit.
          </p>
          <p className='mr_content article-text-bold'>8. Try to see the positive side</p>
          <p className='mr_content'>
            If you missed a deadline, try to appreciate what you learned from this mistake: now you
            know how to plan ahead. Things might seem bad, but if you try, there is usually
            something positive to be learned.
          </p>
          <p className='mr_content article-text-bold'>9. Listen to music</p>
          <p className='mr_content'>
            Listening to music can help calm you down and put you in a better frame of mind. If
            you're feeling stressed, putting on some calming music while you work could really help.
          </p>
          <p className='mr_content article-text-bold'>10. Laugh</p>
          <p className='mr_content'>
            They say that laughter is the best medicine, and it's really true. Laughing out loud
            increases oxygen and blood flow which automatically reduces stress.
          </p>
          <p className='mr_content'>
            Not taking life too seriously can help everyone live a better and easier life. Make time
            for yourself, log out of Twitter and take breaks. It's about time that we students
            accept that we can achieve just as much in life without all the stress.
          </p>
        </div>
      </div>
    </div>
  )
}

export default StudentsWaysToBeatStress

import React from 'react'
import './slider.css'
import num1 from '../../../assets/img/num1.svg'
import num2 from '../../../assets/img/num2.svg'
import num3 from '../../../assets/img/num3.svg'
import num4 from '../../../assets/img/num4.svg'
const Slider = () => {
  return (
    <>
      <div className='scrolling-wrapper mr_content'>
        <div className='card order_place_bg'>
          <h2 className='mr_content h2_order'>Place order</h2>
          <p>
            Submit your details via our online form. We will respond to you within one working day.
          </p>
          <img src={num1} alt='1' className='num' />
        </div>
        <div className='card upgrade_bg'>
          <h2 className='mr_content h2_order'>Upgrade</h2>
          <p>
            About 50% of students chose to pay extra to upgrade their DSA machine. We will send you
            some options and our friendly team will talk you through computer specifications.
          </p>
          <img src={num2} alt='1' className='num' />
        </div>
        <div className='card delivery_bg'>
          <h2 className='mr_content h2_order'>Book delivery</h2>
          <p>
            Your order will be delivered within 10 working days. Use our App or log into your
            account to book a convenient delivery date.
          </p>
          <img src={num3} alt='1' className='num' />
        </div>
        <div className='card feedback_bg'>
          <h2 className='mr_content h2_order'>Feedback</h2>
          <p className='mr_content '>We welcome student and stakeholder feedback. </p>
          <img src={num4} alt='1' className='num' />
        </div>
      </div>
    </>
  )
}

export default Slider

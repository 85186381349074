import React from 'react'
import { essay } from '../img'
import Calc from '../../../components/Calculator/Calc'

const EssayWriting = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={essay} alt='pic' className='article_img' />
        <h2>Essay Writing</h2>
        <p className='text_size mr_content'>Tags: SPLD / DYSLEXIA</p>
        <div className='text_size'>
          <p className='mr_content'>Breakdown your Essay into Sections before you start Writing</p>
          <p className='mr_content'>
            The best way to approach writing your essay is to first breakdown your required word
            count into sections i.e. paragraphs. The calculator below will help you do this by
            generating an approximate break down of paragraphs for you.
          </p>
          <p className='mr_content'>Using the Word Breakdown Calculator</p>
          <p className='mr_content'>
            If you plan your essay so each paragraph is a separate topic/argument which links to the
            next topic/argument in the following paragraph, you are on the right track. Get into the
            habit of approaching your essays in this way and you will a) save yourself time when
            writing and b) keep your answer on course. Moreover, breaking down your workload into
            manageable chunks, makes essays much simpler to write. For example, instead of being
            faced with a 2000 word essay, you can write one 240 word paragraph at a time which
            should then link to the next paragraph if possible.
          </p>
          <p className='mr_content'>
            <Calc />
          </p>
          <p className='mr_content'>
            Excerpt from{' '}
            <a
              href='http://www.studiware.com/succeed/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Succeed Study DVD
            </a>{' '}
            to purchase for £35 please email{' '}
            <a href='mailto: info@as-ds' className='article-link'>
              orders@as-dsa.com
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default EssayWriting

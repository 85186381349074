import React from 'react'
import {
  gif_1,
  gif_10,
  gif_11,
  gif_12,
  gif_13,
  gif_14,
  gif_15,
  gif_16,
  gif_17,
  gif_18,
  gif_19,
  gif_2,
  gif_20,
  gif_21,
  gif_22,
  gif_23,
  gif_24,
  gif_3,
  gif_4,
  gif_5,
  gif_6,
  gif_7,
  gif_8,
  gif_9,
  pic_39
} from '../img'

const InvalueableSkills = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={pic_39} alt='pic' className='article_img' />
        <h2>24 Invaluable Skills To Learn For Free Online This Year</h2>

        <div className='text_size mr_content'>
          Summer Anne Burton
          <br /> BuzzFeed
          <br /> 3 January 2014
          <br />
          Tags: PROCRASTINATION
        </div>
        <div className='text_size'>
          <p className='mr_content'>1. Become awesome at Excel.</p>
          <img src={gif_1} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            Chandoo is one of many gracious Excel experts who wants to share their knowledge with
            the world. Excel excellence is one of those skills that will improve your chances of
            getting a good job instantly, and it will continue to prove invaluable over the course
            of your career. What are you waiting for?
          </p>
          <img src={gif_2} alt='pic' className='article_img_in' />
          <p className='mr_content'>2. Learn how to code.</p>
          <p className='mr_content'>
            Perhaps no other skill you can learn for free online has as much potential to lead to a
            lucrative career. Want to build a site for your startup? Want to build the next big app?
            Want to get hired at a place like BuzzFeed? You should learn to code. There are a lot of
            places that offer free or cheap online coding tutorials, but I recommend{' '}
            <a
              href='https://www.codecademy.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Code Academy
            </a>{' '}
            for their breadth and innovative program. If you want to try a more traditional route,
            Harvard offers its{' '}
            <a
              href='https://www.edx.org/course/introduction-computer-science-harvardx-cs50x'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Introduction to Computer Science course online for free.
            </a>{' '}
          </p>
          <p className='mr_content'>3. Make a dynamic website.</p>
          <img src={gif_3} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            You could use a pre-existing template or blogging service, or you could learn Ruby on
            Rails and probably change your life forever. Here’s an{' '}
            <a
              href='https://iwanttolearnruby.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              extremely helpful long list of free Ruby learning tools
            </a>{' '}
            that includes everything from{' '}
            <a
              href='https://www.pluralsight.com/courses/code-school-rails-for-zombies'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Rails for Zombies
            </a>{' '}
            to{' '}
            <a
              href='https://learncodethehardway.org/ruby/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Learn Ruby The Hard Way.
            </a>{' '}
            Go! Ruby! Some basic programming experience, like one of the courses above, might be
            helpful (but not necessarily required if you’re patient with yourself).
          </p>
          <p className='mr_content'>4. Learn to make a mobile game.</p>
          <img src={gif_4} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            If you’re not interested in coding anything other than fun game apps, you could try{' '}
            <a
              href='https://www.futurelearn.com/courses/begin-programming'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              this course from the University of Reading.
            </a>{' '}
            It promises to teach you how to build a game in Java, even if you don’t have programming
            experience! If you want to make a truly great game, you might want to{' '}
            <a
              href='http://www.ureddit.com/class/69283/game-theory'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              read/listen up on Game Theory
            </a>{' '}
            first.
          </p>
          <p className='mr_content'>5. Start reading faster.</p>
          <img src={gif_5} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            <a
              href='https://www.spreeder.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Spreeder
            </a>{' '}
            is a free online program that will improve your reading skill and comprehension no
            matter how old you are. With enough practice, you could learn to double, triple, or even
            quadruple the speed at which you read passages currently, which is basically like adding
            years to your life.
          </p>
          <p className='mr_content'>6. Learn a language!</p>
          <img src={gif_6} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            With{' '}
            <a
              href='https://www.duolingo.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Duolingo
            </a>{' '}
            you can learn Spanish, French, Portuguese, Italian, or English (from any of the above or
            more). There’s a mobile app and a website, and the extensive courses are completely
            free.
          </p>
          <p className='mr_content'>7. Pickle your own vegetables.</p>
          <img src={gif_7} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            Tired of your farmer’s market haul going bad before you use it all? Or do you just love
            tangy pickled veggies? You too can{' '}
            <a
              href='http://www.skillshare.com/classes/food-and-drink/Brine-Time-Pickle-like-a-Pro/700467999'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              pickle like a pro
            </a>{' '}
            thanks to SkillShare and Travis Grillo.
          </p>
          <p className='mr_content'>8. Improve your public speaking skills.</p>
          <img src={gif_8} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            You can take the{' '}
            <a
              href='https://www.edx.org/course/introduction-to-public-speaking-2'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              University of Washington’s Intro to Public Speaking
            </a>{' '}
            for free online. Once you learn a few tricks of the trade, you’ll be able to go into
            situations like being asked to present at a company meeting or giving a presentation in
            class without nearly as much fear and loathing.
          </p>
          <p className='mr_content'>9. Get a basic handle of statistics.</p>
          <img src={gif_9} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            <a
              href='https://itunes.apple.com/itunes-u/statistics-2-001-fall-2009/id354822870?mt=10#ls=1'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              UC Berkeley put a stats intro class on iTunes.
            </a>{' '}
            Once you know how to understand the numbers yourself, you’ll never read a biased “news”
            article the same way again — 100% of authors of this post agree!
          </p>
          <p className='mr_content'>10. Understand basic psychology.</p>
          <img src={gif_10} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            Knowing the basics of psych will bring context to your understanding of yourself, the
            dynamics of your family and friendships, what’s really going on with your coworkers, and
            the woes and wonders of society in general.{' '}
            <a
              href='https://academicearth.org/online-college-courses/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Yale University has its Intro to Psychology lectures
            </a>{' '}
            online for free.
          </p>
          <p className='mr_content'>11. Make your own music.</p>
          <img src={gif_11} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            Step one: Learn how to play guitar:{' '}
            <a
              href='https://www.justinguitar.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Justin Guitar
            </a>{' '}
            is a fine and free place to start learning chords and the basic skills you’ll need to be
            able to play guitar — from there, it’s up to you, but once you know the basics, just
            looking up tabs for your favorite songs and learning them on your own is how many young
            guitar players get their start (plus it’s an excellent party trick).
          </p>
          <p className='mr_content'>
            Step two: A delightful{' '}
            <a
              href='https://welcome.online.berklee.edu/berklee-voice.html'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              free voice lesson
            </a>{' '}
            from Berklee College Of Music.
          </p>
          <p className='mr_content'>
            Step three: Have you always thought you had an inner TSwift? Berklee College of Music
            offers an{' '}
            <a
              href='https://www.coursera.org/learn/songwriting'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Introduction to Songwriting course
            </a>{' '}
            completely for free online. The course is six weeks long, and by the end of the lesson
            you’ll have at least one completed song.
          </p>
          <p className='mr_content'>
            Step four: Lifehacker’s{' '}
            <a
              href='https://lifehacker.com/the-basics-of-music-production-the-complete-guide-514079132'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              bascis of music production
            </a>{' '}
            will help you put it all together once you have the skills down! You’ll be recording
            your own music, ready to share with your valentine or the entire world, in no time!
          </p>
          <p className='mr_content'>12. Learn to negotiate.</p>
          <img src={gif_12} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            Let{' '}
            <a
              href='https://academicearth.org/online-college-courses/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Stanford’s Stan Christensen
            </a>{' '}
            explain how to negotiate in business and your personal life, managing relationships for
            your personal gain and not letting yourself be steamrolled. There are a lot of football
            metaphors and it’s great.
          </p>
          <p className='mr_content'>13. Stop hating math.</p>
          <img src={gif_13} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            If you struggled with math throughout school and now have trouble applying it in
            real-world situations when it crops up, try{' '}
            <a
              href='https://www.saylor.org/2013/01/real-world-math-area-of-study/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Saylor.org’s Real World Math course.
            </a>{' '}
            It will reteach you basic math skills as they apply IRL. Very helpful!
          </p>
          <p className='mr_content'>14. Start drawing!</p>
          <img src={gif_14} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            All kids draw — so why do we become so afraid of it as adults? Everyone should feel
            comfortable with a sketchbook and pencil, and sketching is a wonderful way to express
            your creativity. DrawSpace is a great place to start. (I also highly recommend the book
            Drawing on the Right Side of the Brain if you can drop a few dollars for a used copy.)
          </p>
          <p className='mr_content'>15. Make your own animated GIF.</p>
          <img src={gif_15} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            BuzzFeed’s own Katie Notopoulos has{' '}
            <a
              href='http://www.buzzfeed.com/katienotopoulos/how-to-make-a-gif-without-photoshop'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              a great, simple guide to making an animated GIF without Photoshop.
            </a>{' '}
            This is all you need to be the king or queen of Tumblr or your favorite email chains.
          </p>
          <p className='mr_content'>16. Appreciate jazz.</p>
          <img src={gif_16} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            Have you never really “gotten” jazz? If you want to be able to participate in
            conversations at fancy parties and/or just add some context to your appreciation of all
            music, try{' '}
            <a
              href='https://www.edx.org/course/jazz-appreciation-3'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              this free online course from UT Austin
            </a>{' '}
          </p>
          <p className='mr_content'>17. Write well.</p>
          <img src={gif_17} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            <a
              href='http://www.macalester.edu/academics/write-well/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Macalester College’s
            </a>{' '}
            lecture series is excellent. If you’re more interested in journalism, try{' '}
            <a
              href='https://en.wikiversity.org/wiki/Journalism'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Wikiversity’s course selection.
            </a>{' '}
          </p>
          <p className='mr_content'>18. Get better at using Photoshop.</p>
          <img src={gif_18} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            Another invaluable skill that will get you places in your career, learning Photoshop can
            be as fun as watching the hilarious videos on{' '}
            <a
              href='https://www.youtube.com/view_play_list?p=D19BCF9D57320E03'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              You Suck At Photoshop
            </a>{' '}
            or as serious as this extensive{' '}
            <a
              href='https://www.udemy.com/topic/photoshop/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Udemy training course
            </a>{' '}
            (focused on photo retouching).
          </p>
          <p className='mr_content'>19. Take decent pictures.</p>
          <img src={gif_19} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            <a
              href='https://lifehacker.com/basics-of-photography-the-complete-guide-5815742'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Lifehacker’s basics of photography
            </a>{' '}
            might be a good place to start. Learn how your camera works, the basic of composition,
            and editing images in post-production. If you finish that and you’re not sure what to do
            next, here’s a short course on{' '}
            <a
              href='https://www.shortcourses.com/display/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              displaying and sharing your digital photographs.
            </a>{' '}
          </p>
          <p className='mr_content'>20. Learn to knit.</p>
          <img src={gif_20} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            <a
              href='https://www.instructables.com/Knitting-Instructions-Learn-to-Knit/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Instructables has a great course
            </a>{' '}
            by a woman who is herself an online-taught knitter. You’ll be making baby hats and cute
            scarves before this winter’s over!
          </p>
          <p className='mr_content'>21. Get started with investing in stocks.</p>
          <img src={gif_21} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            If you are lucky enough to have a regular income, you should start learning about
            savings and investment now. Investopedia has a ton of online resources, including this{' '}
            <a
              href='https://www.instructables.com/Knitting-Instructions-Learn-to-Knit/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              free stock basics
            </a>{' '}
            course. Invest away!
          </p>
          <p className='mr_content'>22. Clean your house in a short amount of time.</p>
          <img src={gif_22} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            <a
              href='https://unfuckyourhabitat.tumblr.com/post/42115227575/emergency-cleaning-unfuck-your-whole-house-in-the'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Unf$#k Your Habitat has a great emergency cleaning guide
            </a>{' '}
            for when your mother-in-law springs a surprise visit on you. While you’re over there,
            the{' '}
            <a
              href='https://unfuckyourhabitat.tumblr.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              entire blog
            </a>{' '}
            is good for getting organized and clean in the long term, not just in “emergencies.”
            You’ll be happier for it.
          </p>
          <p className='mr_content'>23. Start practicing yoga.</p>
          <img src={gif_23} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            Most cities have free community classes (try just searching Google or inquiring at your
            local yoga studio), or if you’re more comfortable trying yoga at home,{' '}
            <a
              href='https://www.glo.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              YogaGlo
            </a>{' '}
            has a great 15-day trial and{' '}
            <a
              href='https://yogameditationhome.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Yome
            </a>{' '}
            is a compendium of 100% free yoga videos. If you’re already familiar with basic yoga
            positions but you need an easy way to practice at home, I recommend{' '}
            <a
              href='http://www.yogatailor.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              YogaTailor’s
            </a>{' '}
            free trial as well.
          </p>
          <p className='mr_content'>24. Tie your shoelaces more efficiently.</p>
          <img src={gif_24} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            <a
              href='https://www.fieggen.com/shoelace/ianknot.htm'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              It’s simple
            </a>{' '}
            and just imagine the minutes of your life you’ll save!
          </p>
        </div>
      </div>
    </div>
  )
}

export default InvalueableSkills

import React from 'react'
import { pic_3 } from '../img'

const AutismComputer = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
      <img src={pic_3} alt='pic' className='article_img' />
        <h2>Autism: How Computers Can Help.</h2>
        <div className='text_size mr_content'>
          Giulia Rhodes
          <br />
          The Guardian
          <br />
          Sunday 26 February 2012 20.00 GMT
          <br />
          Tags: ASD
          <p className='text_size '>
            Click{' '}
            <a
              href='https://www.theguardian.com/lifeandstyle/2012/feb/26/computer-geeks-autism'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            In 2001, the technology magazine Wired coined the phrase "geek syndrome" to describe the
            threefold increase in autism diagnoses in California's Silicon Valley over the space of
            a decade.
          </p>
          <p className='mr_content'>
            The rumour that Bill Gates himself, founder of Microsoft and figurehead of the world IT
            industry, displays the traits of Asperger's syndrome, the high-functioning form of
            autism, spread like wildfire, across – appropriately – the internet.
          </p>
          <p className='mr_content'>
            More than a decade later Cambridge University's Autism Research Centreis now running a
            study investigating the previously established link between parents working in hi-tech,
            scientific and mathematical industries and an increased incidence of children on the
            autism spectrum. The National Autistic Society reports in its latest member's magazine
            that the number of software packages and apps designed specifically for people with
            autism is rocketing. IT companies in the UK and beyond are actively recruiting an
            autistic workforce for its highly technical and concentration skills.
          </p>
          <p className='mr_content'>
            The relationship between computers and autism is undisputed – and double-edged. Many
            autism experts agree with Temple Grandin, an author and professor at Colorado State
            University, herself autistic, who believes that without "the gifts of autism" there
            would probably be no Nasa or IT industry. Yet the high-profile cases of Gary McKinnon
            and Ryan Cleary, both of whom have Asperger's syndrome, are just two examples of how
            that relationship can go wrong.
          </p>
          <p className='mr_content'>
            Last November a conference organised by Research Autism considered this apparent
            contradiction, asking are computers a blessing or a curse for people with autism?
            Richard Mills, director of research at the charity and chair of the conference, believes
            the answer is complicated: "The computer age totally changes the world of autism. Things
            are instant, and they are unregulated. We see tremendous advantages to this if it is
            properly managed – and huge pitfalls if it isn't."
          </p>
          <p className='mr_content'>
            The potential of computers to help a group that struggles to communicate and form
            relationships in real life is obvious. Professor Simon Baron Cohen, Director of the
            Autism Research Centre believes they outweigh the possible risks: "We can use computers
            to teach emotion recognition and to simplify communication by stripping out facial and
            vocal emotional expressions and slowing it down using email instead of face-to-face
            real-time modes."
          </p>
          <p className='mr_content'>
            Research at Nottingham University and Carnegie Mellon University in Pittsburgh has found
            that people with autism value the increased control over their interactions that is
            afforded by the filter of a computer screen. They can observe interactions, choose when
            to be sociable and make contact with other people who have autism.
          </p>
          <p className='mr_content'>
            Presenting information visually in the precise and predictable computer format suits the
            autistic mind, says Baron Cohen, and can provide "a tool or platform for developing
            further skills".
          </p>
          <p className='mr_content'>
            He also identifies the role of computers in making geeks fashionable: "The new
            technology is chic, so people who are talented at using technology acquire a certain
            kudos, thereby further reducing any stigma that is often associated with disability."
          </p>
          <p className='mr_content'>
            One risk though is that the computer can itself become an obsession which, in extreme
            cases, leads the user into serious problems. The reports of Essex teenager Ryan Cleary,
            charged with a cyber-attack on the Serious Organised Crime Agency, leaving his computer
            only to use the bathroom, may be extreme but they are far from unique, says Mills: "We
            do need to think about the tendency in autism to become fixated on narrow activities.
            They may have the skills to use computers but not to know when to stop."
          </p>
          <p className='mr_content'>
            In March it will be 10 years since Gary McKinnon's arrest for allegedly hacking into a
            number of US military computers. High court judges last month set a July deadline for
            the home secretary to decide whether McKinnon will be extradited to face trial and a
            possible 60-year sentence. His mother, Janis Sharpe, is well aware of the dichotomy of
            computer use and autism. "When Gary was nine, we bought a primitive Atari," she says.
            "He would beg me not to send him out to play so he could use it. We wanted him to mix
            more but we didn't want to deny him the information, pleasure and security computers
            gave him. They were an outlet for him to be himself, and that boosted his self-esteem."
            She recalls accompanying her by-then-adult son to a Christmas party at the family home
            of a girlfriend. "Gary got his computer out. I told him he couldn't use it at a party
            but he couldn't understand."
          </p>
          <p className='mr_content'>
            The relationship foundered, and McKinnon retreated further into his virtual world.
            "People with autism need space, and computers can offer that," says Sharpe. "But we have
            to make sure they don't take over and make other relationships, already difficult for
            people with autism, even harder."
          </p>
          <p className='mr_content'>
            She advises parents to keep computers in communal spaces, limit their use and to help
            children learn to question what they read, guidelines which Mills supports. "This
            virtual world has to help people access the real world, not isolate them further. They
            must control it, not be controlled by it," he says. "We have to reinforce the
            positives."
          </p>
          <p className='mr_content'>
            For further information visit{' '}
            <a
              href='https://researchautism.org/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              researchautism.net
            </a>{' '}
            and{' '}
            <a
              href='https://www.autism.org.uk'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              autism.org.uk
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default AutismComputer

import React, { Suspense } from 'react'
import './index.css'
import App from './App'
import {  BrowserRouter, HashRouter } from 'react-router-dom'
import Loader from './components/loader/Loader'
import { Provider } from 'react-redux'
import store from './redux/store'
import { createRoot } from 'react-dom/client'
import ScrollToTop from './components/scroll-top/ScrollTop'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    </BrowserRouter>
  </Provider>
)

import React from 'react'
import { pic_8 } from '../img'

const JoshuFoer = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={pic_8} alt='pic' className='article_img' />
        <h2>Joshua Foer: Feats of memory anyone can do</h2>
        <p className='text_size mr_content'>Tags: SPLD / DYSLEXIA</p>
        <div className='text_size'>
          <p className='mr_content'>
            There are people who can quickly memorize lists of thousands of numbers, the order of
            all the cards in a deck (or ten!), and much more. Science writer Joshua Foer describes
            the technique – called the memory palace – and shows off its most remarkable feature:
            anyone can learn how to use it, including him. Joshua Foer is a science writer who
            'accidentally' won the U.S. Memory Championship.
          </p>
        </div>
      </div>
    </div>
  )
}

export default JoshuFoer

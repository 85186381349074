import React from 'react'
import { pic_20 } from '../img'

const HowToGetWholeLot = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={pic_20} alt='pic' className='article_img' />
        <h2>How To Get A Whole Lot Of Sh*t Done In A Day</h2>

        <div className='text_size mr_content'>
          Zoe Kors
          <br /> MindBodyGreen
          <br /> October 2011
          <br />
          7 January 2014
          <br />
          Tags: PROCRASTINATION
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            I am a multi-tasker. Lately, we hear about how unhealthy it is to be chronically
            managing numerous—and often conflicting—demands on our attention. But with two careers,
            two children, two ex-husbands, two aging parents, a household to run and a large circle
            of friends, for me, multi-tasking is a necessary way of life. My friends often look at
            what I manage to accomplish and say, "I don't know how you do it all."
            <br /> Well…here's how:
          </p>
          <p className='mr_content article-text-bold'>
            1. Prioritize: Establish a hierarchy of needs.
          </p>
          <p className='mr_content'>
            We tend to heap everything we need to do into one big pile of tasks. Finding time to “do
            it all” may actually require redefining “all.” It's important to identify a hierarchy of
            needs. What is truly essential? Be radically truthful. Unless it supports life itself,
            chances are it's optional. Holding this perspective allows us to discern what comes
            first and what can wait, or even get bumped from the to-do list all together.
          </p>
          <p className='mr_content article-text-bold'>
            2. Compartmentalize: Be present in the moment.
          </p>
          <p className='mr_content'>
            Divide up your time and be fully present to the task at hand. This is a muscle that can
            be developed. When you're working, really focus on work. When you're parenting, be
            wholly present to your kids. I often imagine that I have various hats that I put on and
            take off.
          </p>
          <p className='mr_content'>
            I don't allow myself to go to the park with my son when I'm wearing my “writing fedora.”
            Nor do I wear my “mommy cowboy hat” when I take a meeting. When you finally carve time
            for yourself, do it without guilt, even if you choose to do nothing at all. When we let
            ourselves off the hook for what we're not getting done, it frees us up to celebrate what
            we are fully showing up for in each moment.
          </p>
          <p className='mr_content article-text-bold'>3. Time-out: Take a moment of stillness.</p>
          <p className='mr_content'>
            There are often moments during the day when I feel like my head might explode with the
            number of things I'm trying to handle at once. I can usually tell when it's reached a
            critical point, because I lose the ability to finish a thought or form a complete
            sentence. Taking a self-imposed time-out is the perfect medicine for this.
          </p>
          <p className='mr_content'>
            Step away from all electronic devices and sit for five minutes in silence. Sit
            comfortably with your eyes closed and slow your{' '}
            <a
              href='https://www.mindbodygreen.com/tag/breathing'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              breath.
            </a>{' '}
            Pause at the top of each inhalation and at the bottom of each exhalation, creating a
            moment of stillness. Starting you day with this practice sets a baseline for inner peace
            which you can return to throughout the day.
          </p>
          <p className='mr_content article-text-bold'>4. Support: Ask for help.</p>
          <p className='mr_content'>
            Asking for help can make us feel{' '}
            <a
              href='https://www.mindbodygreen.com/tag/vulnerability'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              vulnerable
            </a>{' '}
            , and so we often bobble the request. And if you're like me, the best way to get things
            done is to do them yourself. The flipside of my “Superwoman” card reads “Supermartyr.”
            In order to feel supported, we need to relinquish control.
          </p>
          <p className='mr_content'>
            Get really clear about what it would mean to feel supported. How would that manifest?
            Help people help you by asking for specific things. Sometimes delegating one small task
            creates an exponential amount of space for another. Consider asking for help a daily
            practice: "What can I ask someone to do for me today?"
          </p>
          <p className='mr_content article-text-bold'>
            5. Self-Care: Put your own oxygen mask on first.
          </p>
          <p className='mr_content'>
            Your own needs rank high on the hierarchy of needs. Do not pretend otherwise or they
            will come out sideways. If you are a multi-tasker, your energy output is enormous. You
            must find ways to fill up your own tank. This is your responsibility; no one will do it
            for you.
          </p>
          <p className='mr_content'>
            https://www.mindbodygreen.com/articles/self-care-essentials-to-add-to-your-life Make
            time for{' '}
            <a
              href='https://www.mindbodygreen.com/articles/self-care-essentials-to-add-to-your-life'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              self-care
            </a>{' '}
            — a warm bath at the end of the day, a yoga class, dinner with friends—only you know
            what you need to feel nurtured. Make it happen so you can continue to be of service to
            others.
          </p>
          <p className='mr_content'></p>
          <p className='mr_content'></p>
          <p className='mr_content'></p>
          <p className='mr_content'></p>
          <p className='mr_content'></p>
          <p className='mr_content'></p>
          <p className='mr_content'></p>
          <p className='mr_content'></p>
          <p className='mr_content'></p>
          <p className='mr_content'></p>
          <p className='mr_content'></p>
        </div>
      </div>
    </div>
  )
}

export default HowToGetWholeLot

import React from 'react'
import './contact.css'
const Contact = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <h1 className='mr_head'>Contact Us</h1>
        <div className='contact_content'>
          <div className='contacts'>
            <h2>Mon - Fri 9 am - 5 pm</h2>
            <div className='contact_block text_size'>
              <h2>DSA</h2>
              <p className='text_contact article-text-bold'>Student enquiries:</p>
              <p className='text_contact'>
                <a href='mailto: info@as-ds' className='link_contact'>
                  orders@as-dsa.com
                </a>
              </p>
              <p className='article-text-bold'>Technical support:</p>
              <p className='text_contact'>
                <a href='mailto: support@as-dsa.com' className='link_contact'>
                  support@as-dsa.com
                </a>
              </p>
              <p className='article-text-bold'>Assessor enquiries:</p>
              <p className='text_contact'>
                <a href='mailto: quotes@as-dsa.com' className='link_contact'>
                  quotes@as-dsa.com
                </a>
              </p>
            </div>
            <div className='contact_block text_size'>
              <h2>CORPORATE SERVICES</h2>
              <p className='text_contact'>
                <a href='mailto: solutions@as-dsa.com' className='link_contact'>
                  solutions@as-dsa.com
                </a>
              </p>
            </div>

            <div className='contact_block text_size'>
              <h2>GENERAL ENQUIRIES:</h2>
              <p className='text_contact'>
                <a href='mailto: info@as-dsa.com' className='link_contact'>
                  info@as-dsa.com
                </a>
                <p className='mr_content'>TEL: 0203 519 8000</p>
                <p className='mr_content'>1A DUNN STREET, LONDON, E8 2DG</p>
              </p>
            </div>
          </div>
          <div className='map'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.9131843158234!2d-0.07546408419222475!3d51.55149091529946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761c8c8129c065%3A0x5b50f9275e74c0b7!2s1a%20Dunn%20St%2C%20London%20E8%202DG%2C%20UK!5e0!3m2!1sen!2skg!4v1652878867045!5m2!1sen!2skg'
              style={{ border: 0 }}
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
              className='map_fram'></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact

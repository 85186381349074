import React from 'react'

const WhatMakeUsDifferent = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <h1 className='mr_head'>What makes us different?</h1>
        <div className=' text_size '>
          <h2 className='content_wrapper_height'>A Focus on People </h2>
          <p className='mr_content'>
            Within the boundaries of effective pricing and service level agreements, we are focused
            on assisting individuals. We understand that while equipment can be the same, each
            person's requirements are unique. Customers benefit from our friendly and efficient
            order process, including online ordering, and innovate Training techniques to enhance
            the way they produce work and study.
          </p>
          <h2 className='content_wrapper_height'>A Focus on Learning </h2>
          <p className='mr_content'>
            We specialise in facilitating the effective use of assistive equipment. Using techniques
            that put customers in control, we adapt Training to the learning style of the
            individual. The results for customers are new and effective ways of working.
          </p>
          <h2 className='content_wrapper_height'>A Fresh Approach </h2>
          <p className='mr_content'>
            Working with thousands of people in their homes, offices and universities provides us
            with a deep understanding of our customers' requirements. Technical support engineers
            and 1-1 Tutors are integral to our One-Stop-Shop service; and continual feedback on how
            software, hardware and working strategies are consumed ‘on the ground’ keeps us
            developing.
          </p>
          <p className='mr_content'>
            We pride ourselves on being experts on how people learn and the science of learning. An
            understanding of how individuals utilise specialist equipment in their own environment
            is complemented by systematic customer feedback which provides data on products and
            services we provide. Objective and subjective data provides an understanding of peoples'
            requirements and allow us to continually improve the way we work.
          </p>
          <h2 className='content_wrapper_height'>Experts in Agile Technologies </h2>
          <p className='mr_content'>
            We work with all common assistive software and hardware from free to paid for solutions.
            We take an active role evaluating new products and finding effective ways of
            implementing technologies to assist individuals. Simple solutions can be hugely
            effective. All technology is strictly speaking 'assistive', thus we are more and more
            drawn to the term "Agile Technologies". Agile Technology alludes to the enhanced working
            practices and flexibility that present-day, often portable, technology can provide.
          </p>
        </div>
      </div>
    </div>
  )
}

export default WhatMakeUsDifferent

import React from 'react'
import { pic_13 } from '../img'

const EleanorLongden = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={pic_13} alt='pic' className='article_img' />
        <h2>Eleanor Longden: The voices in my head</h2>

        <div className='text_size mr_content'>
          Professor Francesca Happé
          <br /> The Royal Society, London 26
          <br /> October 2011
          <br />
          Tags: MENTAL_HEALTH
          <p className='text_size '>
            Click{' '}
            <a
              href='https://royalsociety.org/science-events-and-lectures/2011/autism-spectrum-disorder/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            To all appearances, Eleanor Longden was just like every other student, heading to
            college full of promise and without a care in the world. That was until the voices in
            her head started talking. Initially innocuous, these internal narrators became
            increasingly antagonistic and dictatorial, turning her life into a living nightmare.
            Diagnosed with schizophrenia, hospitalized, drugged, Longden was discarded by a system
            that didn't know how to help her. Longden tells the moving tale of her years-long
            journey back to mental health, and makes the case that it was through learning to listen
            to her voices that she was able to survive.
          </p>
        </div>
      </div>
    </div>
  )
}

export default EleanorLongden

import React from 'react'
import './calc.css'
import left_arrow from '../../assets/img/left_arrow.svg'
import arrow_center from '../../assets/img/arrow_center.svg'
import right_arrow from '../../assets/img/right_arrow.svg'
import small_arrow from '../../assets/img/small_arrow.svg'
import { useState } from 'react'
function calculateDistribution(val) {
    let introShare = 0.1
    let introAdjustment = 0
    let body1Factor = 625
    let body2Factor = 500
    let conclusionShare = 0.1
    let conclusionAdjustment = 0
    let adjustmentInterval = 5000

    let form = document.wordForm
    let totalWords = val

    if (totalWords < 2000) {
        introShare = 2 / 15
        introAdjustment = -5 / 120000
        body1Factor = 375
        body2Factor = 300
        conclusionShare = 0.2
        conclusionAdjustment = -0.00005
        adjustmentInterval = 1500
    } else if (totalWords < 3500) {
        introShare = 9 / 80
        introAdjustment = -1.25 / 150000
        adjustmentInterval = 1500
        body1Factor = 400
        body2Factor = 1000 / 3
        conclusionShare = 0.175
        conclusionAdjustment = -13 / 420000
        adjustmentInterval = 2000
    } else if (totalWords < 5000) {
        introShare = 0.1
        introAdjustment = 0
        body1Factor = 500
        body2Factor = 437.5
        conclusionShare = 9 / 70
        conclusionAdjustment = -13 / 1050000
        adjustmentInterval = 3500
    } else {
        introShare = 0.1
        introAdjustment = 0
        body1Factor = 625
        body2Factor = 500
        conclusionShare = 0.11
        conclusionAdjustment = 0
        adjustmentInterval = 5000
    }

  

    let introCount = roundToFive((introShare + introAdjustment * (totalWords - adjustmentInterval)) * totalWords, 1)
    let conclusionCount = roundToFive(
        (conclusionShare + conclusionAdjustment * (totalWords - adjustmentInterval)) * totalWords,
        1
    )
    let remainingWords = totalWords - introCount - conclusionCount

    let body1Parts = Math.round(totalWords / body1Factor)
    if (body1Parts < 1) {
        body1Parts = 1
    }

    let body2Parts = Math.round(totalWords / body2Factor)
    if (body1Parts >= body2Parts) {
        body2Parts = body1Parts + 1
    }

    if (body1Parts == body2Parts) {
        body2Parts++
    }

    let body1Count = roundToFive(remainingWords / body1Parts, 1)
    let body2Count = roundToFive(remainingWords / body2Parts, 1)


    return [introCount, conclusionCount, body1Count, body1Parts]
}



function roundToFive(input, digits) {
    return Math.round(input / 5) * 5
}



const Calc = () => {
    const [mainValue, setMainValue] = useState(2000)
    const [introCount, conclusionCount, body1Count, body1Parts] = calculateDistribution(mainValue)
    const [introductionValue, setIntroduction_value] = useState(Math.round(introCount))

    const [conclusion, setConclusion] = useState(Math.round(conclusionCount))
    const [boddyEssay, setBoddyEssay] = useState(body1Parts + ' x ' + Math.round(body1Count))
    const changeHandler = (e) => {
        const [introCount, conclusionCount, body1Count, body1Parts] = calculateDistribution(e.target.value)
        setMainValue(e.target.value)
        setIntroduction_value(introCount)
        setConclusion(conclusionCount)
        setBoddyEssay(body1Parts + ' x ' + Math.round(body1Count))
    }

    return (
        <div className='container'>
            <div className='mr_content '>
                <div className='calc_wr'>
                    <div className='main_calc'>
                        <span className='first_arrow'>
                            <img src={left_arrow} alt='pic' className='first_arrow_img' />
                        </span>

                        <div className='calc_block1'>
                            <p className='calc_content_mr'>
                                Enter your <br /> word limit
                            </p>
                            <input onChange={changeHandler} defaultValue={mainValue} />
                        </div>

                        <span className='center_arrow'>
                            <img src={arrow_center} alt='pic' className='center_arrow_img' />
                        </span>
                        <span className='right_arrow'>
                            <img className='right_arrow_img' src={right_arrow} alt='pic' />
                        </span>
                    </div>
                    <span className='introduction_value value_title'>
                        <p>Introduction</p>
                        <p className='value'>{introductionValue}</p>
                    </span>
                    <span className='conclusion_value value_title'>
                        <p>Conclusion</p>
                        <p className='value'>{conclusion}</p>
                    </span>
                    <span className='essay_body_value value_title'>
                        <p>Essay Body</p>
                        <p className='value'>{boddyEssay}</p>
                    </span>
                    <span className='small_arrow'>
                        <img className='small_arrow_img' src={small_arrow} alt='pic' />
                    </span>
                    <span className='paragraph_value value_p_title paragraph_position_1'>
                        <p>Paragraph 1</p>
                        <p className='p_value'>500</p>
                    </span>
                    <span className='small_arrow_2'>
                        <img className='small_arrow_img' src={small_arrow} alt='pic' />
                    </span>
                    <span className='paragraph_value value_p_title paragraph_position_2'>
                        <p>Paragraph 1</p>
                        <p className='p_value'>500</p>
                    </span>
                    <span className='small_arrow_3'>
                        <img className='small_arrow_img' src={small_arrow} alt='pic' />
                    </span>
                    <span className='paragraph_value value_p_title paragraph_position_3'>
                        <p>Paragraph 1</p>
                        <p className='p_value'>500</p>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Calc

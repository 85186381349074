import React from 'react'
import './nmh-rates.css'
import nmh_rates from '../../../assets/img/nmh_rates.svg'
const NmhRates = () => {
  return (
    <div className='container'>
      <div className='content_wrapper text_size'>
        <h1 className='mr_head'>NMH Rates</h1>
        <p className='mr_content'>
          Neutral 1-1 Training Rooms are available to students in Hoxton, East London. <br />
        </p>
        <a
          className='download-nmh'
          href='pdf/Assistive_Solutions_NMH_Rates_2023.pdf'
          target='_blank'>
          <b>Download NMH Rates Document</b>
        </a>
        <div className='nmh_rates_img_wrapp'>
          <img src={nmh_rates} className='nmh_rates_img' alt='nmh_rates' />
        </div>
      </div>
    </div>
  )
}

export default NmhRates

import React from 'react'
import './not-found.css'
import notfound from '../../assets/img/notfound.svg'
const NotFound = () => {
    return (
        <div className='not_found_wr'>
            <div className='container'>
                <div className='content_wrapper cm_wrapp_img'>
                    <img src={notfound} alt='coming_soon' className='coming_soon_img' />
                </div>
            </div>
        </div>
    )
}

export default NotFound

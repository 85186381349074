import React, { useState } from 'react'
import TagStudents from '../Tag/Tag'
import './for-students-resources.css'
import { ALL, studentsResources } from '../resources-items'

import ArticleCard from '../Article-card/ArticleCard'
const ForStudentResourcesPage = () => {
  const [sortState, setSortState] = useState(studentsResources.ALL)
  const [select, setSelect] = useState('All content')
  const handler = (title, value) => {
    setSelect(title)
    setSortState(value)
  }
  const cardTagHandler = value => {
    setSortState(studentsResources[value])
  }
  return (
    <div className='container'>
      <div className='content_wrapper students_wrapper'>
        <h1 className='mr_head'>Resources</h1>
        <TagStudents handler={handler} select={select} />
        <div className='articles_wrapper'>
          {sortState.map(article => (
            <ArticleCard key={article.title} article={article} cardTagHandler={cardTagHandler} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ForStudentResourcesPage

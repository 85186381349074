import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './mobile-menu.css'

const MobileMenu = ({ setShow, show }) => {
  const [check, setCheck] = useState(false)
  const [checkAssessors, setCheckAssessors] = useState(false)
  const [checkSoftWare, setCheckSoftWare] = useState(false)
  const [checkResource, setCheckResource] = useState(false)
  const [rotate, setRotate] = useState(false)
  const [rotateAssessors, setrotateAssessors] = useState(false)
  const [rotateSoftWare, setRotateSoftWare] = useState(false)
  const [rotateResource, setRotateResource] = useState(false)
  const studentClose = () => {
    setCheck(!check)
    setCheckAssessors(false)
    setCheckSoftWare(false)
    setRotate(!rotate)
    setrotateAssessors(false)
    setRotateSoftWare(false)
    setRotateResource(false)
  }

  const assessorsClose = () => {
    setCheckAssessors(!checkAssessors)
    setCheck(false)
    setCheckSoftWare(false)
    setrotateAssessors(!rotateAssessors)
    setRotate(false)
    setRotateSoftWare(false)
    setRotateResource(false)
  }

  const softWareClose = () => {
    setCheckSoftWare(!checkSoftWare)
    setCheckAssessors(false)
    setCheck(false)
    setRotateSoftWare(!rotateSoftWare)
    setrotateAssessors(false)
    setRotate(false)
    setRotateResource(false)
  }
  const resourceClose = () => {
    setCheckResource(!checkResource)
    setCheckAssessors(false)
    setCheck(false)
    setRotateSoftWare(false)
    setrotateAssessors(false)
    setRotate(false)
    setRotateResource(!rotateResource)
  }
  return (
    <>
      <div className='mobilenav'>
        <div className='close' onClick={() => setShow(!show)}>
          <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M9.00002 31.6274L20.3137 20.3137L31.6274 9'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
            />
            <path
              d='M9.00003 9.00002L20.3137 20.3137L31.6274 31.6274'
              stroke='white'
              strokeWidth='2'
              strokeLinecap='round'
            />
          </svg>
        </div>
        <ul id='accordion'>
          <li onClick={() => setShow(!show)}>
            <Link to='/' className='link_mob'>
              Home
            </Link>
          </li>
          <li onClick={() => setShow(!show)}>
            <Link to='about' className='link_mob'>
              About us
            </Link>
          </li>
          <li>
            <label htmlFor='students' onClick={studentClose}>
              Students<span className={rotate ? 'active' : ''}>&#x3e;</span>
            </label>
            <input type='radio' name='accordion' id='students' checked={check ? 'checked' : ''} />
            <div onClick={() => setShow(!show)} className='content'>
              <Link to='dsa-order-process'>DSA Order Process</Link>
              <Link to='order'>Order Form</Link>
              <Link to='at-tuition'>AT Tuition</Link>
              <Link to='study-skills'>Study Skills</Link>
              <Link to='mentoring'>Mentoring</Link>
              <Link to='feedback'>Feedback</Link>
              <Link to='dsa-insurance'>DSA Insurance</Link>
              <Link to='technical-support'>Technical Support</Link>
            </div>
          </li>
          <div className='border_mob'></div>
          <li>
            <label htmlFor='second' onClick={assessorsClose}>
              Assessors<span className={rotateAssessors ? 'active' : ''}>&#x3e;</span>
            </label>
            <input
              type='radio'
              name='accordion'
              id='second'
              checked={checkAssessors ? 'checked' : ''}
            />
            <div onClick={() => setShow(!show)} className='content'>
              <Link to='wmud'>What makes us different</Link>
              <Link to='nmh-rates'>NMH Rates</Link>
              <Link to='ergonomic-assessments'>Ergonomic Assessments</Link>
            </div>
          </li>
          <div className='border_mob'></div>
          <li>
            <label htmlFor='third' onClick={softWareClose}>
              Software<span className={rotateSoftWare ? 'active' : ''}>&#x3e;</span>
            </label>
            <input
              type='radio'
              name='accordion'
              id='third'
              checked={checkSoftWare ? 'checked' : ''}
            />
            <div onClick={() => setShow(!show)} className='content'>
              <Link to='grammarly'>Grammarly</Link>
              <Link to='scholarcy'>Scholarcy</Link>
              <a href='https://shop.assistiveitsolutions.com/' target='_blank' rel='noreferrer'>
                WordQ
              </a>
            </div>
          </li>
          <div className='border_mob'></div>
          <li onClick={() => setShow(!show)}>
            <Link to='resources' className='link_mob'>
              Resources
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default MobileMenu

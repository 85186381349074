import React from 'react'
import { circle_of_control } from '../img';

const CircleOfControl = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={circle_of_control} alt='pic' className='article_img' />
        <h2>Circle of Control</h2>
        
        {/* <div className='text_size'>
          <p className='mr_content'>
            This talk presents a cognitive neuroscience perspective on what might be some of the
            obstacles standing in the way of major scientific breakthroughs in the science of
            autism. What we do know, and what we need to know, about individuals with autism
            spectrum disorders will be discussed.
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default CircleOfControl

import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import './slider-funding-body.css'

function Modal({ closeModal, item, way }) {
  return (
    <>
      {ReactDOM.createPortal(<Backdrop closeModal={closeModal} />, document.body)}
      {ReactDOM.createPortal(<Overlay closeModal={closeModal} way={way} />, document.body)}
    </>
  )
}

function Backdrop({ closeModal }) {
  return <div className='backdrop' onClick={closeModal}></div>
}

function Overlay({ closeModal, way }) {
  return (
    <div className='overlay'>
      <header className='overlay__header' onClick={closeModal}>
        <img src={'/img/close_modal.svg'} alt='Close' />
      </header>
      <div className='overlay__content'>
        <img src={way} className='overlay_img' alt='SFE' />
      </div>
    </div>
  )
}
const sfe = '/img/SFE.svg'
const sfw = '/img/sfw.svg'
const sfni = '/img/sfni.svg'
const nnsFunding = '/img/nns.svg'
const nnsWales = '/img/nns_wales.svg'
const saas = '/img/swas.svg'

const fundingBodyItems = [
  {
    id: 0,
    h2: 'SFE',
    cardName: 'Student Finance England',
    img: sfe
  },
  {
    id: 1,
    h2: 'SFEW',
    cardName: 'Student Finance Wales',
    img: sfw
  },
  {
    id: 2,
    h2: 'SFNI',
    cardName: 'Student Finance Northern Ireland',
    img: sfni
  },
  {
    id: 3,
    h2: 'NHS',
    cardName: 'NHS Funding',
    img: nnsFunding
  },
  {
    id: 4,
    h2: 'NHS-W',
    cardName: 'NHS-Wales',
    img: nnsWales
  },
  {
    id: 5,
    h2: 'SAAS',
    cardName: 'Student Awards Agency Scotland',
    img: saas
  }
]

const SliderFundingBody = () => {
  const [shouldShowModal, setShouldShowModal] = React.useState(null)
  const theme = useSelector(state => state.theme)
  function openModal(card) {
    setShouldShowModal(card)
  }

  function closeModal() {
    setShouldShowModal(null)
  }

  return (
    <div className='flex-row-container mr_content'>
      {fundingBodyItems.map((el, index) => (
        <Fragment key={index}>
          <div
            className={
              theme === 'dark-light-theme' ? 'flex-row-item dark' : 'flex-row-item not_dark'
            }
            onClick={() => openModal(el.h2)}>
            <h2 className='h2_funding'>{el.cardName}</h2>
            <img src={el.img} alt='Img' className='img-funding-body' />
          </div>
          {shouldShowModal === 'SFE' ? (
            <Modal closeModal={closeModal} way={sfe} />
          ) : shouldShowModal === 'SFEW' ? (
            <Modal closeModal={closeModal} way={sfw} />
          ) : shouldShowModal === 'SFNI' ? (
            <Modal closeModal={closeModal} way={sfni} />
          ) : shouldShowModal === 'NHS' ? (
            <Modal closeModal={closeModal} way={nnsFunding} />
          ) : shouldShowModal === 'NHS-W' ? (
            <Modal closeModal={closeModal} way={nnsWales} />
          ) : shouldShowModal === 'SAAS' ? (
            <Modal closeModal={closeModal} way={saas} />
          ) : null}
        </Fragment>
      ))}
    </div>
  )
}

export default SliderFundingBody

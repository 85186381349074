import React from 'react'
const StudySkills = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <div>
          <h1 className='mr_head'>Study Skills</h1>
          <div className='quote-mr'>
            <div className='quote-wrap'>
              <div className='quote-text'>
                <p>
                  “My tutor is always really helpful and breaks things down into easy to understand
                  points. Her relaxed approach to academic writing/work is helpful in reducing
                  stress and she always has lots of tips!”
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='text_size'>
          <h2 className='content_wrapper_height'>What is Study Skills</h2>
          <p className='mr_content'>
            Study skills sessions are facilitated by a Tutor who will help you to develop techniques
            that can save you time when creating your assignments, improve the quality of the work
            that you produce, and to help your feel more confident academically. Our Study Skills
            Tutors are also here to help you manage your time effectively, which can assist with
            skills such as juggling multiple deadlines at the same time.
          </p>
          <h2 className='content_wrapper_height'>Our Specialist Tutors</h2>
          <p className='mr_content'>
            Our Study Skills Tutors are equipped with generalisable tools and techniques to teach
            you. Whilst they do not assist with subject specific information, they will be
            experienced in assisting those from multiple areas of academia (Maths and Science to The
            Arts and Creative Writing), and can apply the knowledge they have to help you gain the
            skills you need to improve the quality of your work. Your sessions will be personalised
            to you, and our Study Skills Tutors are a helping hand to assist you with other problems
            you may be having (such as anxiety).
          </p>
          <h2 className='content_wrapper_height'>Booking a Session</h2>
          <p className='mr_content'>
            For the most part, our support is conducted online, however, we may be able to
            facilitate face to face sessions depending on where you are in the UK. The beauty of
            remote sessions includes the fact that you can receive your support from a comfortable,
            familiar location such as your home. It also means that we can set up your support
            quickly, (this can be booked for the same week we hear from you). To arrange your
            support, please email us on nmh@as-dsa.com with your DSA2 letter (or equivalent letter
            stating from your finding body that you have been approved for support), or call the
            office on 020 3519 8000.
          </p>
          <div className='contact_us_btn_wr mr_content'>
            <a className='contact_us_button btn_bg' href='mailto: nmh@as-dsa.com'>
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StudySkills

import { IS_LOADING, IS_NOT_LOADING } from '../types/types'
import {initState} from '../initState'

export const loaderReducer = (state = initState, action) => {
    switch (action.type) {
        case IS_LOADING:
            return true
        case IS_NOT_LOADING:
            return false
        default:
            return state
    }
}
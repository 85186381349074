import React from 'react'
import { pic_12 } from '../img'

const SecretOfTheSuper = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
      <img src={pic_12} alt='pic' className='article_img' />
        <h2 >Secret of the super successful ...they’re dyslexic</h2>
        <div className='text_size mr_content'>
          Ben Dowell
          <br />
          Tags: SPLD / Dyslexia
          <p className='text_size '>
            Click{' '}
            <a
              href='https://www.gifteddyslexic.com/Davis-assessment/successful-because-of-dyslexia'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            You don’t have to be dyslexic, but it helps. A study has revealed that millionaires are
            significantly more likely to suffer from the condition than the rest of the population.
            Psychologists who analysed the mental make-up of business winners found learning
            difficulties are one of the most important precursors of financial success. About 40% of
            the 300 studied had been diagnosed with the condition — four times the rate in the
            general population.
          </p>
          <p className='mr_content'>
            Experts believe one reason may be that dyslexics, who tend not to be good at details,
            learn to excel by grasping the bigger picture and producing original ideas. They might
            also be more motivated because of the social exclusion many feel.
          </p>
          <p className='mr_content'>
            The findings, by Tulip Financial Research, show a huge majority of Britain’s estimated
            5,000 self-made millionaires performed badly at school and continue to perform poorly in
            aptitude tests.
          </p>
          <p className='mr_content'>
            Chapter 3 focuses on the analysis of the student's reading. Topics include selecting a
            reading passage, miscue analysis, reading style analysis, comprehension, and single work
            and nonword testing.
          </p>
          <p className='mr_content'>
            “Most people who make a million have difficult childhoods or have been frustrated in a
            major way,” said Adrian Atkinson, a business psychologist who assisted with the tests.
            “Dyslexia is one of the driving forces behind that.”
          </p>
          <p className='mr_content'>
            Sir Richard Branson, head of Virgin, who made his first million by the age of 18 after
            founding a record label, is a classic example of the successful dyslexic. Branson, a
            billionaire, admits he did not understand the difference between net and gross profit
            until it was explained to him three years ago.
          </p>
          <p className='mr_content'>
            “One of the problems about being dyslexic is that you don’t perform well at school and I
            knew I wasn’t going to pass my exams so I did other things,” said Branson. “Being
            dyslexic means I am good at delegation and the bigger picture.”
          </p>
          <p className='mr_content'>
            Lord Heseltine — the former Tory cabinet minister whose wealth was put at £203m in this
            year’s Sunday Times Rich List, largely from his Haymarket publishing empire — has also
            suffered throughout his life from dyslexia.
          </p>
          <p className='mr_content'>
            He recalls his first entrepreneurial steps at Shrewsbury school: “I wasn’t any good at
            games, so when all these very energetic fellows spent the afternoon exhausting
            themselves on the soccer playing fields of Shrewsbury I used to carry lemonade up the
            hill and sell it at a significant mark-up.”
          </p>
          <p className='mr_content'>
            The latest study was commissioned for a BBC2 series, Mind of a Millionaire....Ivan
            Massow, a former Tory party adviser and businessman who made his first million at 21 and
            is dyslexic, said the findings chimed with his experiences.
          </p>
          <p className='mr_content'>
            Massow, who came from a broken home and felt himself shunned at school, insisted his
            traumatic early experiences were beneficial to his career.
          </p>
          <p className='mr_content'>
            Small Business - The mind of an entrepreneur:Small Business - The mind of an
            entrepreneur:
            <br />
            Childhood shapes the future whiz-kids
            <br /> Rachel Bridge discovers the secrets of how to make your first million
          </p>
          <p className='mr_content'>
            They are hugely optimistic, full of energy and extremely exciting to be around. But they
            are also stubborn, single-minded and selfish. And they absolutely hate holidays. Welcome
            to the fascinating world inside the mind of the entrepreneur, where risk is irrelevant,
            speed is vital - and failure is not an option.
          </p>
          <p className='mr_content'>
            In a bid to find out what makes them tick, a team of psychologists and business experts
            spent a day putting a group of entrepreneurs through a series of psychological tests for
            a new BBC2 series, called Mind of a Millionaire.... What they found was rather
            surprising. Adrian Atkinson, business psychologist with the consultancy Human Factor
            International and one of the programme’s experts, says: "Entrepreneurs are different to
            the rest of us. They don’t behave rationally in the way other people do. They’re willing
            to risk everything to start a business. They pursue opportunities without regard for
            resources, preferring to create the opportunity and then find the money later. "They
            believe that everything that happens, whether good or bad, is due to their actions."
          </p>
          <p className='mr_content'>
            The result, he says, is great for the economy, which thrives on constant innovation and
            change, but not so good for dinner-party conversation. "You wouldn’t want to get stuck
            in a lift with an entrepreneur because most of them are not tremendously enjoyable
            companions," he says. "They have this amazing focus and single-mindedness and they don’t
            need relationships with other people. They just talk about business all the time."
          </p>
          <p className='mr_content'>
            Atkinson says entrepreneurs can be divided into three distinct types: Social
            entrepreneurs, such as Paul Harrod, whose company provides employment for the homeless.
            These are driven by the desire to improve society.
          </p>
          <p className='mr_content'>
            Theme entrepreneurs, such as Anita Roddick, who start businesses within a particular
            defined area; and Serial entrepreneurs, such as Richard Branson, who look for
            opportunities to create wealth anywhere and will often set up one company after another
            in quick succession.
          </p>
          <p className='mr_content'>
            All three types are, however, motivated by one of three factors - revenge, status or
            power. And the roots of that stem overwhelmingly from their childhood experiences.
            Atkinson explains: "Revenge entrepreneurs are driven to put right a social injustice to
            their family or to themselves, which they experienced as a child.
          </p>
          <p className='mr_content'>
            All three types are, however, motivated by one of three factors - revenge, status or
            power. And the roots of that stem overwhelmingly from their childhood experiences.
            Atkinson explains: "Revenge entrepreneurs are driven to put right a social injustice to
            their family or to themselves, which they experienced as a child.
          </p>
          <p className='mr_content'>
            "Status entrepreneurs are driven to create a situation where they are highly respected
            by the people they think matter. Something in their childhood has made them feel
            excluded and they are determined to show the world that they fit in. "Power
            entrepreneurs are driven by the desire to show people they can do whatever they want to
            do. What drives all of them is the desire to create wealth to appease their feelings of
            insecurity. It is not about money, it is about providing security from slipping back
            into their previous existence."Rene Carayol, a business adviser and another member of
            the programme’s team of experts, says one of the most fascinating characteristics of
            entrepreneurs is their absolute refusal to acknowledge failure. He says: "They don’t do
            failure, they redefine it. Failure for them is a learning experience that will enable
            them to be even better. If they fall over, they just come straight back up again. I have
            never met such a bunch of optimistic people. Everything is an opportunity, the glass
            isn’t half full, it’s spilling over."
          </p>
          <p className='mr_content'>
            Carayol says there are several things traditional business people can learn from the way
            entrepreneurs work. He says: "Number one, making mistakes is okay. It’s a necessary part
            of learning. Number two, speed is the key competitive advantage when you’re in business
            now - it’s not who does it better, it’s who gets there first. Time used to be the enemy.
            It’s now an assassin. And number three, play to your strengths." Sadly, for the rest of
            us, however, Carayol thinks that ultimately entrepreneurs are born, not made. He says:
            "If you don’t have that drive, that energy, that focus, then you can’t make a chicken
            salad out of chicken s**t, no matter how you try."
          </p>
          <p className='mr_content'>
            Case study:
            <br />
            Alexander Amosu, 28, made his first million composing ring tones for his friends’ mobile
            phones. He began selling them for £1 a time from his bedroom, but within four months he
            had moved into offices and was employing 21 staff. In the first year his company, Rnb
            Wild, had sales of £1.3m. He sold it for several million pounds this year to start up a
            new business selling video downloads for mobile phones.
          </p>
          <p className='mr_content'>
            Amosu, who was brought up in Kilburn, northwest London, by his grandmother, got his
            first taste of being an entrepreneur at the age of 12 when he got a paper round so he
            could afford a pair of new trainers.
          </p>
          <p className='mr_content'>
            He says: ‘The best way to make money is when you make money out of nothing. There have
            been many times when people have said to me, what the hell are you doing, you are just
            wasting your time. But I don’t get put off when people are negative, it spurs me on. If
            you don’t try something, then you have failed yourself.’
          </p>
        </div>
      </div>
    </div>
  )
}

export default SecretOfTheSuper

import React from 'react'
import { pic_10 } from '../img'
import './article.css'
const DiagnosingDyslexiaGuide = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
      <img src={pic_10} alt='pic' className='article_img' />
        <h2>A Guide to the Assessment of Adults with Specific Learning Difficulties</h2>
        <div className='text_size mr_content'>
          Cynthia Klein
          <br />
          Tags: SPLD / Dyslexia
          <p className='text_size '>
            Click{' '}
            <a
              href='https://eric.ed.gov/?id=ED356398'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            This book offers tutors in adult, further, and higher education materials for diagnosing
            students with specific learning disabilities (dyslexia). It provides information for
            setting up an appropriate individualized teaching program and making recommendations for
            supporting such students. Materials and methods can be used with students from basic
            literacy to advanced levels. An introduction describes the diagnostic methodology.
          </p>
          <p className='mr_content'>Chapter 1 is a guide to diagnostic assessment.</p>
          <p className='mr_content'>Chapter 2 is a guide to the use of the diagnostic interview.</p>
          <p className='mr_content'>
            Chapter 3 focuses on the analysis of the student's reading. Topics include selecting a
            reading passage, miscue analysis, reading style analysis, comprehension, and single work
            and nonword testing.
          </p>
          <p className='mr_content'>Chapter 4 discusses spelling error analysis.</p>
          <p className='mr_content'>
            Chapter 5 considers writing analysis and provides a guide to identifying the kinds of
            difficulties that may contribute to a diagnosis of specific learning difficulties.
          </p>
          <p className='mr_content'>Chapter 6 addresses drawing conclusions.</p>
          <p className='mr_content'>
            Chapter 7 offers an overview of specific learning difficulties.
          </p>
          <p className='mr_content'>
            Chapter 8 focuses on writing the diagnostic report and making recommendations.
          </p>
          <p className='mr_content'>
            Chapter 9 discusses how to explain the diagnosis and dyslexia to the student. Appendixes
            include an article entitled "The Reading Process," Fogg's Test of Readability, reading
            selections, word lists, spelling dictations, and advanced spelling list.<br/>
            Read full text <a href='http://eric.ed.gov/PDFS/ED356398.pdf' target='_blank' rel="noreferrer" className='article-link'>here</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default DiagnosingDyslexiaGuide

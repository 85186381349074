import React from 'react'

import { Outlet, Route, Routes } from 'react-router'
import Home from './pages/Home/Home'
import DsaOrderProcess from './pages/Students/dsa-order-process/DsaOrderProcess.jsx'
import AtTuition from './pages/Students/at-tuition/AtTuition'
import StudySkills from './pages/Students/study-skills/StudySkills'
import Mentoring from './pages/Students/mentoring/Mentoring'
import FeedBack from './pages/Students/feedback/FeedBack'
import DsaInsurance from './pages/Students/dsa-insurance/DsaInsurance'
import TechnicalSupport from './pages/Students/technical-support/TechnicalSupport'
import WhatMakeUsDifferent from './pages/Asseessors/what-make-us-different/WhatMakeUsDifferent'
import NmhRates from './pages/Asseessors/nmh-rates/NmhRates'
import ErgonomicAssessments from './pages/Asseessors/ergonomic-assessments/ErgonomicAssessments'
import Grammarly from './pages/Software/grammarly/Grammarly'
import Scholarcy from './pages/Software/scholarcy/Scholarcy'
import OrderForm from './pages/Students/order-form/OrderForm'
import ComingSoon from './pages/coming_soon/ComingSoon'
import Contact from './pages/Contact/Contact'
import NotFound from './components/404/NotFound'
import AboutUs from './pages/About-us/AboutUs'
import OtherResourcesPage from './pages/Resources/Other/OtherResourcesPage'
import ForStudentResourcesPage from './pages/Resources/For-student/ForStudentResourcesPage'
import InPraiseOfMistfits from './pages/Resources/Articles/InPraiseOfMistfits'
import DiagnosingDyslexiaGuide from './pages/Resources/Articles/DiagnosingDyslexiaGuide'
import Memrise from './pages/Resources/Articles/Memrise'
import EssayWriting from './pages/Resources/Articles/EssayWriting'
import SecretOfTheSuper from './pages/Resources/Articles/SecretOfTheSuper'
import JoshuFoer from './pages/Resources/Articles/JoshuFoer'
import WhenWillWenUnderstandAutism from './pages/Resources/Articles/WhenWillWenUnderstandAutism'
import AutismComputer from './pages/Resources/Articles/AutismComputer'
import AspergerConfoundsColleges from './pages/Resources/Articles/AspergerConfoundsColleges'
import AspergerIsOurNormal from './pages/Resources/Articles/AspergerIsOurNormal'
import AssistiveSolutionsBslTutors from './pages/Resources/Articles/AssistiveSolutionsBslTutors'
import ShowdonAwardScheme from './pages/Resources/Articles/SnowdonAwardScheme'
import WhyTheDeafHaveEnhancendVision from './pages/Resources/Articles/WhyTheDeafHaveEnhancendVision'
import StudyingWithSocialDistancing from './pages/Resources/Articles/StudyingWithSocialDistancing'
import MentalElf from './pages/Resources/Articles/MentalElf'
import MentalHealthAndHigherEducation from './pages/Resources/Articles/MentalHealthAndHigherEducation'
import DontWorryBeHappy from './pages/Resources/Articles/DontWorryBeHappy'
import WhyProcrastinatorsProcrastinate from './pages/Resources/Articles/WhyProcrastinatorsProcrastinate'
import HowToBeatProcrastination from './pages/Resources/Articles/HowToBeatProcrastination'
import InvalueableSkills from './pages/Resources/Articles/InvalueableSkills'
import HowToGetWholeLot from './pages/Resources/Articles/HowToGetWholeLot'
import MeditationFPeople from './pages/Resources/Articles/MeditationFPeople'
import EqualityAct from './pages/Resources/Articles/EqualityAct'
import MartynSibleyBlog from './pages/Resources/Articles/MartynSibleyBlog'
import DisabledStudents from './pages/Resources/Articles/DisabledStudents'
import RsaAnimate from './pages/Resources/Articles/RsaAnimate'
import TurningLearningRightSide from './pages/Resources/Articles/TurningLearningRightSide'
import SalmanKhan from './pages/Resources/Articles/SalmanKhan'
import DisabledStudentsStillNeedHelp from './pages/Resources/Articles/DisabledStudentsStillNeedHelp'
import FiveSecrets from './pages/Resources/Articles/FiveSecrets'
import MyLifeAsADisabledStudent from './pages/Resources/Articles/MyLifeAsADisabledStudent'
import StudentsWaysToBeatStress from './pages/Resources/Articles/StudentsWaysToBeatStress'
import Employability from './pages/Resources/Articles/Employability'
import HowToUseLinkedin from './pages/Resources/Articles/HowToUseLinkedin'
import TenSkills from './pages/Resources/Articles/TenSkills'
import WillDisability from './pages/Resources/Articles/WillDisability'
import EleanorLongden from './pages/Resources/Articles/EleanorLongden'
import NorahResearch from './pages/Resources/Articles/NorahResearch'
import CircleOfControl from './pages/Resources/Articles/CircleOfControl';

const MainRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<AboutUs />} />
      <Route path='/dsa-order-process' element={<DsaOrderProcess />} />
      <Route path='/at-tuition' element={<AtTuition />} />
      <Route path='/study-skills' element={<StudySkills />} />
      <Route path='/mentoring' element={<Mentoring />} />
      <Route path='/feedback' element={<FeedBack />} />
      <Route path='/dsa-insurance' element={<DsaInsurance />} />
      <Route path='/technical-support' element={<TechnicalSupport />} />
      <Route path='/wmud' element={<WhatMakeUsDifferent />} />
      <Route path='/nmh-rates' element={<NmhRates />} />
      <Route path='/ergonomic-assessments' element={<ErgonomicAssessments />} />
      <Route path='/grammarly' element={<Grammarly />} />
      <Route path='/scholarcy' element={<Scholarcy />} />
      <Route path='/resources' element={<ForStudentResourcesPage />} />
      {/* <Route path='/resources/other' element={<OtherResourcesPage />} /> */}
      <Route path='essay-writing' element={<EssayWriting />} />
      <Route path='circle-of-control' element={<CircleOfControl />} />
      <Route path='in-praise-of-misfits' element={<InPraiseOfMistfits />} />
      <Route path='joshua-foer-feats-of-memory-anyone-can-do' element={<JoshuFoer />} />
      <Route
        path='when-will-we-understand-autism-spectrum-disorders'
        element={<WhenWillWenUnderstandAutism />}
      />
      <Route
        path='mental-health-and-higher-education'
        element={<MentalHealthAndHigherEducation />}
      />
      <Route path='mental-elf' element={<MentalElf />} />
      <Route path='the-norah-fry-research-centre' element={<NorahResearch />} />

      <Route path='eleanor-longden-the-voices-in-my-head' element={<EleanorLongden />} />
      <Route
        path='will-my-disability-affect-my-graduate-job-application'
        element={<WillDisability />}
      />
      <Route
        path='the-ten-skills-students-really-need-when-they-graduate'
        element={<TenSkills />}
      />
      <Route
        path='students-how-to-use-linkedin-at-uni-to-help-you-get-a-job-when-you-graduate'
        element={<HowToUseLinkedin />}
      />
      <Route path='employability' element={<Employability />} />
      <Route path='students-10-ways-to-beat-stress' element={<StudentsWaysToBeatStress />} />
      <Route path='my-life-as-a-disabled-student' element={<MyLifeAsADisabledStudent />} />
      <Route path='five-secrets-to-revising-that-can-boost-your-grades' element={<FiveSecrets />} />
      <Route
        path='disabled-students-still-need-more-help'
        element={<DisabledStudentsStillNeedHelp />}
      />

      <Route path='salman-khan-talk-at-ted-2011' element={<SalmanKhan />} />
      <Route
        path='turning-learning-right-side-up-book-talk'
        element={<TurningLearningRightSide />}
      />
      <Route path='rsa-animate-changing-education-paradigms' element={<RsaAnimate />} />
      <Route path='disabled-students-allowances' element={<DisabledStudents />} />
      <Route path='equality-act' element={<EqualityAct />} />
      <Route path='martyn-sibley-blog' element={<MartynSibleyBlog />} />
      <Route path='meditation-for-people-who-dont-meditate' element={<MeditationFPeople />} />
      <Route path='how-to-get-a-whole-lot-of-sh*t-done-in-a-day' element={<HowToGetWholeLot />} />
      <Route
        path='invaluable-skills-to-learn-for-free-online-this-year'
        element={<InvalueableSkills />}
      />
      <Route path='how-to-beat-procrastination' element={<HowToBeatProcrastination />} />
      <Route
        path='why-procrastinators-procrastinate'
        element={<WhyProcrastinatorsProcrastinate />}
      />
      <Route path='dont-worry-be-happy' element={<DontWorryBeHappy />} />
      <Route path='autism-how-computer-can-help' element={<AutismComputer />} />
      <Route path='aspergers-confounds-colleges' element={<AspergerConfoundsColleges />} />
      <Route path='asperger-is-this-is-our-normal' element={<AspergerIsOurNormal />} />
      <Route path='assistive-solutions-bsl-tutors' element={<AssistiveSolutionsBslTutors />} />
      <Route path='snowdon-award-scheme' element={<ShowdonAwardScheme />} />
      <Route path='why-the-deaf-have-enhanced-vision' element={<WhyTheDeafHaveEnhancendVision />} />
      <Route path='studying-with-social-distancing' element={<StudyingWithSocialDistancing />} />
      <Route
        path='secret-of-the-super-successful-they-are-dyslexic'
        element={<SecretOfTheSuper />}
      />
      <Route
        path='diagnosing-dyslexia-guide-to-the-assessment-of-adults-with-specific-learning-difficulties'
        element={<DiagnosingDyslexiaGuide />}
      />
      <Route path='memrise' element={<Memrise />} />

      <Route path='/order' element={<OrderForm />} />
      <Route path='/coming-soon' element={<ComingSoon />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default MainRoutes

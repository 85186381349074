import React from 'react'
import { empl } from '../img'

const HowToUseLinkedin = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={empl} alt='pic' className='article_img' />
        <h2>Students: how to use LinkedIn at uni to help you get a job when you graduate</h2>

        <div className='text_size mr_content'>
          Katherine Burch
          <br /> The Guardian
          <br /> 30 December 2013
          <br />
          Tags: EMPLOYMENT
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            Trying to make yourself stand out in the sea of graduates looking for jobs is tricky.
            Having a LinkedIn account can be a key way to build up contacts while still at uni. And
            getting a LinkedIn profile now can be a good way to find work experience and other
            opportunities alongside your studies.
          </p>
          <p className='mr_content'>
            Ian Osborne, university work related learning officer, says: "In a previous role with
            the university I set up work placements for around 200 studentsand the vast majority
            were through utilising and generating contacts on LinkedIn."
          </p>
          <p className='mr_content'>
            A LinkedIn profile is your chance to build a positive online presence for yourself.
          </p>
          <p className='mr_content'>
            Some universities are even integrating LinkedIn into their courses. Melissa Spiers, a
            third year zoology student says: "My university is very keen on employability so I heard
            about LinkedIn through academics. My course at university makes LinkedIn count towards
            my grade, so that is really useful.
          </p>
          <p className='mr_content'>
            "It helps me keep in contact with my peers in a more professional manner than Facebook
            and keeps track of my achievements beyond my CV."
          </p>
          <p className='mr_content'>
            Universities are taking on board the importance of the online profile, and it's
            something you should be thinking about too. If someone searches for your name in Google,
            what are they likely to find? Having an up to date LinkedIn profile is like an online
            CV.
          </p>
          <p className='mr_content'>
            Osborne says: "Advice I would give for students getting LinkedIn is to make sure it is
            up to date with their activities, achievements, grades, involvements in societies,
            passions, interests and career goals.
          </p>
          <p className='mr_content'>
            "Have regular updates on your profile. Think of it like Twitter in that sense. Your
            activity updates are like a beacon of flashing information disseminated out across your
            network;– as it's not only your contacts that see this but their contacts too."
          </p>
          <p className='mr_content'>
            Your LinkedIn account is your space to share what you are achieving at university, and
            will be important when you graduate. Anthony Vickers, masters student at the University
            of Exeter, has over 70 contacts on LinkedIn and says that he has found it useful when
            applying for jobs.
          </p>
          <p className='mr_content'>
            LinkedIn can help you network with people in unexpected ways. Osborne says: "Don't
            necessarily keep your profile exclusive to your career. Everyone has potential with
            people they know. I had one student who had around five contacts and he thought they
            were irrelevant to his career choices. However on closer inspection one of them had a
            contact in the area he was looking to go into."
          </p>
          <p className='mr_content'>
            For Osborne, LinkedIn is significant because "your profile can be used as a short
            cutting avenue into organisations and can help you get around the gatekeeper to the
            company (for example the receptionist you would have to speak to before getting to an
            employer).
          </p>
          <p className='mr_content'>
            "But it's always important to keep in mind that LinkedIn is also a vehicle to meet key
            contacts face to face."
          </p>
          <p className='mr_content'>
            LinkedIn is a useful tool for graduate job hunters, so getting to grips with it while
            still at uni will stand you in good stead. But it could also be your chance to get
            yourself noticed early, and to develop contacts while you're still studying.
          </p>
          <p className='mr_content article-text-bold'>Top LinkedIn tips for students</p>
          <p className='mr_content'>
            • Get LinkedIn in your first year at university. Employers want to see it as an evolving
            online CV.
          </p>
          <p className='mr_content'>
            • Make sure your LinkedIn profile is complete. Employers are more likely to look at a
            finished profile.
          </p>
          <p className='mr_content'>
            • Make sure you upload a professional picture of yourself. No mirror selfies.
          </p>
          <p className='mr_content'>
            • To make the most of LinkedIn try to get a network of at least 100 key people.
          </p>
          <p className='mr_content'>
            • A student's profile cannot be too broad. Display everything you are doing. Employers
            will look to see what you do that is over and above what they are looking for – it's no
            longer just enough to have a good degree.
          </p>
          <p className='mr_content'>
            • Update your profile a couple of times a term. If you complete an activity such as a
            first aid course, update it to show that.
          </p>
          <p className='mr_content'>
            • Get to understand which groups are on there that you want to be part of and join them.
            Start approaching business mentors, people who can help you grow in your career and make
            contacts.
          </p>
        </div>
      </div>
    </div>
  )
}

export default HowToUseLinkedin

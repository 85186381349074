import React, { useState } from 'react'
import './navbar.css'
import { Link } from 'react-router-dom'
import { AiOutlineMenu } from 'react-icons/ai'
import { GrClose } from 'react-icons/gr'
import { useDispatch } from 'react-redux'
import { setTheme } from '../../redux/actions/actions'

const NavList = ({ show, setShow }) => {
  const dispatch = useDispatch()
  const [focusItem, setFocusItem] = useState(null)

  const changeTheme = value => {
    dispatch(setTheme(value))
  }
  return (
    <>
      <ul className={'nav__list'}>
        <li className='nav__listitem'>
          <Link onFocus={() => setFocusItem(null)} to='about'>
            About us
          </Link>
        </li>
        <li className='nav__listitem'>
          <span
            className='span_nav_item'
            tabIndex='0'
            onFocus={() => setFocusItem('students')}
            style={{ pointerEvents: 'none' }}>
            Students
          </span>
          <ul
            className={
              focusItem === 'students' ? 'nav__listitemdrop_students_focus' : 'nav__listitemdrop'
            }
            onClick={() => setFocusItem(null)}
            onMouseLeave={() => setFocusItem(null)}>
            <li>
              <Link to='dsa-order-process'>DSA Order Process</Link>
            </li>
            <li>
              <Link to='order'>Order Form</Link>
            </li>
            <li>
              <Link to='at-tuition'>AT Tuition</Link>
            </li>
            <li>
              <Link to='study-skills'>Study Skills</Link>
            </li>
            <li>
              <Link to='mentoring'>Mentoring</Link>
            </li>
            <li>
              <Link to='feedback'>Feedback</Link>
            </li>
            <li>
              <Link to='dsa-insurance'>DSA Insurance</Link>
            </li>
            <li>
              <Link to='technical-support'>Technical Support</Link>
            </li>
          </ul>
        </li>
        <li className='nav__listitem'>
          <span
            className='span_nav_item'
            tabIndex='0'
            onFocus={() => setFocusItem('assessors')}
            style={{ pointerEvents: 'none' }}>
            Assessors
          </span>
          <ul
            className={
              focusItem === 'assessors'
                ? 'nav__listitemdrop_assessor_focus'
                : 'nav__listitemdrop_assessor'
            }
            onClick={() => setFocusItem(null)}
            onMouseLeave={() => setFocusItem(null)}>
            <li>
              <Link to='wmud'>What makes us different</Link>
            </li>
            <li>
              <Link to='nmh-rates'>NMH Rates</Link>
            </li>
            <li>
              <Link to='ergonomic-assessments'>Ergonomic Assessments</Link>
            </li>
          </ul>
        </li>
        <li className='nav__listitem'>
          <span
            className='span_nav_item'
            tabIndex='0'
            style={{ pointerEvents: 'none' }}
            onFocus={() => setFocusItem('software')}>
            Software
          </span>
          <ul
            className={
              focusItem === 'software' ? 'nav__listitemdrop_software_focus' : 'nav__listitemdrop'
            }
            onClick={() => setFocusItem(null)}
            onMouseLeave={() => setFocusItem(null)}>
            <li>
              <Link to='grammarly'>Grammarly</Link>
            </li>
            <li>
              <Link to='scholarcy'>Scholarcy</Link>
            </li>
            <li>
              <a href='https://shop.assistiveitsolutions.com/' target='_blank' rel='noreferrer'>
                WordQ
              </a>
            </li>
          </ul>
        </li>
        <li className='nav__listitem'>
          <Link onFocus={() => setFocusItem('resources')} to='resources'>
          Resources
          </Link>
        </li>
        <li className='nav__listitem'>
          <span className='span_nav_item' style={{ pointerEvents: 'none' }}>
            Aa
          </span>
          <ul className='nav__listitemdrop_color'>
            <div className='nav__listitemdrop_color_item'>
              <li onClick={() => changeTheme('yellow-theme')}>
                <div className='yellow_theme'>T</div>
              </li>
              <li onClick={() => changeTheme('purple-theme')}>
                <div className='purple_theme'>T</div>
              </li>

              <li onClick={() => changeTheme('dark-light-theme')}>
                <div className='dark_light_theme'>T</div>
              </li>
            </div>
            <div className='nav__listitemdrop_color_item'>
              <li onClick={() => changeTheme('blue-theme')}>
                <div className='blue_theme'>T</div>
              </li>

              <li onClick={() => changeTheme('green-theme')}>
                <div className='green_theme'>T</div>
              </li>
              <li onClick={() => changeTheme('dark-yellow-theme')}>
                <div className='dark_yellow_theme'>T</div>
              </li>
            </div>
            <div onClick={() => changeTheme(null)} className='nav__listitemdrop_color_reset'>
              Reset
            </div>
          </ul>
        </li>
        {/* <li className='nav__listitem' >
                    <span>
                        <img className='flag' src={lang === 'we' ? welsh : english} alt={lang ? 'welsh' : 'english'} />
                    </span>
                    <ul className='flag_drop'>
                        <li onClick={() => changeLanguage('en')}>
                            <div className='translate'>
                                <img src={english} alt='english' /> <span className='span_nav_item'>English</span>
                            </div>
                        </li>
                        <li onClick={() => changeLanguage('we')}>
                            <div className='translate'>
                                <img src={welsh} alt='welsh' />
                                <span className='span_nav_item'> Welsh</span>
                            </div>
                        </li>
                    </ul>
                </li> */}
      </ul>
      <div className='menu-icon' onClick={() => setShow(!show)}>
        {show ? <GrClose /> : <AiOutlineMenu color='#4893B7' cursor='pointer' />}
      </div>
    </>
  )
}

export default NavList

import React from 'react'
const AtTuition = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <div>
          <h1 className='mr_head'>AT Tuition</h1>
          <div className='quote-mr'>
            <div className='quote-wrap'>
              <div className='quote-text'>
                <p>
                  "My tutor always explains in a great manner that I can understand, is always on
                  time and answers any questions I have"
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='about text_size'>
          <h2 className='mr_content content_wrapper_height'>
            What is Assistive Technology Tuition?
          </h2>
          <p className='mr_content'>
            Our Assistive Technology Tuiton/ Training is designed to help you save time while
            working and improve the quality of your work output. 1-1 sessions explore methods of
            working, using assistive technology, to accelerate work.
          </p>
          <h2 className='mr_content content_wrapper_height'>Individuals Learn in Different Ways</h2>
          <p className='mr_content'>
            Each 1-1 session is tailored to your working or learning style, your existing knowledge,
            and the work you need to produce. Putting you in control, Tutors ensure that you drive
            the session and are hands on with the software and hardware. This helps Tutors tailor
            sessions to your requirements and ensures that you get the most from training. Tutors
            will provide plenty of guidance but will not dictate a set format for the session or
            engage you in anything that you don't feel is useful to your circumstances.
          </p>
          <h2 className='mr_content content_wrapper_height'>Example areas that may be Covered</h2>
          <p className='mr_content'>
            For some students, improving organisation and planning can be the areas they wish to
            focus on, for others, improving written work or learning research techniques are vital.
            Within the first ATT session you have, your AT Tutor will help you run through what your
            aims are, and help you identify the way in which you currently work. From this, you will
            develop an individual learning plan (ILP) with your Tutor, which will highlight specific
            areas and topics that may be helpful to address in your future sessions. (Please note
            that this preplanning approach to sessions is not applicable to all and is by no means
            mandatory; freely exploring software and techniques with your AT Tutor’s guidance can
            produce fruitful new ways of working with your Assistive Technology.
          </p>
          <h2 className='mr_content content_wrapper_height'>Booking A Session</h2>
          <p className='mr_content'>
            For the most part, our support is conducted online, however, we may be able to
            facilitate face to face sessions depending on where you are in the UK. The beauty of
            remote sessions includes the fact that you can receive your support from a comfortable,
            familiar location such as your home. It also means that we can set up your support
            quickly, (this can be booked for the same week we hear from you). To arrange your
            support, please email us on nmh@as-dsa.com with your DSA2 letter (or equivalent letter
            stating from your funding body that you have been approved for support), or call the
            office on 020 3519 8000.
          </p>
          <div className='contact_us_btn_wr mr_content'>
            <a className='contact_us_button btn_bg' href='mailto: nmh@as-dsa.com'>
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AtTuition

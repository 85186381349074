import React from 'react'
import { rsa } from '../img'

const RsaAnimate = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={rsa} alt='pic' className='article_img' />
        <h2>RSA Animate: Changing Education Paradigms</h2>

        <div className='text_size mr_content'>
          Sir Ken Robinson
          <br />
          Tags: EDUCATION
          <p className='text_size '>
            Click{' '}
            <a
              href='https://www.youtube.com/watch?v=zDZFcDGpL4U'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            This animate was adapted from a talk given at the RSA by Sir Ken Robinson,
            world-renowned education and creativity expert and recipient of the RSA's Benjamin
            Franklin award.
          </p>
        </div>
      </div>
    </div>
  )
}

export default RsaAnimate

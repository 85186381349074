import React from 'react'
import { dsa2 } from '../img'

const DisabledStudents = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={dsa2} alt='pic' className='article_img' />
        <h2>Disabled Students’ Allowances (DSAs) in higher education 2013/14</h2>

        <div className='text_size mr_content'>
          Tags: DSA
          <p className='text_size '>
            Click{' '}
            <a
              href='http://www.direct.gov.uk/prod_consum_dg/groups/dg_digitalassets/@dg/@en/@educ/documents/digitalasset/dg_200484.pdf'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            DSAs help pay for the extra costs you may have as a direct result of your disability,
            mental-health condition or specific learning difficulty. The allowances can help with
            the cost of having a person to support you, such as a note-taker, items of specialist
            equipment, travel and other course-related costs. The information in this booklet is for
            guidance only and does not cover all circumstances. Visit{' '}
            <a
              href='https://www.gov.uk/disabled-students-allowances-dsas'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              https://www.gov.uk/disabled-students-allowances-dsas
            </a>{' '}
            for more information.
          </p>
          <p className='mr_content'>
            You can find out more information about what’s available where you live from the
            following websites:
          </p>
          <p className='mr_content'>
            England:{' '}
            <a
              href='http://www.sfengland.slc.co.uk/dsa.aspx'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              http://www.sfengland.slc.co.uk/dsa.aspx
            </a>
          </p>
          <p className='mr_content'>
            Wales:{' '}
            <a
              href='http://www.studentfinancewales.co.uk'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              www.studentfinancewales.co.uk
            </a>
          </p>
          <p className='mr_content'>
            Northern Ireland:{' '}
            <a
              href='http://www.studentfinanceni.co.uk'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              www.studentfinanceni.co.uk
            </a>
          </p>
          <p className='mr_content'>
            Scotland:{' '}
            <a
              href='http://www.saas.gov.uk'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              www.saas.gov.uk
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default DisabledStudents

import React from 'react'
import { asperger } from '../img'

const AspergerConfoundsColleges = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={asperger} alt='pic' className='article_img' />
        <h2>Asperger's Confounds Colleges</h2>
        <p className='text_size mr_content'>
          Elizabeth F. Farrell
          <br />
          Tags: ASD
        </p>
        <div className='text_size'>
          <p className='mr_content'>
            By the eighth grade, Stephen M. Shore had taught himself to play every instrument in his
            school's band. But seven years later, during his junior year at the University of
            Massachusetts at Amherst, a run-of-the-mill academic assignment stumped him.
          </p>
          <p className='mr_content'>
            Mr. Shore, a double major in music and accounting, was assigned a research project on a
            topic of his choosing for his "Physics of Music" course. But after finding some books in
            the library and doing some reading, he felt lost. The syllabus had given him no specific
            instructions or intermediate deadlines.
          </p>
          <p className='mr_content'>
            "I didn't know what to do with the materials I found," says Mr. Shore, who withdrew from
            the class to avoid failing it. "It didn't even occur to me to go to the professor and
            ask him for help."
          </p>
          <p className='mr_content'>
            For an Honor student, the experience was baffling: Why couldn't he tackle an assignment
            that his fellow students handled with ease?
          </p>
          <p className='mr_content'>
            What Mr. Shore, now a doctoral candidate at Boston University, did not realize at the
            time was that his problem is related to a neurobiological disorder -- Asperger's
            syndrome, one of the milder forms of autism known as autistic spectrum disorders.
          </p>
          <p className='mr_content'>
            People who have Asperger's tend to struggle with social interactions because of their
            extreme literal-mindedness. They typically are unable to infer meanings from tone or
            context. And when they lack explicit instructions on how to complete an assignment, some
            of them, like Mr. Shore, hit roadblocks. Researchers have not determined the cause of
            Asperger's, but most attribute it to a combination of genetic and environmental factors.
          </p>
          <p className='mr_content'>
            As more students with Asperger's are getting the help they need in elementary and
            secondary schools and making it to college, campus health professionals are struggling
            to determine how to help them.
          </p>
          <p className='mr_content'>
            Some colleges have developed services for such students, but many health-care providers
            have only a vague understanding of the syndrome, and of the needs of students who have
            it.
          </p>
          <p className='mr_content'>
            The absence of a common approach to students with Asperger's has led to widely differing
            interpretations of what constitutes "reasonable accommodations" for them on campuses, as
            required by federal law.
          </p>
          <p className='mr_content'>
            "One of the dilemmas here is that we haven't decided where the lines ought to be drawn,
            where Asperger's starts and stops," says Fred R. Volkmar, a specialist on the syndrome
            who is a professor of psychiatry at the Yale University Child Study Center. "We realize
            there are things that legitimately cause these people to have trouble, but there are a
            lot of gray areas."
          </p>
          <p className='mr_content'>Special Accommodations</p>
          <p className='mr_content'>
            There are no definitive statistics tracking how many students at the college level have
            the syndrome and similar autistic spectrum disorders. Some experts even dispute that
            Asperger's is a form of autism, further clouding estimates.
          </p>
          <p className='mr_content'>
            Studies have confirmed that autistic spectrum disorders, including Asperger's, are on
            the rise: In California, for instance, the Department of Developmental Services reported
            a 273-percent increase in the past decade, and a study conducted by the U.S. Centers for
            Disease Control and Prevention estimated that one out of every 165 children has some
            form of the disorders.
          </p>
          <p className='mr_content'>
            The disorder, recognized and classified in 1944 by an Austrian doctor named Hans
            Asperger, did not receive widespread awareness until 1994, when Asperger's was first
            listed and explained in the Diagnostic and Statistical Manual of Mental Disorders, the
            standard reference for psychologists and psychiatrists. The number of Asperger's
            diagnoses has soared ever since.
          </p>
          <p className='mr_content'>
            Educating students with Asperger's can be particularly vexing because of the extreme
            inconsistency in their abilities. They often have an amazing aptitude for one specific
            subject, like Mr. Shore's for music. In fact, Asperger's is also known as the
            "little-professor syndrome."
          </p>
          <p className='mr_content'>
            "I've had professors say to me that not only did this student read the textbook, but
            they could have written it," says Jane Thierfeld-Brown, director of student services at
            the University of Connecticut's law school.
          </p>
          <p className='mr_content'>
            But some of those same students cannot write a coherent essay on their favorite subject.
            In such cases, says Ms. Thierfeld-Brown, "creative accommodations become very
            important." She regularly works with students to figure out exactly what an assignment
            requires and break it down into literally defined steps, helping them understand how to
            complete the task.
          </p>
          <p className='mr_content'>
            "You always want to maintain the integrity of the curriculum," she says. "I often tell
            them that college is not a trade school, and you can't just make it through by mastering
            one subject."
          </p>
          <p className='mr_content'>
            Mr. Shore's struggle with Asperger's as a graduate student at Boston University
            presented the disability office there with a quandary. Although he overcame his academic
            difficulties at U. Mass to graduate with honors, he confronted another hurdle while
            pursuing a doctorate in music at Boston.
          </p>
          <p className='mr_content'>
            As part of the program he was required to pass a series of tests on genres of music. He
            could not fathom composition from the Romantic era, however, because it lacked the
            structure and patterns that were evident in other types of music.
          </p>
          <p className='mr_content'>
            The director of the music department refused his request that the questions be rephrased
            to compensate for his extremely literal way of thinking. The university's clinical
            director of disability services, Lorraine E. Wolf, also denied Mr. Shore's request. He
            dropped out of the program in 1997.
          </p>
          <p className='mr_content'>
            Ms. Wolf says she would have handled the situation "with more compassion" had she known
            more about the disorder at the time. She has since developed a better understanding of
            Asperger's and now gives talks about the disorder with Mr. Shore, who is pursuing a
            doctorate in education at Boston.
          </p>
          <p className='mr_content'>
            Still, she says, her decision was "academically correct." Her office provides Asperger's
            students with assistance on social interaction and time management, but nonetheless
            requires them to meet the same academic standards as other students.
          </p>
          <p className='mr_content'>'Lost in the Dust'</p>
          <p className='mr_content'>
            Some students with Asperger's need help navigating life outside the classroom, too.
            Because social skills are crucial for academic success, it is important that colleges
            provide such students with guidance on interpersonal skills, says Yale's Dr. Volkmar.
          </p>
          <p className='mr_content'>
            "The problem is, it takes social awareness to pay attention selectively," he says. "You
            watch the professor to see what's relevant and what's not relevant, to get the big
            picture, ... but all that nonverbal stuff is happening very fast, so these students get
            lost in the dust."
          </p>
          <p className='mr_content'>
            Some students with Asperger's struggle to control their impulses. Brenda Smith-Myles, an
            associate professor of special education at the University of Kansas, helped to treat a
            student from another college who often yelled at a professor during class when she
            became frustrated at her inability to keep up.
          </p>
          <p className='mr_content'>
            The office for students with disabilities at the college, which Ms. Smith-Myles declines
            to name, refused to help the student, she says. "It was generally interpreted that she
            could control what she was doing," says the professor. "No one should treat a professor
            that way, but she needed support, and the question really was where should it come from.
            The university did not see how it could provide support, so they didn't."
          </p>
          <p className='mr_content'>
            Some colleges offer lessons in etiquette and social norms for Asperger's students.
          </p>
          <p className='mr_content'>
            Ms. Thierfeld-Brown, at Connecticut, works with students on the basics of interpreting
            body language. They learn that a person who puts his hand on a doorknob probably wants
            to leave the room, and that it is preferable to wait until people are facing you before
            beginning conversations with them. She also sets up "safe rooms" around the campus for
            the students to visit if they need to calm down.
          </p>
          <p className='mr_content'>
            At Boston University, Ms. Wolf says her office provides similar services, although she
            contends that colleges are not legally required to do so. "We provide it as a service
            because if we didn't, these students could be a constant drain on our time," she says.
            "A lot of them will get in trouble with professors if we don't teach them how not to."
          </p>
          <p className='mr_content'>
            The difficulties faced by Asperger's students reveal a larger question that colleges
            must cope with: When determining accommodations for students with special needs, how
            much is too much? If students struggle to accomplish even basic tasks for themselves, is
            the college responsible for unlimited hand-holding and lessons in common sense?
          </p>
          <p className='mr_content'>
            Most student-disability professionals agree that college life is supposed to serve as
            the last bridge to the real world, and that students themselves are responsible for
            learning social and survival skills. With many learning-disability and
            psychological-counselling offices stretched thin, professionals argue that some services
            fall beyond the scope of what colleges can, or should provide.
          </p>
          <p className='mr_content'>
            "There are some folks who are just not going to make it under traditional standards,"
            says Keith J. Anderson, a psychologist at Rensselaer Polytechnic Institute. "The problem
            is that parents start to develop the expectation that we will provide unlimited services
            -- and that's just not feasible."
          </p>
          <p className='mr_content'>
            For students who need additional assistance, a few programs have sprung up. In
            Pittsburgh, a program called Achieving in Higher Education with Autism and Developmental
            Disabilities works with local colleges.
          </p>
          <p className='mr_content'>
            Before the program began two years ago, with a state grant, there was nothing available
            to young adults with conditions on the autism spectrum, says Carolyn Komich Hare, the
            program's founder and director. Students with Asperger's and autism are "often served
            really well in the K-12 public school system, and then they're cut off," she says.
          </p>
          <p className='mr_content'>
            Ms. Komich Hare works with about 15 students from community colleges and four-year
            institutions, including Carnegie Mellon University. She typically checks in with each of
            them a couple of times a day to help them schedule their study time. She also talks them
            through social situations, advising them on appropriate times to ask questions during a
            class, or how to ask a classmate out on a date.
          </p>
          <p className='mr_content'>
            Since the program started, two students who had been on the verge of flunking out of
            Carnegie Mellon have turned their grades around, with one earning mostly A's and B's.
            While the colleges she works with are cooperative, students who require daily guidance
            must pay an additional $4,500 per semester for her services because the state grant does
            not cover the full cost, according to Ms. Komich Hare. Sometimes those costs are
            partially covered by private medical insurance, she notes, adding that she is in the
            process of securing a federal grant to further offset the expenses.
          </p>
          <p className='mr_content'>
            Larry A. Powell, manager of disability resources at Carnegie Mellon, says he wishes that
            his institution could cover more of the cost, but that "with my caseload of 380
            students, we just don't have the resources."
          </p>
          <p className='mr_content'>
            As more students are diagnosed with Asperger's syndrome, programs like the one in
            Pittsburgh could become the new model for colleges. But for now, experts agree that
            students with the disorder are showing up in droves, whether colleges are ready for them
            or not.
          </p>
        </div>
      </div>
    </div>
  )
}

export default AspergerConfoundsColleges

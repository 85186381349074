import React from 'react'
import { pic_40 } from '../img'

const StudyingWithSocialDistancing = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={pic_40} alt='pic' className='article_img' />
        <h2>Studying with Social Distancing.</h2>
        <p className='mr_content text_size'> Tags: Mental Health</p>
        <div className='text_size'>
          <h2>Coronavirus and Mental Health @ Home</h2>
          <p className='mr_content'>
            So you’re still studying but have lost vital parts of your support network, we
            understand. That’s why we have a few resources ready to help you through studying at
            this time.
          </p>
          <p className='mr_content'>
            ‘Social distancing’ is a not a term we like, we prefer “Physical Distancing”. Because
            whilst you are not mixing with people there is still a whole online social world
            available at your fingertips.
          </p>
          <p className='mr_content'>
            This might include arranging your 1:1 support through us, which we can set up remotely,
            or anyone can access some additional support from the resources below. Just remember
            that when isolated there are still numerous ways to interact and socialise with others.
          </p>
          <h2>Frazzled Café</h2>
          <p className='mr_content'>
            Frazzled Café provides a safe, confidential and non-judgemental environment where people
            who are feeling frazzled and overwhelmed by stresses can meet on a regular basis to talk
            and share their personal stories.
            <br />{' '}
            Visit the{' '}
            <a
              href='https://www.frazzledcafe.org/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
               Frazzled Café website
            </a>
          </p>
          <h2>Kooth</h2>
          <p className='mr_content'>
            Kooth is mainly for young people. Don’t hesitate to reach out to them if you’re in need{' '}
            <br />
            <a href='https://www.kooth.com/' target='_blank' rel='noreferrer' className='article-link'>
              https://www.kooth.com/
            </a>
          </p>
          <h2>Every Mind Matters</h2>
          <p className='mr_content'>
            Some really excellent advice from our super NHS. Can be helpful for anyone looking after
            their mental and physical wellbeing, whilst at home.
            <br />
            <a
              href='https://www.nhs.uk/every-mind-matters/coronavirus/mental-wellbeing-while-staying-at-home/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Visit NHS Coronavirus staying at home tips website
            </a>
          </p>
          <h2>Mental Health Foundation</h2>
          <p className='mr_content'>
            Here’s some more good tips from a UK Charity who are looking out for your mental health.
            <br />
            <a
              href='https://www.mentalhealth.org.uk/explore-mental-health/coronavirus-and-mental-health'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              mentalhealth.org.uk
            </a>
          </p>
          <h2>Mind</h2>
          <p className='mr_content'>
            Super advice and support, as ever from Mind, for anyone experiencing mental health
            difficulties. Find out more on
            <br />
            <a
              href='https://www.mind.org.uk/information-support/coronavirus/coronavirus-and-your-wellbeing/#collapse48e4a'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Mind website
            </a>
          </p>
          <h2>Assistive Solutions 1:1 Specialist Mentoring Support</h2>
          <p className='mr_content'>
            Please don’t forget that our highly experienced team of Specialist Mentors are on hand
            to assist you with your studies and will help address any barriers to learning with you
            that you may be experiencing. If you have recently been awarded support, or have yet to
            arrange your support, please do get in touch via 0203 519 8000 or{' '}
            <a href='mailto: nmh@assistiveitsolutions.com' className='article-link'>
              nmh@assistiveitsolutions.com{' '}
            </a>
            and we look forward to connecting and helping you.
          </p>
          <h2>Tip of the week:</h2>
          <p className='mr_content'>
            Try out the mindful breathing exercise video below to help you feel calmer, more present
            and to increase concentration.
          </p>
        </div>
      </div>
    </div>
  )
}

export default StudyingWithSocialDistancing

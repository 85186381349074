import { combineReducers } from 'redux'
import { emailReducer } from './emailReducer'
import { loaderReducer } from './loaderReducer'
import { themeReducer } from './themeReducer'

export const rootReducer = combineReducers({
    // reducers
    theme: themeReducer,
    emailStatus: emailReducer,
    isLoading: loaderReducer,
})

import React from 'react'
import './scholarcy.css'
import { sholarcyItems } from './scholarcyItem'
import video from './scholarcy_vid.mp4'
import youtube from '../../../assets/img/youtube.svg'
import instagram from '../../../assets/img/instagram.svg'
import twitter from '../../../assets/img/twitter.svg'
import facebook from '../../../assets/img/facebook.svg'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'
import videojs from 'video.js'

const Scholarcy = () => {
  const playerRef = React.useRef(null)
  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: video,
        type: 'video/mp4'
      }
    ]
  }
  const handlePlayerReady = player => {
    playerRef.current = player
    player.on('waiting', () => {
      videojs.log('player is waiting')
    })

    player.on('dispose', () => {
      videojs.log('player will dispose')
    })
  }
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <h1 className='mr_head'>Scholarcy</h1>
        <div className='text_size'>
          <p className='mr_content'>
            The AI research summarisation tool for the DSA. Turn your research articles into
            knowledge that sticks. Scholarcy converts chapters and articles into interactive summary
            flashcards to give you the key facts faster. It accelerates reading, writing essays and
            revision.
          </p>
          <div className='video'>
            <VideoPlayer options={videoJsOptions} onReady={handlePlayerReady} />
          </div>
          <p className='mr_content'>
            <span className='scholarcy_card_title'>Understand more: </span>Get to grips with the key
            themes and concepts in a text before reeading in full
            <br />
            <span className='scholarcy_card_title'>Reduce stress: </span>
            Tackle a paper or chapter section-by-section with no distractions
            <br />
            <span className='scholarcy_card_title'>Get organised: </span>
            Save your Flashards into folders to keep track of your reading
          </p>

          <h2>Scholarcy helps students who experience challeneges with:</h2>
          {sholarcyItems.map(item => (
            <div className='scholarcy_content_ul mr_content' key={item.id}>
              <div className='scholarcy_card_img'>
                <img src={item.img} alt='asd' />
              </div>
              <div>
                <p className='scholarcy_card_title'>{item.title}</p>
                <p>{item.content}</p>
              </div>
            </div>
          ))}
          <p className='mr_content'>
            <a
              href='https://www.scholarcy.com'
              target='_blank'
              rel='noreferrer'
              className='scholarcy_card_title'>
              scholarcy.com
            </a>
          </p>
          <div className='mr_content scholarcy_logos'>
            <a
              href='https://www.facebook.com/scholarcy'
              target='_blank'
              rel='noreferrer'
              className='logo_link'>
              <img src={facebook} alt='FaceBook' />
            </a>
            <a
              href='https://www.instagram.com/scholarcy_/'
              target='_blank'
              rel='noreferrer'
              className='logo_link'>
              <img src={instagram} alt='Instagram' />
            </a>
            <a
              href='https://twitter.com/scholarcy'
              target='_blank'
              rel='noreferrer'
              className='logo_link'>
              <img src={twitter} alt='Twitter' />
            </a>
            <a
              href='https://www.youtube.com/@scholarcy'
              target='_blank'
              rel='noreferrer'
              className='logo_link'>
              <img src={youtube} alt='Youtube' />
            </a>
          </div>
          <p className='text_size mr_content'>
            To book a web call, please click{' '}
            <a
              href='https://calendly.com/daniel-scholarcy/informal-15-min-chat?month=2022-12'
              target='_blank'
              rel='noreferrer'
              className='blue-link'>
              here
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Scholarcy

import React from 'react'
import './navbar.css'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/logo.svg'
import logo_light from '../../assets/img/logo_light.svg'
import { useSelector } from 'react-redux'
import NavList from './NavList'
const Navbar = ({ show, setShow }) => {

  const theme = useSelector(state => state.theme)
  return (
    <div className='container'>
      <nav className={'nav '}>
        <div className={'nav__listlogo'}>
          <Link to='/'>
            <img
              src={
                theme === 'dark-light-theme'
                  ? logo_light
                  : theme === 'dark-yellow-theme'
                  ? logo_light
                  : logo
              }
              alt='logo'
            />
          </Link>
        </div>
        <NavList show={show} setShow={setShow} />
      </nav>
    </div>
  )
}

export default Navbar

import React from 'react'
import {
  pic_19,
  pic_52,
  pic_53,
  pic_54,
  pic_55,
  pic_56,
  pic_57,
  pic_59,
  pic_60,
  pic_61,
  pic_62,
  pic_63,
  pic_64,
  pic_65
} from '../img'
import './article.css'
const HowToBeatProcrastination = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={pic_19} alt='pic' className='article_img' />
        <h2>How to Beat Procrastination</h2>
        <p className='text_size mr_content'>
          Tags: PROCRASTINATION
          <p className='text_size '>
            Click{' '}
            <a
              href='http://waitbutwhy.com/2013/11/how-to-beat-procrastination.html'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
            <br />
            This is Part 2. You won’t get Part 2 if you haven’t read Part 1 yet. For Part 1, click{' '}
            <a
              href='https://waitbutwhy.com/2013/10/why-procrastinators-procrastinate.html'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
          </p>
        </p>
        <div className='text_size'>
          <p className='mr_content'>
            <span className='article-text-bold'>
              pro-cras-ti-na-tion |prəˌkrastəˈnāSHən, prō-|{' '}
            </span>
            <br />
            noun
            <br />
            the action of ruining your own life for no apparent reason
          </p>
          <p className='mr_content'>
            Let me start by saying that I’ve had just about enough of the irony of battling through
            crippling procrastination while trying to write posts on procrastination and how to beat
            it. I’ve spent the last two weeks being this guy, who shoots himself in the foot while
            talking about gun safety, and I look forward to getting back to irony-free
            procrastination following this post.
          </p>
          <p className='mr_content'>A few notes before we begin:</p>
          <p className='mr_content'>
            I’m not a professional at any of this, just a lifelong procrastinator who thinks about
            this topic all the time. I’m still in a total battle with my own habits, but I have made
            some progress in the last few years, and I’m drawing my thoughts from what’s worked for
            me.
          </p>
          <p className='mr_content'>
            In this post, I’m referring to both ADD and non-ADD procrastinators (and the line is
            often pretty hazy between the two), but not those with severe ADD/ADHD, who need
            something different than anything in this post can provide.
          </p>
          <p className='mr_content'>
            This post was posted late, not only because it took me 2,000 years to do, but also
            because I decided that Monday night was an urgent time to open Google Earth, hover a few
            hundred feet above the southern tip of India, and scroll all the way up India to the top
            of the country, to “get a better feel for India.” I have problems.
          </p>
          <p className='mr_content'>
            All right, so last week we dove into the everyday inner struggle of the procrastinator
            to examine the underlying psychology going on. But this week, when we’re actually trying
            to do something about it, we need to dig even deeper. Let’s begin by trying to unwrap
            the procrastinator’s psychology and see what’s really at the core of things: We know
            about the Instant Gratification Monkey (the part of your brain that makes you
            procrastinate) and his dominion over the Rational Decision Maker, but what’s really
            happening there?
          </p>
          <p className='mr_content'>
            The procrastinator is in the bad habit, bordering on addiction, of letting the monkey
            win. He continues to have the intention to control the monkey, but he puts forth a
            hapless effort, using the same proven-not-to-work methods he’s used for years, and deep
            down, he knows the monkey will win. He vows to change, but the patterns just stay the
            same. So why would an otherwise capable person put forth such a lame and futile effort
            again and again?
          </p>
          <p className='mr_content'>
            <span className='article-text-bold'>
              The answer is that he has incredibly low confidence when it comes to this part of his
              life, allowing himself to become enslaved by a self-defeating, self-fulfilling
              prophecy.
            </span>{' '}
            Let’s call this self-fulfilling prophecy his Storyline. The procrastinator’s Storyline
            goes something like this:
          </p>
          <p className='mr_content'>
            For the Have-To-Dos in my life, I’ll end up waiting until the last minute, panicking,
            and then either doing less than my best work or shutting down and not doing anything at
            all. For the Want-To-Dos in my life, let’s be honest—I’ll either start one and quit or
            more likely, I just won’t ever get aroun
          </p>
          <p className='mr_content'>
            The procrastinator’s problems run deep, and it takes something more than “being more
            self-disciplined” or “changing his bad habits” for him to change his ways—
            <span className='article-text-bold'>
              the root of the problem is embedded in his Storyline, and his Storyline is what must
              change.
            </span>
          </p>
          <p className='mr_content'>
            Before we talk about how Storylines change, let’s examine, concretely, what the
            procrastinator even wants to change into. What do the right habits even look like, and
            where exactly will the procrastinator run into trouble?
          </p>
          <p className='mr_content'>
            There are two components of being able to achieve things in a healthy and effective
            manner—planning and doing. Let’s start with the easy one:
          </p>
          <p className='mr_content'>Planning</p>
          <p className='mr_content'>
            Procrastinators love planning, quite simply because planning does not involve doing, and
            doing is the procrastinator’s Kryptonite.
          </p>
          <p className='mr_content'>
            But when procrastinators plan, they like to do it in a vague way that doesn’t consider
            details or reality too closely, and their planning leaves them perfectly set up to not
            actually accomplish anything. A procrastinator’s planning session leaves him with a
            doer’s nightmare:
          </p>
          <p className='mr_content'>A big list of icky, daunting tasks and undertakings.</p>
          <img src={pic_52} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            A big list of vague and daunting things makes the Instant Gratification Monkey laugh.
            When you make a list like that, the monkey says, “Oh perfect, this is easy.” Even if
            your gullible conscious mind believes it intends to accomplish the items on that list in
            an efficient manner, the monkey knows that in your subconscious, you have no intention
            of doing so.
          </p>
          <p className='mr_content'>
            Effective planning, on the other hand, sets you up for success. Its purpose is to do the
            exact opposite of everything in that sentence:
          </p>
          <p className='mr_content article-text-bold'>
            Effective planning takes a big list and selects a winner:
          </p>
          <p className='mr_content'>
            A big list is perhaps an early phase of planning, but planning must end with rigorous
            prioritizing and one item that emerges as the winner—the item you’re going to make your
            first priority. And the item that wins should be the one that means the most to you—the
            item that’s most important for your happiness. If urgent items are involved, those will
            have to come first and should be knocked out as quickly as possible in order to make way
            for the important items (procrastinators love to use unimportant but urgent items as an
            excuse to forever put off the important ones).
          </p>
          <p className='mr_content article-text-bold'>
            Effective planning makes an icky item un-icky:
          </p>
          <p className='mr_content'>
            We all know what an icky item is. An icky item is vague and murky, and you’re not really
            sure where you’d start, how you’d go about doing it, or where you’d get answers to your
            questions about it.
          </p>
          <p className='mr_content'>
            So let’s say your dream is to make your own app, and you know that if you build a
            successful app you could quit your job and become a full-time developer. You also think
            that programming ability is the literacy of the 21st century, and you don’t have money
            to spend outsourcing development anyway, so you decide to anoint “Learn how to code” the
            winning item on your list—the number one priority. Exciting, right?
          </p>
          <p className='mr_content'>
            Well, no, because “Learn how to code” is an intensely icky item—and every time you
            decide it’s time to get started, you will coincidentally also decide your inbox needs to
            be cleaned out and your kitchen floor needs to be mopped, ASAP. It’ll never end up
            happening.
          </p>
          <p className='mr_content'>
            To un-icky the item, you need to read, research, and ask questions to find out exactly
            how one learns how to code, the specific means necessary for each step along the way,
            and how long each one should take. Un-ickying a list item turns it from this:
          </p>
          <img src={pic_53} alt='pic' className='article_img_in' />
          <p className='mr_content'>Into this:</p>
          <img src={pic_54} alt='pic' className='article_img_in' />
          <p className='mr_content article-text-bold'>
            Effective planning turns a daunting item into a series of small, clear, manageable
            tasks:
          </p>
          <p className='mr_content'>
            Icky combines with Daunting into an Instant Gratification Monkey steroid potion. And
            just because you un-icky an item, it doesn’t mean it’s still not horribly big and
            daunting. The key to de-dauntifying an item is to absorb this fact:
          </p>
          <p className='mr_content'>
            A remarkable, glorious achievement is just what a long series of unremarkable,
            unglorious tasks looks like from far away.
          </p>
          <p className='mr_content'>
            No one “builds a house.” They lay one brick again and again and again and the end result
            is a house. Procrastinators are great visionaries—they love to fantasize about the
            beautiful mansion they will one day have built—but what they need to be are gritty
            construction workers, who methodically lay one brick after the other, day after day,
            without giving up, until a house is built.
          </p>
          <p className='mr_content'>
            Nearly every big undertaking can be boiled down to a core unit of progress—its brick. A
            45-minute gym visit is the brick of getting in great shape. A 30-minute practice session
            is the brick of becoming a great guitarist.
          </p>
          <p className='mr_content'>
            The average day in a wannabe author’s week and a real author’s week looks almost the
            same. The real author writes a couple pages, laying a brick, and the wannabe author
            writes nothing. 98% of their day is otherwise identical. But a year later, the real
            author has a completed first draft of a book and the wannabe author has…nothing.
          </p>
          <p className='mr_content'>It’s all about the bricks.</p>
          <p className='mr_content'>
            And the good news is, laying one brick isn’t daunting. But bricks do require scheduling.
            So the final step in planning is to make a Brick Timeline, which slots bricks into the
            calendar. The slots are non-negotiable and non-cancellable—after all, it’s your first
            priority and the thing that matters most to you, isn’t it? The most important date is
            the first one. You can’t start learning to code “in November.” But you can start
            learning to code on November 21st from 6:00 – 7:00pm.
          </p>
          <p className='mr_content'>
            Now you’re effectively planned—just follow the schedule and you’ll be a programmer. Only
            thing left is to do…
          </p>
          <p className='mr_content'>Doing</p>
          <p className='mr_content'>
            It’s not that procrastinators don’t like the concept of doing. They look at the bricks
            on their calendar and they think, “Great, this will be fun.” And that’s because when
            they picture the moment in the future when they sit down and knock out a work session,
            they picture things without the presence of the Instant Gratification Monkey.
            Procrastinators’ visions of future scenarios never seem to include the monkey.
          </p>
          <p className='mr_content'>
            But when the actual moment arrives to begin that scheduled brick-laying, the
            procrastinator does what the procrastinator does best—he lets the monkey take over and
            ruin everything.
          </p>
          <p className='mr_content'>
            And since we just stressed above that all achievement boils down to the ability to lay
            that one brick during that slot when it’s on your schedule, we seem to have isolated the
            core struggle here. Let’s examine this specific challenge of laying a single brick:
          </p>
          <img src={pic_55} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            So this diagram represents the challenge at hand anytime you take on a task, whether
            it’s making a PowerPoint for work, going on a jog, working on a script, or anything else
            you do in your life. The Critical Entrance is where you go to officially start work on
            the task, the Dark Woods are the process of actually doing the work, and once you
            finish, you’re rewarded by ending up in The Happy Playground—a place where you feel
            satisfaction and where leisure time is pleasant and rewarding because you got something
            hard done. You occasionally even end up super-engaged with what you’re working on and
            enter a state of{' '}
            <a
              href='https://en.wikipedia.org/wiki/Flow_(psychology)'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Flow
            </a>{' '}
            ,where you’re so blissfully immersed in the task that you lose track of time.
          </p>
          <p className='mr_content'>Those paths look something like this:</p>
          <img src={pic_56} alt='pic' className='article_img_in' />
          <p className='mr_content'>Sounds pretty simple, right?</p>
          <p className='mr_content'>
            Well unfortunately for procrastinators, they tend to miss out on both The Happy
            Playground and Flow.
            <br /> For example, here’s a procrastinator that never even gets started on the task
            he’s supposed to do, because he never makes it through the Critical Entrance. Instead,
            he spends hours wallowing in The Dark Playground, hating himself:
          </p>
          <img src={pic_57} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            Here’s a procrastinator who gets started on the task, but she can’t stay focused, and
            she keeps taking long breaks to play on the internet and make food. She doesn’t end up
            finishing the task:
          </p>
          <img src={pic_57} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            Here’s a procrastinator who couldn’t bring himself to get started, even though a work
            deadline was approaching, and he spent hours in The Dark Playground, knowing the looming
            deadline was drawing near and he was only making his life harder by not starting.
            Eventually, the deadline got so close, the Panic Monster suddenly came roaring into the
            room, freaking him out and causing him to fly through the task to hit the deadline.
          </p>
          <img src={pic_59} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            After he finishes, he feels decent because he accomplished something, but he’s also not
            that pleased because he knows he did an underwhelming job on the project because he had
            to rush so much, and he feels like he wasted most of his day procrastinating for no
            reason. This lands him in Mixed Feelings Park.
          </p>
          <p className='mr_content'>
            So if you’re a procrastinator, let’s look at what you need to do to get on the right
            path, one that will leave you much happier.
          </p>
          <p className='mr_content'>
            The first thing you must do is make it through the Critical Entrance. This means
            stopping whatever you’re doing when it’s time to begin the task, putting away all
            distractions, and getting started. It sounds simple, but this is the hardest part. This
            is where the Instant Gratification Monkey puts up his fiercest resistance:
          </p>
          <img src={pic_60} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            The monkey absolutely hates stopping something fun to start something hard, and this is
            where you need to be the strongest. If you can get started and force the monkey into the
            Dark Woods, you’ve broken a bit of his will. Of course, he’s not going to give up
            anytime soon.
          </p>
          <p className='mr_content'>
            The Dark Woods is where you are when you’re working. It’s not a fun place to be, and the
            Instant Gratification Monkey wants nothing to do with it. To make things harder, the
            Dark Woods issurrounded by the Dark Playground, one of the monkey’s favorite places, and
            since he can see how close it is, he’ll try as hard as he can to leave the Dark Woods.
          </p>
          <p className='mr_content'>
            There will also be times when you bump into a tree—maybe the jog is taking you on an
            uphill street, maybe you need to use an Excel formula you don’t know, maybe that song
            you’re writing just isn’t coming together the way you thought it would—and this is when
            the monkey will make his boldest attempt at an escape.
          </p>
          <img src={pic_61} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            It makes no sense to leave the Dark Woods in favor of the Dark Playground—they’re both
            dark. They both suck to be in, but the big difference is the Dark Woods leads to
            happiness and the Dark Playground leads only to more misery. But the Instant
            Gratification Monkey isn’t logical and to him, the Dark Playground seems like much more
            fun.
          </p>
          <p className='mr_content'>
            The good news is, if you can power through a bit of the Dark Woods, something funny
            happens. Making progress on a task produces positive feelings of accomplishment and
            raises your self-esteem. The monkey gains his strength off of low self-esteem, and when
            you feel a jolt of self-satisfaction, the monkey finds a High Self-Esteem Banana in his
            path. It doesn’t quell his resistance entirely, but it goes a long way to distracting
            him for a while, and you’ll find that the urge to procrastinate has diminished.
          </p>
          <img src={pic_62} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            Then, if you continue along, something magical happens. Once you get 2/3 or 3/4 of the
            way through a task, especially if it’s going well, you start to feel great about things
            and suddenly, the end is in sight. This is a key tipping point—
          </p>
          <img src={pic_63} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            The Tipping Point is important because it’s not just you who can smell the Happy
            Playground up ahead—the monkey can smell it too. The monkey doesn’t care if his instant
            gratification comes alongside you or at your expense, he just loves things that are easy
            and fun. Once you hit the Tipping Point, the monkey becomes more interested in getting
            to the Happy Playground than the Dark Playground. When this happens, you lose all
            impulse to procrastinate and now both you and the monkey are speeding toward the finish.
          </p>
          <img src={pic_64} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            Before you know it, you’re done, and you’re in the Happy Playground. Now, for the first
            time in a while, you and the monkey are a team. You both want to have fun, and it feels
            great because it’searned. When you and the monkey are on a team, you’re almost always
            happy.
          </p>
          <img src={pic_65} alt='pic' className='article_img_in' />
          <p className='mr_content'>
            The other thing that might happen when you pass the Tipping Point, depending on the type
            of task and how well it’s going, is that you might start feeling fantastic about what
            you’re working on, so fantastic that continuing to work sounds like much more fun than
            stopping to do leisure activities. You’ve become obsessed with the task and you lose
            interest in basically everything else, including food and time—this is called Flow. Flow
            is not only a blissful feeling, it’s usually when you do great things.
          </p>
          <p className='mr_content'>
            The monkey is just as addicted to the bliss as you are, and you two are again a team.
          </p>
          <img src={pic_19} alt='pic' className='article_img_in' />

          <p className='mr_content'>
            Fighting through to the Tipping Point is hard, but what makes procrastination so hard to
            beat is that the Instant Gratification Monkey has a terribly short-term memory—even if
            you wildly succeed on Monday, when you begin a task on Tuesday, the monkey has forgotten
            everything and will again resist entering the Dark Woods or working through them.
          </p>
          <p className='mr_content'>
            And that’s why persistence is such a critical component of success. Laying each brick
            yields an inner struggle—and in the end, your ability to win this very specific struggle
            and lay brick after brick, day after day, is what lies at the core of a procrastinator’s
            struggle to gain control over his world.
          </p>
          <p className='mr_content'>
            So that’s what needs to happen—but if procrastination could be solved by reading a blog
            entry, it wouldn’t be such a large problem in so many people’s lives. There’s only one
            way to truly beat procrastination:
          </p>
          <p className='mr_content'>You need to prove to yourself that you can do it.</p>
          <p className='mr_content'>
            You need to show yourself you can do it, not tell yourself. Things will change when you
            show yourself that they can. Until then, you won’t believe it, and nothing will change.
            Think of yourself like a basketball player on a cold streak. For basketball players,
            it’s all about confidence, and an ice cold shooter can tell himself 1000 times, “I’m a
            great shooter, I’m going to hit this next one,” but it’s not until he physically hits a
            shot that his confidence goes up and his touch comes back.
          </p>
          <p className='mr_content'>So how do you start hitting shots?</p>
          <p className='mr_content article-text-bold'>
            1) Try to internalize the fact that everything you do is a choice.
          </p>
          <p className='mr_content'>
            Start by thinking about the terms we’ve used in these posts, and if they resonated with
            you, write them down. Part of the reason I assigned terms to so many of these feelings
            or phenomena—the Instant Gratification Monkey, the Rational Decision-Maker, the Panic
            Monster, the Dark Playground, Ickiness, Bricks, the Critical Entrance, the Dark Woods,
            the Tipping Point, the Happy Playground, Flow, your Storyline—is that terms help you
            clarify the reality of the choices you’re making. It helps expose bad choices and
            highlights when it’s most critical to make good ones.
          </p>
          <p className='mr_content article-text-bold'>
            2) Create methods to help you defeat the monkey.
          </p>
          <p className='mr_content'>Some possible methods:</p>
          <p className='mr_content'>
            Solicit external support by telling one or more friends or family members about a goal
            you’re trying to accomplish and asking them to hold you to it. If that’s hard for
            whatever reason, email it to me—I’m a stranger (contact@waitbutwhy.com)—and just typing
            out a goal and sending it to a real person can help make it more real.
          </p>
          <p className='mr_content'>
            Create a Panic Monster if there’s not already one in place—if you’re trying to finish an
            album, schedule a performance for a few months from now, book a space, and send out an
            invitation to a group of people.
          </p>
          <p className='mr_content'>
            If you really want to start a business, quitting your job makes the Panic Monster your
            new roommate.
          </p>
          <p className='mr_content'>
            If you’re trying to write a consistent blog, put “new post every Tuesday” at the top of
            the page…
          </p>
          <p className='mr_content'>
            Leave post-it notes for yourself, reminding you to make good choices.
          </p>
          <p className='mr_content'>
            Set an alarm to remind yourself to start a task, or to remind you of the stakes.
          </p>
          <p className='mr_content'>
            Minimize distractions by all means necessary. If TV’s a huge problem, sell your TV. If
            the internet’s a huge problem, get a second computer for work that has Wifi disabled,
            and turn your phone on Airplane Mode during work sessions.
          </p>
          <p className='mr_content'>
            Lock yourself into something—put down a non-refundable deposit for lessons or a
            membership.
          </p>
          <p className='mr_content'>
            And if the methods you set up aren’t working, change them. Set a reminder for a month
            from now that says, “Have things improved? If not, change my methods.”
          </p>
          <p className='mr_content article-text-bold'>
            3) Aim for slow, steady progress—Storylines are rewritten one page at a time.
          </p>
          <p className='mr_content'>
            In the same way a great achievement happens unglorious brick by unglorious brick, a
            deeply-engrained habit like procrastination doesn’t change all at once, it changes one
            modest improvement at a time. Remember, this is all about showing yourself you can do
            it, so the key isn’t to be perfect, but to simply improve. The author who writes one
            page a day has written a book after a year. The procrastinator who gets slightly better
            every week is a totally changed person a year later.
          </p>
          <p className='mr_content'>
            So don’t think about going from A to Z—just start with A to B. Change the Storyline from
            “I procrastinate on every hard task I do” to “Once a week, I do a hard task without
            procrastinating.” If you can do that, you’ve started a trend. I’m still a wretched
            procrastinator, but I’m definitely better than I was last year, so I feel hopeful about
            the future.
          </p>
          <p className='mr_content'>
            Why do I think about this topic so much, and why did I just write a 19,000 page blog
            post on it?
          </p>
          <p className='mr_content'>
            Because defeating procrastination is the same thing as gaining control over your own
            life. So much of what makes people happy or unhappy—their level of fulfillment and
            satisfaction, their self-esteem, the regrets they carry with them, the amount of free
            time they have to dedicate to their relationships—is severely affected by
            procrastination. So it’s worthy of being taken dead seriously, and the time to start
            improving is now.
          </p>
          <p className='mr_content'>
            <a
              href='http://waitbutwhy.com/2013/11/how-to-beat-procrastination.html'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              {' '}
              http://waitbutwhy.com/2013/11/how-to-beat-procrastination.html
            </a>
          </p>
          <p className='mr_content'>
            <span className='article-text-bold'>
              If you liked this article, here are some more posts from Wait But Why:
            </span>
            <a
              href='https://waitbutwhy.com/2013/09/why-generation-y-yuppies-are-unhappy.html'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              {' '}
              Why Generation Y Yuppies Are Unhappy
            </a>
            <a
              href='https://waitbutwhy.com/2013/09/why-generation-y-yuppies-are-unhappy.html'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              7 Ways to be Insufferable on Facebookl
            </a>
            <a
              href='https://waitbutwhy.com/2013/11/life-is-picture-but-you-live-in-pixel.html'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Life is a Picture, But You Live in a Pixel
            </a>
          </p>
          <p className='mr_content article-text-bold'>
            For more articles from Wait But Why, check out{' '}
            <a
              href='https://waitbutwhy.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              www.waitbutwhy.com
            </a>
          </p>
          <p className='mr_content article-text-bold'>
            Follow Wait But Why on Facebook:{' '}
            <a
              href='https://waitbutwhy.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              www.facebook.com/waitbutwhy
            </a>{' '}
          </p>
          <p className='mr_content article-text-bold'>
            Subscribe to Wait But Why via{' '}
            <a
              href='https://waitbutwhy.com/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              Email
            </a>
          </p>
          <p className='mr_content article-text-bold'>
            Follow Wait But Why on Twitter:{' '}
            <a
              href='https://twitter.com/waitbutwhy'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              www.twitter.com/waitbutwhy
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default HowToBeatProcrastination

import React from 'react'
import { empl1 } from '../img'

const TenSkills = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={empl1} alt='pic' className='article_img' />
        <h2>The ten skills students really need when they graduate</h2>

        <div className='text_size mr_content'>
          Libby Page
          <br /> The Guardian
          <br /> 4 February 2014
          <br />
          Tags: EMPLOYMENT
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            The number of jobs available for graduates is predicted to rise by 10.2%, according to a
            survey by the Association of Graduate Recruiters. Good news for students – but when you
            come to apply for your first job after university, are you sure you'll have the relevant
            skills for the post?
          </p>
          <p className='mr_content'>
            Leaving it until you're sitting in front of a blank computer screen trying to write your
            CV for a graduate job may well be too late to assess the qualities you've got to offer.
          </p>
          <p className='mr_content'>
            "From day one students should see the start of university as the start of their working
            life," says Dan Hawes, co-founder of the Graduate Recruitment Bureau. "Think of the
            skills you need to have when you finish university in three or four years time and then
            work backwards, so you're hoarding skills each year."
          </p>
          <p className='mr_content'>
            Gemma Pirnie, founder of Your New Crew, says: "When I left university I was completely
            unprepared for what the grad market would throw at me. I thought having a 2:1 from a
            good university and having a bit of (irrelevant) work experience would see me land a job
            no problem – not the case."
          </p>
          <p className='mr_content'>
            Obviously the skills you need will depend on the job you apply for, but there are some
            things that graduate recruiters are looking for that you can start thinking about now.
          </p>
          <p className='mr_content'>Business sense</p>
          <p className='mr_content'>
            "Candidates who start their own societies, organise their university ball or start their
            own T-shirt-making business while at uni tend to have a good grasp of how a business
            makes money," says Cary Curtis, managing director of Give-a-grad-a-go.
          </p>
          <p className='mr_content'>
            An understanding of business is one of the main skills that students underestimate,
            according to a 2013 Guardian survey. Less than 10% of students thought it was a vital
            skill, compared to nearly 50% of employers.
          </p>
          <p className='mr_content'>
            "You don't need to be the next Mark Zuckerberg," says Curtis, "but having a good idea of
            how a business runs and the challenges involved are really useful skills to take into a
            company."
          </p>
          <p className='mr_content article-text-bold'>Get global</p>
          <p className='mr_content'>
            "More and more employers are wanting graduates to have a 'global mindset', which means
            understanding different cultures and how industries work across borders," says Stephen
            Isherwood, chief executive of the Association of Graduate Recruiters.
          </p>
          <p className='mr_content'>
            Isherwood recommends that students make the most of time at uni to meet people from
            around the world, consider study abroad schemes like Erasmus, and says that speaking
            another language shows that a grad has a "better cultural understanding".
          </p>
          <p className='mr_content article-text-bold'>A language</p>
          <p className='mr_content'>
            "If you speak another language then make sure it's clearly displayed on your CV," says
            Curtis. "We really like bilingual candidates as they usually interview really well. But
            quite often their CV doesn't do them justice. 'Business fluent French' looks much better
            than just 'French A-level at grade A' as it offers a company more."
          </p>
          <p className='mr_content'>
            Be careful though. "If you haven't had much practice with your languages make sure you
            scrub up before sending out a CV that implies you can hold your own."
          </p>
          <p className='mr_content article-text-bold'>A squeaky clean digital footprint</p>
          <p className='mr_content'>
            "Start building a digital footprint of relevance to what you want to do," says Smith.
            "Employers, just like everyone else, will look on the internet as a first port of call."
          </p>
          <p className='mr_content'>
            That might mean setting up a LinkedIn profile, starting your own blog or website,
            joining Twitter, and generally making sure you're careful about what goes online
            connected to you.
          </p>
          <p className='mr_content'>
            Chris Smith, chief executive of Opinio Group recruiters, says: "If the only thing
            employers can find is you with traffic cones on your head on Facebook then you're in
            trouble."
          </p>
          <p className='mr_content article-text-bold'>Office etiquette</p>
          <p className='mr_content'>
            Knowing how to fit into an office will come in handy for many graduate jobs, so it's a
            good idea to look for opportunities to spend time in one while you're still a student.
          </p>
          <p className='mr_content'>
            Hawes says: "What some graduates might not have is the office know-how, maybe they've
            never worked in an office before so everything's new, whether it's answering the phone
            or the way you dress or address people, the things like that – office etiquette."
          </p>
          <p className='mr_content article-text-bold'>How to make a good cuppa</p>
          <p className='mr_content'>
            It might sound like a cliché, but being able to make a good cup of tea can go a long
            way.
          </p>
          <p className='mr_content'>
            Sam Espensen, managing director of Conversation Creation, says: "One of the skills that
            has stood me in great stead is making great tea and coffee. I once had a grad refuse to
            make my team drinks because it 'suggested he was below the rest of us' – needless to say
            he didn't last long.
          </p>
          <p className='mr_content'>
            "We all make tea in my team and the person who brings me a cup of tea unprompted is
            likely to be my hero for the day."
          </p>
          <p className='mr_content'>
            Sussing out the culture of a workplace (whether they all make tea for each other, for
            example) is really important when you come to look for work.
          </p>
          <p className='mr_content article-text-bold'>Good with computers</p>
          <p className='mr_content'>
            "Hard skills in programming and software development for us are really valuable," says
            Hawes. Curtis adds that being highly numerate and having good analytical skills is
            increasingly important.
          </p>
          <p className='mr_content'>
            But even if you're not applying for a job in a tech company, being digitally savvy is
            still really important.
          </p>
          <p className='mr_content'>
            "All employers expect graduates to be computer literate," says Isherwood. "Generally
            just being aware of the digital age is important. That doesn't mean having programming
            skills, but understanding that instant messaging and conference calls are common in the
            business world and having basic IT skills like working with spreadsheets and documents."
          </p>
          <p className='mr_content article-text-bold'>Teamwork</p>
          <p className='mr_content'>
            If you've been thinking of joining a uni sports team but haven't quite found the
            motivation, here's another reason to join: it could help you get a job. Smith says:
            "What shows better that someone can be part of a team than the fact they've spent the
            past two years playing for a team."
          </p>
          <p className='mr_content'>
            Being able to work with other people is a key skill employers are looking for and you'll
            need to prove it's something you have had experience of beyond your course.
          </p>
          <p className='mr_content'>
            "When talking about teamwork don't use university examples," says Isherwood. "At uni the
            teams are chosen for you, so it's much more impressive to speak about sport or
            involvement with a society."
          </p>
          <p className='mr_content article-text-bold'>Talking to people</p>
          <p className='mr_content'>
            Getting confident talking to people is useful for building up your contacts. Pirnie
            says: "Attend industry meet ups. This will help you both with articulating your
            interests and with interview techniques as you'll quickly become comfortable talking to
            new people."
          </p>
          <p className='mr_content'>
            Smith recommends going to exhibitions and conventions in your chosen field. He says:
            "There are small business conventions going on all over the country. Go to them, meet
            people, ask some questions. And if you're not sure yet quite what you want to do, all
            the more reason to get out there and investigate."
          </p>
          <p className='mr_content article-text-bold'>Positive attitude</p>
          <p className='mr_content'>
            If you're still at uni, it's worth thinking about how you can build up all these skills.
            But if you're in your final year and haven't ticked them all, don't worry. "Employers
            often say that they hire for attitude and train for skill," says Hawes. So whatever
            experience you've got to your name, a positive attitude is still the most important
            thing you can bring to your first job. A few more things to consider
          </p>
          <p className='mr_content'>
            • A driving licence: "A driving licence certainly isn't the prerequisite that it used to
            be," says Curtis, but if you haven't yet learnt to drive it's still worth trying before
            you leave uni. Smith says: "It can be important. Also, it speaks volumes if you can't
            drive. The first thing I think is – why not? It hints at laziness."
          </p>
          <p className='mr_content'>
            • You can pick up skills in unlikely places: Espensen's father ran a cottage industry
            making model planes, and when she was a student she "reluctantly" helped out, packing
            the kits and posting them out. At the time she had no idea that the knowledge she built
            up would come in handy – but she ended up working doing the PR for the Bomber Command
            Memorial and realised how useful it was.
          </p>
          <p className='mr_content'>
            Things you get involved with at university and in your own time might not seem directly
            linked to what you want to do in the future, but you never know when those skills might
            come in handy.
          </p>
        </div>
      </div>
    </div>
  )
}

export default TenSkills

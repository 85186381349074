import React from 'react'
import './dsa-order-process.css'
import { Link } from 'react-router-dom'
import Slider from './Slider'
import SliderFundingBody from './SliderFundingBody'

const DsaOrderProcess = () => {
    
    return (
        <div className='container_order'>
            <div className='content_wrapper_order'>
                <h1 className='mr_head'>DSA Order Process</h1>
                <div className='text_size'>
                    <p className='mr_content text_size'>
                        Have you just received your Funding Body Letter? <br /> Click on the button below to place your
                        Computer equipment or Ergonomic order, book Assistive Technology Training, Study Skills or
                        Specialist Mentoring. <br />
                        <br />
                        Alternatively, please call us on 0203 519 8000 or email{' '}
                        <a className='link__order_form' href='mailto:  orders@as-dsa.com'>
                            orders@as-dsa.com
                        </a>
                    </p>
                    <Link to='/order'>
                        <div className='btn_wrapp_order'>
                            <div className='mr_content button_place_order btn_bg'>Place an order</div>
                        </div>
                    </Link>
                    <Slider />
                    <div className='dsa_order_process_content'>
                        <h2 className='mr_content'>How long does it take?</h2>
                        <p className='mr_content'>
                            Under our Service Level Agreement we are obliged to deliver all equipment within 10 working
                            days of receiving permission to supply - e.g. Entitlement Letter - however we strive to do
                            much better than this. <br />
                            <br /> Please see our{' '}
                            <a
                                className='incurance_link'
                                href='pdf/Assistive Solutions - Terms and Conditions DSA.pdf'
                                target='_blank'
                            >
                                DSA Terms and Conditions{' '}
                            </a>
                            for full details of our sevices to you.
                        </p>
                        <h2 className='mr_content'>Upgrade Guide</h2>
                        <p className='mr_content'>
                            DSAs fund the minimum spec to run your specialist Assistive software. Course requirements
                            (e.g. more RAM to run Adobe Suite) cannot be taken into account when recommending the
                            equipment. <br />
                            <br /> You can upgrade any of your Software or Hardware, provided the upgraded equipment
                            does not compromise the original intent of recommendation and works with the rest of your
                            hardware and software. <br />
                            <br /> Please be aware that you can only upgrade your machine before your order is shipped.
                            We are unable to change the computer subsequently. Check our{' '}
                            <a className='incurance_link' href='pdf/Upgrade-Guide.pdf' target='_blank'>
                                Upgrade Guide{' '}
                            </a>{' '}
                            if you need help choosing the right machine, or call our friendly team. <br />
                            <br /> We do our best to keep upgrades at cost price, as those are funded by Students.
                        </p>
                        <h2 className='mr_content'>Examples of Funding Body Letters</h2>
                        <p className='mr_content'>
                            Please click on images below for examples of Funding Body Letters you need to send to us.
                        </p>
                    </div>
                    <SliderFundingBody />
                </div>
            </div>
        </div>
    )
}

export default DsaOrderProcess

import React from 'react'
import { pic_24 } from '../img'

const Employability = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <img src={pic_24} alt='pic' className='article_img' />
        <h2>Employability</h2>
        <div className='text_size mr_content'>
          Tags: EMPLOYMENT
          <p className='text_size '>
            Click{' '}
            <a
              href='https://www.employ-ability.org.uk/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              here
            </a>{' '}
            to go to external article
          </p>
        </div>
        <div className='text_size'>
          <p className='mr_content'>
            EmployAbility work with disabled university students and graduates to ease the
            transition from education into employment. Their highly skilled team can offer you free
            support, advice and guidance throughout the entire recruitment process and beyond,
            including:
          </p>
          <p className='mr_content'>
            Guidance on preparing CVs and completing application forms, how to perform well at
            interviews, mock interview sessions with key employers, recommendations regarding
            disability inclusive employers, advice on sensitive issues such as disclosure of a
            disability information and advice regarding reasonable adjustments you may require
            during the recruitment process and/or in the workplace. They can also provide
            opportunities to attend specialist recruitment events and they run a wide range of
            internships and graduate recruitment programmes on behalf of many blue-chip and public
            sector organisations.
          </p>
          <p className='mr_content'>
            To register with Employability or to put any specific queries to them following this{' '}
            <a
              href='https://www.employ-ability.org.uk/'
              target='_blank'
              rel='noreferrer'
              className='article-link'>
              link.
            </a>{' '}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Employability

import React from 'react'
import './mentoring.css'
import mentoring from '../../../assets/img/mentoring.svg'
const Mentoring = () => {
  return (
    <div className='container'>
      <div className='content_wrapper'>
        <div>
          <h1 className='mr_head'>Mentoring</h1>
          <div className='quote-mr'>
            <div className='quote-wrap'>
              <div className='quote-text'>
                <p className='mentoring-text'>
                  'I am very happy with my mentor. She is supporting me through the most difficult
                  challenges. She is really understanding, and kind. She is well-informed, has an
                  answer and solution for everything. My mentor also helps me manage my emotions and
                  anxiety. I am thankful to have her support."
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='text_size'>
          <h2 className='content_wrapper_height'>What is Mentoring?</h2>
          <p className='mr_content'>
            Mentoring can be utilised in a wide variety of ways depending on your preference/needs.
            From guidance on time management, scheduling, a healthy work-life balance, and support
            with your mental health. Our mentors are here to provide a friendly ear, and will work
            with you to collaboratively create solutions to the problems and barriers you may be
            facing.
          </p>
          <h2 className='content_wrapper_height'>Who are Our Mentors?</h2>
          <p className='mr_content'>
            At Assistive Solutions, our Mentors come from a variety of backgrounds, but are always
            qualified in professional fields such as counselling, social work, or psychology. Our
            Mentors will have experience with supporting those struggling with their mental health,
            and are here to help you better manage things such as anxiety, imposter syndrome, or
            potentially coming to grips with any diagnosis you may have been given.
          </p>
          <h2 className='content_wrapper_height'>Booking A Session</h2>
          <p className='mr_content'>
            For the most part, our support is conducted online, however, we may be able to
            facilitate face to face sessions depending on where you are in the UK. The beauty of
            remote sessions includes the fact that you can receive your support from a comfortable,
            familiar location such as your home. It also means that we can set up your support
            quickly, (this can be booked for the same week we hear from you). To arrange your
            support, please email us on nmh@as-dsa.com with your DSA2 letter (or equivalent letter
            stating from your finding body that you have been approved for support), or call the
            office on 020 3519 8000.
          </p>
        </div>
        <div className='contact_us_btn_wr mr_content'>
          <a className='contact_us_button btn_bg' href='mailto: nmh@as-dsa.com'>
            Contact us
          </a>
        </div>
        <div className='mentotring_img'>
          <img src={mentoring} alt='mentoring' />
        </div>
      </div>
    </div>
  )
}

export default Mentoring

import React from 'react'
import './article_card.css'
import { Link } from 'react-router-dom'
const ArticleCard = ({ article, cardTagHandler }) => {
  const handler = value => {
    cardTagHandler(value)
    window.scrollTo(0, 300)
  }

  return (
    <div className='articles_wrapper'>
      <div className='card_article'>
        {article.link ? (
          <Link to={article.link}>
            <div className='card_app_bar'>
              <img src={article.img} alt='Article image' />
              <div>{article.title}</div>
            </div>
          </Link>
        ) : (
          <a href={article.href} target='_blank' rel="noreferrer">
            <div className='card_app_bar'>
              <img src={article.img} alt='asd' />
              <div>{article.title}</div>
            </div>
          </a>
        )}

        <div className='footer_article_card'>
          Tags:{' '}
          {article.tags.map(el => (
            <span key={el.title} onClick={() => handler(el.value)}>
              {el.title}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ArticleCard
